import { FC, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { Icon } from '@liveconnect/icons'
import { FormProvider, MultiSelectTreeControl } from '@liveconnect/components'

import useEmailsDashboard from '../../../../../core/emailsDashboard/useEmailsDashboard'

import SlidePanel from '../../../../../components/SlidePanel'

import './styles.scss'

interface EmailsListFilterProps {
  isOpen: boolean
  onClose: () => void
}

interface UseFormProps {
  types: string[]
  states: string[]
}

const EmailsListFilter: FC<EmailsListFilterProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const filterPanelWrapper = useRef<HTMLDivElement>(
    document.createElement('div')
  )

  const { emailsTypes, emailsStates, emailsListFilters, setFilters } =
    useEmailsDashboard()

  const methods = useForm<UseFormProps>({
    mode: 'onChange',
    defaultValues: {
      types: [],
      states: [],
    },
  })
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods

  const handleReset = () => {
    reset({
      types: [],
      states: [],
    })
  }

  const onSubmit = async (values: UseFormProps) => {
    setFilters({
      ...emailsListFilters,
      types: values.types,
      states: values.states,
    })
    onClose()
  }

  useEffect(() => {
    const filterPanelElement = document.getElementById('left-panel')
    filterPanelElement?.appendChild(filterPanelWrapper.current)
  }, [])

  return ReactDOM.createPortal(
    <div className="EmailsListFilter">
      <SlidePanel alignment="left" opened={isOpen} close={onClose}>
        <div className="EmailsListFilter__wrapper">
          <div className="EmailsListFilter__header d-flex justify-content-between align-items-center">
            <div className="EmailsListFilter__title">
              {t('filter.panel.title')}
            </div>
            <button className="non-styled-btn" onClick={onClose}>
              <Icon name="close" />
            </button>
          </div>
          <FormProvider methods={methods}>
            <div className="EmailsListFilter__form">
              <Controller
                control={control}
                name="types"
                render={({ field, fieldState }) => {
                  return (
                    <MultiSelectTreeControl
                      label={t('controlPanel.emails.info.filters.type.label')}
                      placeholder={t(
                        'controlPanel.emails.info.filters.type.placeholder'
                      )}
                      floatingFeedback
                      name={field.name}
                      error={fieldState.error}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      options={
                        emailsTypes
                          ? emailsTypes?.items.map(({ id, name }) => ({
                              label: t(
                                `tenantEmailsTemplate.table.body.name.${name}`
                              ),
                              value: id,
                              title: t(
                                  `tenantEmailsTemplate.table.body.name.${name}`
                              ),
                            }))
                          : []
                      }
                      placeholderInfo={(itemsSelected) =>
                        t('controlPanel.emails.info.filters.placeholder', {
                          itemsSelected,
                        })
                      }
                    />
                  )
                }}
              />
              <Controller
                control={control}
                name="states"
                render={({ field, fieldState }) => (
                  <MultiSelectTreeControl
                    label={t('controlPanel.emails.info.filters.state.label')}
                    placeholder={t(
                      'controlPanel.emails.info.filters.state.placeholder'
                    )}
                    floatingFeedback
                    name={field.name}
                    error={fieldState.error}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    options={
                      emailsStates
                        ? emailsStates?.items.map(({ id, name }) => ({
                            label: t(
                              `controlPanel.emails.info.table.body.status.${name.toLowerCase()}`
                            ),
                            value: id,
                            title: t(
                                `tenantEmailsTemplate.table.body.name.${name}`
                            ),
                          }))
                        : []
                    }
                    placeholderInfo={(itemsSelected) =>
                      t('controlPanel.emails.info.filters.placeholder', {
                        itemsSelected,
                      })
                    }
                  />
                )}
              />
            </div>
            <div className="EmailsListFilter__actions">
              <button
                className="btn btn-outline-primary"
                disabled={!isValid}
                onClick={() => handleReset()}
              >
                {t('filter.panel.reset')}
              </button>
              <button
                className="btn btn-primary"
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
              >
                {t('filter.panel.apply')}
              </button>
            </div>
          </FormProvider>
        </div>
      </SlidePanel>
    </div>,
    filterPanelWrapper.current
  )
}

export default EmailsListFilter
