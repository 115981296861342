import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { SelectControl } from '@liveconnect/components'
import {
  Chart as ChartJS,
  ArcElement,
  ChartOptions,
  Legend,
  Tooltip,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { addHours } from 'date-fns/esm'
import { useTranslation } from 'react-i18next'

import usePublishedMessages from '../../../../core/publishedMessages/usePublishedMessages'
import { BLUE_LIGHT_COLOR, ORANGE_COLOR } from '..'
import ForwardButton from '../../../../components/ForwardButton'

import './styles.scss'

interface Option<T> {
  value: string
  label: T
}

const PublishedMessagesGraph: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    serviceList,
    publishedStatistics,
    fetchPublishedStatistics,
    fetchServiceList,
  } = usePublishedMessages()

  const [serviceSelected, setServiceSelected] = useState<string>('')

  // Service Combo - START

  const serviceOptions: Option<string>[] = useMemo(() => {
    const allServices = {
      value: '',
      label: t('dashboard.all'),
    }
    const list = serviceList.map(
      (item) =>
        ({
          value: item,
          label: item,
        } as Option<string>)
    )
    return [allServices, ...list]
  }, [serviceList])

  // Service Combo - END

  ChartJS.register(ArcElement, Tooltip, Legend)

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const data = useMemo(() => {
    const labels = [t('dashboard.published'), t('dashboard.failed')]
    const data = {
      labels,
      datasets: [
        {
          data: [publishedStatistics?.success, publishedStatistics?.failed],
          borderColor: [BLUE_LIGHT_COLOR, ORANGE_COLOR],
          backgroundColor: [BLUE_LIGHT_COLOR, ORANGE_COLOR],
        },
      ],
    }

    return data
  }, [publishedStatistics])

  useEffect(() => {
    const actualDateTime = addHours(new Date().setMinutes(0, 0, 0), 1)

    fetchPublishedStatistics({
      startDate: addHours(actualDateTime, -24).toISOString(),
      endDate: actualDateTime.toISOString(),
      serviceName: serviceSelected,
    })
  }, [serviceSelected])

  useEffect(() => {
    !serviceList.length && fetchServiceList()
  }, [])

  return (
    <div className="PublishedMessagesGraph">
      <div className="PublishedMessagesGraph__top">
        <div className="PublishedMessagesGraph__top__title">
          {t('dashboard.emittedMessages.short')}
        </div>
        <div className="PublishedMessagesGraph__top__service">
          <SelectControl
            name="app"
            onChange={(value: string) => setServiceSelected(value)}
            label={t('dashboard.services')}
            options={serviceOptions}
            required={false}
            value={serviceSelected}
            noOptionsMessage=""
            isClearable={false}
            placeholder={t(
              'dashboard.publishedMessages.selectService.placeholder'
            )}
          />
        </div>
      </div>
      <div className="PublishedMessagesGraph__bottom">
        <div className="PublishedMessagesGraph__bottom__graph">
          <Doughnut options={options} data={data} />
        </div>

        <div className="PublishedMessagesGraph__bottom__legend">
          <div className="tag">
            <div className="tag__title">
              <div
                className="rectangle"
                style={{ background: BLUE_LIGHT_COLOR }}
              ></div>
              {t('dashboard.published')}
            </div>
            <div>{!!publishedStatistics ? publishedStatistics.success : 0}</div>
          </div>
          <div className="tag">
            <div className="tag__title">
              <div
                className="rectangle"
                style={{ background: ORANGE_COLOR }}
              ></div>
              {t('dashboard.failed')}
            </div>
            <div>{!!publishedStatistics ? publishedStatistics.failed : 0}</div>
          </div>
        </div>
      </div>
      <div className="PublishedMessagesGraph__seemore">
        <ForwardButton
          label={t('dashboard.seemore')}
          onClick={() => navigate(`./published`)}
        />
      </div>
    </div>
  )
}

export default PublishedMessagesGraph
