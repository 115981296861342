import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IMAGE_KEY, VIDEO_KEY } from '../constants'
import { UploadControlRules } from '../types'

export interface MetaDataProps {
  dimensions?: string
  rules?: UploadControlRules
}

const MetaData: FC<MetaDataProps> = ({ dimensions, rules }): JSX.Element => {
  const { t } = useTranslation()

  const formatExtensions = (extensionList?: string[]): string => {
    if (!extensionList || extensionList.length === 0) return ''
    return extensionList
      ?.map((extension) =>
        extension
          .replace(/^audio\/|video\/|image\/|application\//, '')
          .toUpperCase()
      )
      .sort((a, b) => a.localeCompare(b))
      .join(', ')
  }

  if (!rules) return <></>

  let videoExtensions = rules[VIDEO_KEY]?.allowedExtensions
  if (typeof videoExtensions === 'undefined') videoExtensions = []
  let imageExtensions = rules[IMAGE_KEY]?.allowedExtensions
  if (typeof imageExtensions === 'undefined') imageExtensions = []

  return (
    <small className="input-file__meta">
      {videoExtensions.length > 0 && (
        <span>
          {t('file.control.video.restrictions', {
            extensions: formatExtensions(videoExtensions),
            videoMaxWeight: rules[VIDEO_KEY]?.maxFileSize,
          })}
        </span>
      )}
      {imageExtensions.length > 0 && (
        <span>
          {dimensions}{' '}
          <span className="ps-1"> {formatExtensions(imageExtensions)} </span>
        </span>
      )}
    </small>
  )
}

export default MetaData
