import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { TermsConsVersion, UseTermsConsVersionsResult } from './types'
import { setCurrent, setList } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useTermsConsVersions = (): UseTermsConsVersionsResult => {
  const dispatch = useAppDispatch()
  const { current, list, isLoaded } = useAppSelector(
    (state) => state.termsConsVersions
  )
  const { get, post } = useFetch()
  const { notifyApiError } = useError()

  const errorModel = 'termsconsversion'
  const endpoint = 'termconditionsversions'

  const fetchTermsCons = async () => {
    const response: TermsConsVersion[] | undefined = await get({ endpoint })
    response && dispatch(setList(response))
  }

  const createTermsConsVersion = async () => {
    try {
      const response: TermsConsVersion | undefined = await post({
        endpoint: `${endpoint}/create`,
        body: {},
      })
      response && dispatch(setCurrent(response))
    } catch (e) {
      notifyApiError(e, errorModel, 'save')
    }
  }

  const fetchCurrent = async () => {
    const response: TermsConsVersion | undefined = await get({
      endpoint: `${endpoint}/current`,
    })
    response && dispatch(setCurrent(response))
  }

  return {
    current,
    list,
    isLoaded,
    fetchTermsCons,
    fetchCurrent,
    createTermsConsVersion,
  }
}

export default useTermsConsVersions
