import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    description: yup
      .string()
      .label(t('tenant.form.name'))
      .max(50, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 50 })
      ),
    azureActiveDirectoryLoginTitle: yup
      .string()
      .label(t('IdentityClientConfig.form.aadTitle'))
      .max(100, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 100 })
      ),
  })
}
