import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EmailsSummary } from '../types'

const initialState: EmailsSummary = {
  totalSended: 1,
  totalError: 1,
  totalPending: 1,
  data: [],
}

export const emailsSummarySlice = createSlice({
  name: 'emailsSummary',
  initialState,
  reducers: {
    setSummary: (state, action: PayloadAction<EmailsSummary>) => {
      state.totalSended = action.payload.totalSended
      state.totalError = action.payload.totalError
      state.totalPending = action.payload.totalPending
      state.data = action.payload.data
    },
  },
})

export const { setSummary } = emailsSummarySlice.actions

export default emailsSummarySlice.reducer
