import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Main } from '../../../components/Main'
import EmailsGraph from './EmailsGraph'
import EmailsList from './EmailsList'

import useEmailsDashboard from '../../../core/emailsDashboard/useEmailsDashboard'

import './styles.scss'

const EmailsDashBoard: FC = () => {
  const { t } = useTranslation()
  const {
    emailsList,
    emailsListFilters,
    fetchEmails,
    fetchEmailsTypes,
    fetchEmailsStates,
  } = useEmailsDashboard()

  const getEmailsList = useCallback(async () => {
    await fetchEmails({
      page: emailsListFilters.page,
      pageSize: emailsListFilters.pageSize,
      search: emailsListFilters.search,
      states: emailsListFilters.states,
      types: emailsListFilters.types,
    })
  }, [fetchEmails])

  const getEmailsTypes = useCallback(async () => {
    await fetchEmailsTypes()
  }, [fetchEmailsTypes])

  const getEmailsStates = useCallback(async () => {
    await fetchEmailsStates()
  }, [fetchEmailsStates])

  const initEmailsSelector = () => {
    getEmailsTypes()
    getEmailsStates()
  }

  useEffect(() => {
    getEmailsList()
  }, [emailsListFilters])

  useEffect(() => {
    initEmailsSelector()
  }, [])

  return (
    <Main ariaLabelledby="ControlPanelEmails" className="EmailsDashBoard">
      <div className="EmailsDashBoard__header">
        <h1 id="EmailsDashBoard" className="h3 EmailsDashBoard__header__title">
          {t('controlPanel.emails.title')}
        </h1>
        <p className="EmailsDashBoard__header__subtitle">
          {t('controlPanel.emails.subtitle')}
        </p>
      </div>
      <div className="EmailsDashBoard__body">
        <section className="EmailsDashBoard__body__graph">
          <EmailsGraph />
        </section>
        <section className="EmailsDashBoard__body__list">
          {emailsList && <EmailsList list={emailsList} />}
        </section>
      </div>
    </Main>
  )
}

export default EmailsDashBoard
