import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useForm } from 'react-hook-form'

import {
  FormProvider,
  FormDate,
  FormTextInput,
  FormRichText,
  FormSelect,
  Option,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { Loader } from '@liveconnect/communities-ui'

import FormActions from '../../../../components/FormActions'
import { LiveConnectUserProfile } from '../../../../core/liveconnect/types'
import useLiveconnect from '../../../../core/liveconnect/useLiveconnect'
import { buildValidationSchema } from './validations'
import useIdDocumentTypes from '../../../../core/identificationDocumentTypes/useIdentificationDocumentTypes'
import { IdDocumentType } from '../../../../core/identificationDocumentTypes/types'
import { AvatarUploadControl } from '../../../../components/forms/AvatarUploadControl'
import useNotifications from '../../../../utils/notifications/useNotifications'
import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'

import './styles.scss'

const LCProfile = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const navigate = useNavigate()
  const {
    detail: user,
    parseDetailToProfile,
    updateUser,
    uploadImage,
  } = useLiveconnect()
  const { getIdDocumentTypes } = useIdDocumentTypes()
  const [idTypes, setIdTypes] = useState<Option<IdDocumentType>[]>([])
  const [isProfileLoaded, setIsProfileLoaded] = useState<boolean>(false)

  const methods = useForm<LiveConnectUserProfile>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      email: '',
      name: '',
      surname: '',
      description: '',
      imageUrl: undefined,
      dateOfBirth: undefined,
      documentType: undefined,
      identificationDocument: '',
      hasCometchatAccount: false,
      numberOfContacts: '0',
    },
  })

  const {
    control,
    reset,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    watch,
    formState: { isDirty, isSubmitting, isValid },
  } = methods

  const { fields, append, remove } = useFieldArray<LiveConnectUserProfile>({
    control,
    name: 'positions',
  })

  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const initIdTypes = async () => {
    const _types = await getIdDocumentTypes()
    setIdTypes(_types.map((item) => ({ label: item, value: item })))
  }

  const handleRemovePosition = (index: number) => {
    remove(index)
    trigger('positions')
  }

  const onSubmit = async (values: LiveConnectUserProfile) => {
    if (values?.imageUrl?.includes('blob:')) {
      const imageUrl = await uploadImage(values.imageUrl)
      values = { ...values, imageUrl }
    }
    try {
      await updateUser(values)
      notify.success(t('liveconnect.detail.form.successFeedback'))
    } catch (e) {
      notify.error(t('liveconnect.detail.form.unknownError'))
    }
  }

  useEffect(() => {
    if (user) {
      const profile = parseDetailToProfile(user)
      profile.hasCometchatAccount = profile.hasCometchatAccount
        ? t('common.yes')
        : t('common.no')
      profile.numberOfContacts = `${profile.numberOfContacts} ${t(
        'liveconnect.detail.profile.cometchatContacts.value'
      )}`
      reset(profile)
      setIsProfileLoaded(true)
    }
  }, [user])

  const watchDocumentType = watch('documentType')
  useEffect(() => {
    if (!watchDocumentType && isProfileLoaded) {
      setValue('identificationDocument', '')
      trigger('identificationDocument')
    }
  }, [watchDocumentType, isProfileLoaded])

  useEffect(() => {
    initIdTypes()
  }, [])

  return (
    <div className="LCProfile">
      <div className="LCProfile__header d-flex align-items-center col-xs-12 col-md-10">
        <h1 className="h4 LCProfile__header__title">
          {t('liveconnect.details.header.mainData')}
        </h1>
        <span className="LCProfile__header__mandatory-fields">
          {t('liveconnect.mandatoryFields')}
        </span>
      </div>
      <FormProvider methods={methods}>
        <div className="row LCProfile__main-data">
          <div className="col-xs-12 col-md-2 LCProfile__image">
            <AvatarUploadControl
              control={control}
              name="imageUrl"
              triggerText={t('common.add')}
              deleteText={t('common.delete')}
              label={t('liveconnect.detail.profile.image')}
              accept="image/png,image/jpeg"
            />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <FormTextInput
                  control={control}
                  name="name"
                  label={t('liveconnect.detail.profile.name')}
                  placeholder={t('liveconnect.detail.profile.name')}
                  type="text"
                  required={true}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <FormTextInput
                  control={control}
                  name="surname"
                  label={t('liveconnect.detail.profile.surname')}
                  placeholder={t('liveconnect.detail.profile.surname')}
                  type="text"
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <FormTextInput
                  control={control}
                  name="email"
                  label={t('liveconnect.detail.profile.email')}
                  type="text"
                  disabled={true}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <FormDate
                  control={control}
                  name="dateOfBirth"
                  label={t('liveconnect.detail.profile.dateOfBirth')}
                  max={new Date()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="row">
                  <div className="col-5 LCProfile__document__type">
                    <FormSelect
                      control={control}
                      name="documentType"
                      label={t('liveconnect.detail.profile.documentType')}
                      placeholder={t(
                        'liveconnect.detail.profile.documentType.placeholder'
                      )}
                      options={idTypes}
                      isClearable={true}
                      noOptionsMessage={t('common.noOptionsMessage')}
                    />
                  </div>
                  <div className="col-7 LCProfile__document__number">
                    <FormTextInput
                      control={control}
                      name="identificationDocument"
                      label={t(
                        'liveconnect.detail.profile.identificationDocument'
                      )}
                      placeholder={t(
                        'liveconnect.detail.profile.identificationDocument.placeholder'
                      )}
                      type="text"
                      disabled={!getValues('documentType')}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 LCProfile__cometchat">
                <div className="row">
                  <div className="col-5 LCProfile__cometchat__user">
                    <FormTextInput
                      control={control}
                      name="hasCometchatAccount"
                      label={t('liveconnect.detail.profile.cometchatUser')}
                      placeholder={t(
                        'liveconnect.detail.profile.cometchatUser.placeholder'
                      )}
                      type="text"
                      disabled={true}
                    />
                  </div>
                  <div className="col-7 LCProfile__cometchat__contacts">
                    <FormTextInput
                      control={control}
                      name="numberOfContacts"
                      placeholder={t(
                        'liveconnect.detail.profile.cometchatContacts.placeholder'
                      )}
                      type="text"
                      disabled={true}
                      label={t(
                        'liveconnect.detail.profile.cometchatContacts.placeholder'
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row LCProfile__generic-description">
          <h4 className="LCProfile__generic-description__title">
            {t('liveconnect.details.header.aditionalInfo')}
          </h4>
          <div className="col-10">
            <FormRichText
              control={control}
              name="description"
              label={t('liveconnect.detail.profile.description')}
              placeholder={t(
                'liveconnect.detail.profile.description.placeholder'
              )}
            />
            <span>{t('liveconnect.max.characters', { label: '1000' })}</span>
          </div>
        </div>
        <div className="LCProfile__positions">
          <h4 className="title">{t('liveconnect.positions.list.title')}</h4>
          <p className="subtitle">{t('liveconnect.positions.list.subtitle')}</p>
          {fields.map((position, index) => (
            <div key={position.id} className="row">
              <div className="col-xs-12 col-md-5">
                <FormTextInput
                  control={control}
                  name={`positions.${index}.jobTitle`}
                  label={t('liveconnect.detail.profile.job')}
                  placeholder={t('liveconnect.detail.profile.job.placeholder')}
                  type="text"
                  required={!getValues(`positions.${index}.company`)}
                  onChange={() => trigger('positions')}
                />
              </div>
              <div className="col-xs-12 col-md-5">
                <FormTextInput
                  control={control}
                  name={`positions.${index}.company`}
                  label={t('liveconnect.detail.profile.company')}
                  placeholder={t(
                    'liveconnect.detail.profile.company.placeholder'
                  )}
                  type="text"
                  required={!getValues(`positions.${index}.jobTitle`)}
                  onChange={() => trigger('positions')}
                />
              </div>
              <div className="col-xs-2 col-md-1 delete">
                <Icon
                  className="delete__icon"
                  name="delete_outline"
                  onClick={() => handleRemovePosition(index)}
                />
              </div>
            </div>
          ))}
          <button
            className="btn btn-link add p-0"
            onClick={() => append({ id: null, jobTitle: '', company: '' })}
          >
            <span>{t('liveconnect.positions.list.add')}</span>
            <Icon name="add" />
          </button>
        </div>
      </FormProvider>
      <FormActions>
        <button
          className="btn btn-outline-primary"
          onClick={() => navigate('..')}
        >
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || !isDirty || !isValid}
        >
          {isSubmitting ? <Loader /> : t('common.save')}
        </button>
      </FormActions>
    </div>
  )
}

export default LCProfile
