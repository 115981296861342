import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import PrivacyPoliciesScreen from '../pages/PrivacyPolicies'
import PrivacyPoliciesCompare from '../pages/PrivacyPolicies/Form/Compare'

const PrivacyPoliciesRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivacyPoliciesScreen />} />
      <Route path="/:id1/compare/:id2" element={<PrivacyPoliciesCompare />} />
    </Routes>
  )
}

export default PrivacyPoliciesRouter
