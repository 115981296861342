import { FC } from 'react'
import { Main } from '../../../components/Main'
import { useTranslation } from 'react-i18next'
import './styles.scss'

import HistoryGraph from './HistoryGraph'

import PublishedMessagesGraph from './PublishedMessagesGraph'
import SuscribedMessagesGraph from './SuscribedMessagesGraph'

export const BLUE_LIGHT_COLOR = '#0064AB'
export const BLUE_DARK_COLOR = '#0066FF'
export const ORANGE_COLOR = '#F3722C'
export const GREEN_COLOR = '#90BE6D'
export const RED_COLOR = '#FF0000'

const IntegrationDashboard: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Main
        ariaLabelledby="profile-list-title"
        className="IntegrationDashboard"
      >
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="user-list-title" className="h3">
              {t('dashboard.title')}
            </h1>
            <p>{t('dashboard.hint')}</p>
          </div>
        </div>
        <h4 className="HistoryGraph__legends__title mb-3 mt-3">
          {t('dashboard.historyGraph.title')}
        </h4>
        <div className="IntegrationDashboard__row">
          <div className="historyGraph IntegrationDashboard__box">
            <HistoryGraph />
          </div>
        </div>
        <div className="IntegrationDashboard__row messages-doughnut-graph">
          <div className="publishedMessages IntegrationDashboard__box IntegrationDashboard__box-50">
            <PublishedMessagesGraph />
          </div>
          <div className="receivedMessages IntegrationDashboard__box IntegrationDashboard__box-50">
            <SuscribedMessagesGraph />
          </div>
        </div>
      </Main>
    </>
  )
}

export default IntegrationDashboard
