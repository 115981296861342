import { useTranslation } from 'react-i18next'
import { FetchError } from '../fetch/types'
import useNotifications from '../notifications/useNotifications'
import { ErrorActionType } from './types'

const BASE_ERROR = 'errors'
const DEFAULT = 'default'
const CUSTOM = 'custom'
const ERROR_ACTIONS = 'actions'
const ERROR_CODES = 'codes'
const ERROR_MODELS = 'models'

const useError = () => {
  const { t, i18n } = useTranslation()
  const notify = useNotifications()

  const getErrorMessage = (
    errorCode: string,
    modelCode: string,
    actionCode: ErrorActionType
  ) => {
    const errorKey = `${BASE_ERROR}.${ERROR_CODES}.${errorCode}`
    const modelKEy = `${BASE_ERROR}.${ERROR_MODELS}.${modelCode}`
    const model = i18n.exists(modelKEy)
      ? t(modelKEy)
      : `${BASE_ERROR}.${ERROR_MODELS}.${DEFAULT}`
    const action = t(`${BASE_ERROR}.${ERROR_ACTIONS}.${actionCode}`)
    if (i18n.exists(errorKey)) {
      const error = t(errorKey)
      return t(`${BASE_ERROR}.${CUSTOM}`, {
        model,
        action,
        error,
      })
    } else return t(`${BASE_ERROR}.${DEFAULT}`, { model, action })
  }

  const getApiErrorMessage = (
    error: unknown,
    model: string,
    action: ErrorActionType
  ) => {
    const fetchError = error as FetchError
    const code = fetchError.body[0]?.code
    return getErrorMessage(code, model, action)
  }

  const notifyApiError = (
    error: unknown,
    model: string,
    action: ErrorActionType
  ) => {
    const message = getApiErrorMessage(error, model, action)
    notify.error(message)
    throw error
  }

  return {
    getErrorMessage,
    notifyApiError,
  }
}

export default useError
