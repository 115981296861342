export enum Permission {
  TenantAdmin_Tenant = 'TenantAdmin_Tenant',
  TenantAdmin_Ids = 'TenantAdmin_Ids',
  TenantAdmin_CommunityAdmin = 'TenantAdmin_CommunityAdmin',
  TenantAdmin_EventAdmin = 'TenantAdmin_EventAdmin',
  TenantAdmin_ProfileAdmin = 'TenantAdmin_ProfileAdmin',
  TenantAdmin_EventEditionMaster = 'TenantAdmin_EventEditionMaster',
  TenantAdmin_LiveconnectUser = 'TenantAdmin_LiveconnectUser',
  TenantAdmin_IntegrationDashboard = 'TenantAdmin_IntegrationDashboard',
  TenantAdmin_EmailDashboard = 'TenantAdmin_EmailDashboard',
  TenantAdmin_TermsConditions = 'TenantAdmin_TermsConditions',
  TenantAdmin_PrivacyPolicy = 'TenantAdmin_PrivacyPolicy',
  TenantAdmin_TenantAdminUsers = 'TenantAdmin_TenantAdminUsers',
  TenantAdmin_IdsClients = 'TenantAdmin_IdsClients',
  TenantAdmin_ComponentDesign = 'TenantAdmin_ComponentDesign',
  TenantAdmin_EmailsTemplates = 'TenantAdmin_EmailsTemplates',
  TenantAdmin_IdsDelete = 'TenantAdmin_IdsDelete',
}

export interface PermissionListResponse {
  hasProfile: boolean
  actions: Permission[]
}
export interface PermissionsListPayload {
  list: Permission[]
}

export interface PermissionsState {
  list: Permission[]
  isLoaded: boolean
}

export enum ComplimentType {
  one = 'one',
  all = 'all',
}

export interface UsePermissionsResult {
  list: Array<Permission>
  isLoaded: boolean
  fetchList: () => void
  checkPermissions: (to: Permission[], mustComply?: ComplimentType) => boolean
}
