import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  CompareTermsConsParams,
  TermsCons,
  UseTermsConsResult,
  CompareTermsConsResponse,
  TermsConsDetail,
  TermsConsValue,
} from './types'
import { setCurrent, setList } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useTermsCons = (): UseTermsConsResult => {
  const dispatch = useAppDispatch()
  const { current, list, isLoaded } = useAppSelector((state) => state.termsCons)
  const { get, post } = useFetch()
  const { notifyApiError } = useError()

  const errorModel = 'termscons'
  const endpoint = 'termconditions'

  const fetchTermsCons = async () => {
    const response: TermsCons[] | undefined = await get({ endpoint })
    response && dispatch(setList(response))
  }

  const createTermsCons = async (termsCons: TermsConsValue[]) => {
    try {
      const response: TermsConsDetail | undefined = await post({
        endpoint,
        body: { termsConditionsValues: termsCons },
      })
      response && dispatch(setCurrent(response))
    } catch (e) {
      notifyApiError(e, errorModel, 'save')
    }
  }

  const fetchCurrent = async () => {
    const response: TermsConsDetail | undefined = await get({
      endpoint: `${endpoint}/current`,
    })
    response && dispatch(setCurrent(response))
  }

  const compareTermsCons = async (params: CompareTermsConsParams) => {
    const response: CompareTermsConsResponse | undefined = await get({
      endpoint: `${endpoint}/compare`,
      params,
    })
    return response as CompareTermsConsResponse
  }

  return {
    current,
    list,
    isLoaded,
    fetchTermsCons,
    setCurrent: (data) => dispatch(setCurrent(data)),
    createTermsCons,
    fetchCurrent,
    compareTermsCons,
  }
}

export default useTermsCons
