import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'

interface TenantDesignHeaderProps {
  labelledby: string
}

const TenantDesignHeader: FC<TenantDesignHeaderProps> = ({ labelledby }) => {
  const { t } = useTranslation()
  return (
    <div className="tenant-design-header">
      <h1 id={labelledby} className="h3 Tenant-design-header__title">
        {t('tenantDesign.header.title')}
      </h1>
      <p className="Tenant-design-header__subtitle">
        {t('tenantDesign.header.subtitle')}
      </p>
    </div>
  )
}

export default TenantDesignHeader
