import { useEffect, useCallback, useRef } from 'react'
import { useParams } from 'react-router'
import { OidcClientSettings, User } from 'oidc-client'

import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { UseAuthResult } from './types'
import { setUser, unsetUser } from './store'
import AppUserManager from './AppUserManager'
import { SigninCallbackError } from './utils'

const redirectPathKey = 'redirectPath'
const rootPath = '/'
export const postLogoutRedirectPathKey = 'postLogoutRedirectPath'

const useAuth = (): UseAuthResult => {
  const oidRef = useRef<AppUserManager>()
  const { tenantId } = useParams()
  const dispatch = useAppDispatch()
  const { user, loaded, isLoggedIn } = useAppSelector((state) => state.auth)
  const { settings } = useAppSelector((state) => state.settings)

  const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  const updateUserState = useCallback(async () => {
    const user = await getUser()
    if (!!user && !!user.access_token && !user.expired) {
      await dispatch(setUser(user))
      const userToken = parseJwt(user.access_token)
      localStorage.setItem(
        `ifema_tenant_admin_access_token_${userToken.tenant_id}`,
        user.access_token
      )
    } else {
      await dispatch(unsetUser())
    }
  }, [dispatch])

  const initOIDUser = useCallback(async (): Promise<void> => {
    if (AppUserManager.isInstanced()) {
      oidRef.current = AppUserManager.getInstance(null)
    } else {
      oidRef.current = AppUserManager.getInstance(settings)
      updateUserState()
    }
  }, [updateUserState, settings])

  const oidcSigninOptions = useCallback((): OidcClientSettings => {
    return {
      extraQueryParams: {
        tenant_id: tenantId,
        source_url: window.location.href,
      },
    }
  }, [tenantId])

  const getUser = (): Promise<User | null> | undefined => {
    return oidRef.current?.user.getUser()
  }

  const getChangePasswordUrl = (): void | undefined => {
    if (oidRef.current?.user.settings.authority) {
      const url = oidRef.current?.user.settings.authority
      const redirect = window.location.assign(url)
      return redirect
    } else {
      return undefined
    }
  }

  const login = useCallback((): void => {
    if (!AppUserManager.isInstanced()) return
    sessionStorage.removeItem(redirectPathKey)
    sessionStorage.setItem(
      redirectPathKey,
      window.location.pathname + window.location.search
    )
    oidRef.current?.user.signinRedirect(oidcSigninOptions())
  }, [oidcSigninOptions])

  const logout = useCallback(
    (url?): Promise<void> | undefined => {
      if (url) {
        sessionStorage.setItem(postLogoutRedirectPathKey, url)
      }
      return oidRef.current?.user.signoutRedirect(oidcSigninOptions())
    },
    [oidcSigninOptions]
  )

  const getPostLogoutUri = (): string | null => {
    const redirectPath = sessionStorage.getItem(postLogoutRedirectPathKey)
    sessionStorage.removeItem(postLogoutRedirectPathKey)
    return redirectPath
  }

  const renewToken = useCallback(
    async () => await oidRef?.current?.user.signinSilent(oidcSigninOptions()),
    [oidcSigninOptions]
  )

  const signinSilentCallback = useCallback(():
    | Promise<User | undefined>
    | undefined => {
    sessionStorage.removeItem(postLogoutRedirectPathKey)
    return oidRef.current?.user.signinSilentCallback()
  }, [])
  const signinRedirectCallback = useCallback(async (): Promise<{
    user: User | undefined
    redirectPath: string
  }> => {
    const redirectPath = sessionStorage.getItem(redirectPathKey) || rootPath

    try {
      const user = await oidRef.current?.user.signinRedirectCallback()
      await updateUserState()
      return { user, redirectPath }
    } catch (e) {
      throw new SigninCallbackError(e as Error, redirectPath)
    }
  }, [updateUserState])

  const handleAddUserSignedOut = useCallback(async () => {
    await oidRef.current?.user.removeUser()
    updateUserState()
  }, [updateUserState])

  // Esto habrá que pasarlo al punto de entrada de la APP
  useEffect(() => {
    oidRef.current?.user.events.addUserLoaded(() => updateUserState())
    oidRef.current?.user.events.addUserSessionChanged(() => updateUserState())
    oidRef.current?.user.events.addUserSignedOut(() => handleAddUserSignedOut())

    return (): void => {
      oidRef.current?.user.events.removeUserLoaded(() => updateUserState())
      oidRef.current?.user.events.removeUserSessionChanged(() =>
        updateUserState()
      )
      oidRef.current?.user.events.removeUserSignedOut(() =>
        handleAddUserSignedOut()
      )
    }
  }, [handleAddUserSignedOut, updateUserState])

  useEffect(() => {
    initOIDUser()
  }, [initOIDUser])

  return {
    user,
    loaded,
    isLoggedIn,
    login,
    logout,
    getPostLogoutUri,
    signinSilentCallback,
    signinRedirectCallback,
    getChangePasswordUrl,
    renewToken,
  }
}

export default useAuth
