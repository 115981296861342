import { useAppSelector, useAppDispatch } from '../reduxProvider'
import useFetch from '../../utils/fetch/useFetch'
import { UseLocalizationsResult, Localization } from './types'
import { setLocalizations } from './store'

const useLocalizations = (): UseLocalizationsResult => {
  const dispatch = useAppDispatch()
  const { localizations } = useAppSelector((state) => state.localizations)
  const { get } = useFetch()

  const endpoint = 'localizations'

  const getLocalizations = async (source = 'liveconnect') => {
    const response: Localization[] | undefined = await get({
      endpoint,
      params: { source },
    })
    return response
  }

  const fetchLocalizations = async () => {
    const response: Localization[] | undefined = await getLocalizations()
    if (response) {
      dispatch(setLocalizations(response))
    }
  }

  return {
    getLocalizations,
    fetchLocalizations,
    localizations,
  }
}

export default useLocalizations
