import { FC, useCallback, useEffect, useState } from 'react'
import { Loader, Tag } from '@liveconnect/components'
import { ContextMenu } from '@liveconnect/communities-ui'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Modal } from 'react-bootstrap'
import { Icon } from '@liveconnect/icons'

import { Main } from '../../components/Main'

import { useTenantEmailsTemplates } from '../../core/tenantEmailsTemplates/useTenantEmailsTemplates'
import useNotifications from '../../utils/notifications/useNotifications'

import { EmailsTemplate, ListAction } from './types'

import './styles.scss'

const TenantEmailsTemplates: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getTemplates, setDraftAsTemplate, removeTemplate } =
    useTenantEmailsTemplates()
  const notify = useNotifications()
  const initModalTemplateState = { id: '', name: '' }

  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false)
  const [emailsTemplates, setEmailsTemplates] = useState<
    EmailsTemplate[] | undefined
  >([])
  const [showSetDraft, setShowSetDraft] = useState<boolean>(false)
  const [showDeleteDraft, setShowDeleteDraft] = useState<boolean>(false)
  const [modalTemplate, setModalTemplate] = useState<{
    id: string
    name: string
  }>(initModalTemplateState)

  const initTemplates = useCallback(async () => {
    try {
      setLoadingTemplates(true)
      const response = await getTemplates()
      setEmailsTemplates(response)
      setLoadingTemplates(false)
    } catch (e) {
      setLoadingTemplates(false)
      throw e as Error
    }
  }, [getTemplates, setEmailsTemplates])

  const navigateTo = (id: string, creatingDraft = false) =>
    navigate(`${id}`, {
      state: {
        id,
        creatingDraft,
      },
    })

  const getActionsItems = (
    id: string,
    name: string,
    isDraft: boolean,
    hasDraft: boolean
  ): ListAction[] => {
    if (isDraft) {
      return [
        {
          onClick: () => navigateTo(id),
          label: t('tenantEmailsTemplate.table.body.actions.editDraft'),
        },
        {
          onClick: () => {
            setModalTemplate({ id, name })
            setShowSetDraft(true)
          },
          label: t('tenantEmailsTemplate.table.body.actions.setAsTemplate'),
        },
        {
          onClick: async () => {
            setModalTemplate({ id, name })
            setShowDeleteDraft(true)
          },
          label: t('tenantEmailsTemplate.table.body.actions.removeDraft'),
        },
      ]
    }
    return [
      {
        onClick: () => navigateTo(id, true),
        label: t('tenantEmailsTemplate.table.body.actions.addDraft'),
        disabled: hasDraft,
      },
      {
        onClick: () => navigateTo(id),
        label: t('tenantEmailsTemplate.table.body.actions.editTemplate'),
      },
    ]
  }

  const clearModalValues = (modal: 'setTemplate' | 'deleteDraft') => {
    if (modal === 'setTemplate') setShowSetDraft(false)
    if (modal === 'deleteDraft') setShowDeleteDraft(false)
    setModalTemplate(initModalTemplateState)
  }

  const setAsTemplate = async () => {
    try {
      await setDraftAsTemplate(modalTemplate.id)
      clearModalValues('setTemplate')
      notify.success(
        t('tenantEmailsTemplate.notification.setAsTemplate.success')
      )
      initTemplates()
    } catch {
      clearModalValues('setTemplate')
      notify.error(t('tenantEmailsTemplate.notification.setAsTemplate.error'))
    }
  }

  const deleteDraft = async () => {
    try {
      await removeTemplate(modalTemplate.id)
      clearModalValues('deleteDraft')
      notify.success(t('tenantEmailsTemplate.notification.remove.success'))
      initTemplates()
    } catch {
      clearModalValues('deleteDraft')
      notify.error(t('tenantEmailsTemplate.notification.remove.error'))
    }
  }

  useEffect(() => {
    initTemplates()
  }, [])

  return (
    <Main
      ariaLabelledby="TenantEmailsTemplates"
      className="TenantEmailsTemplates"
    >
      <div className="TenantEmailsTemplates__header">
        <h1
          id="TenantEmailsTemplates"
          className="h3 TenantEmailsTemplates__header__title"
        >
          {t('tenantEmailsTemplate.title')}
        </h1>
        <p className="TenantEmailsTemplates__header__subtitle">
          {t('tenantEmailsTemplate.subtitle')}
        </p>
      </div>
      <div className="TenantEmailsTemplates__body">
        <div className="TenantEmailsTemplates__body__header">
          <h1 className="h6 m-0">{t('tenantEmailsTemplate.table.title')}</h1>
        </div>
        <div className="TenantEmailsTemplates__body__list">
          <div className="TenantEmailsTemplates__body__list__header row">
            <div className="TenantEmailsTemplates__body__list__header__name column col-5">
              {t('tenantEmailsTemplate.table.header.name')}
            </div>
            <div className="TenantEmailsTemplates__body__list__header__status column col-1">
              {t('tenantEmailsTemplate.table.header.status')}
            </div>
            <div className="TenantEmailsTemplates__body__list__header__entity column col-1">
              {t('tenantEmailsTemplate.table.header.entity')}
            </div>
            <div className="TenantEmailsTemplates__body__list__header__language column col-1">
              {t('tenantEmailsTemplate.table.header.language')}
            </div>
            <div className="TenantEmailsTemplates__body__list__header__last-update column col-3">
              {t('tenantEmailsTemplate.table.header.lastUpdate')}
            </div>
            <div className="TenantEmailsTemplates__body__list__header__actions column col-1"></div>
          </div>
          {emailsTemplates?.map(
            ({ id, name, isDraft, usedBy, language, updateAt, hasDraft }) => (
              <div
                key={id}
                className="TenantEmailsTemplates__body__list__template row"
              >
                <div className="TenantEmailsTemplates__body__list__template__name column col-5">
                  {t(`tenantEmailsTemplate.table.body.name.${name}`)}
                </div>
                <div className="TenantEmailsTemplates__body__list__template__status column col-1">
                  {!isDraft ? (
                    <Tag type="success" icon="check_circle_outline">
                      {t('tenantEmailsTemplate.table.body.status.active')}
                    </Tag>
                  ) : (
                    <Tag type="dark">
                      {t('tenantEmailsTemplate.table.body.status.draft')}
                    </Tag>
                  )}
                </div>
                <div className="TenantEmailsTemplates__body__list__template__entity column col-1">
                  {t(`tenantEmailsTemplate.table.body.entity.${usedBy}`)}
                </div>
                <div className="TenantEmailsTemplates__body__list__template__language column col-1">
                  <Tag>{language.split('-')[0].toUpperCase()}</Tag>
                </div>
                <div className="TenantEmailsTemplates__body__list__template__last-update column col-3">
                  {new Date(updateAt).toLocaleDateString()}
                </div>
                <div className="TenantEmailsTemplates__body__list__template__actions column col-1">
                  <div className="TenantEmailsTemplates__body__list__template__actions__dropdown">
                    <ContextMenu
                      items={getActionsItems(id, name, isDraft, hasDraft)}
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {loadingTemplates && <Loader />}
      <Modal
        show={showSetDraft}
        onHide={() => clearModalValues('setTemplate')}
        size="lg"
        className="TenantEmailsTemplates__modal"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>
            {t('tenantEmailsTemplate.modal.setTemplate.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="info-icon">
            <Icon name="info_outline" />
          </div>
          <div className="info-text">
            {t(`tenantEmailsTemplate.modal.setTemplate.text`, {
              emailName: t(
                `tenantEmailsTemplate.table.body.name.${modalTemplate.name}`
              ),
            })}
          </div>
          <div className="info-subtext">
            {t(`tenantEmailsTemplate.modal.setTemplate.subtext`)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => clearModalValues('setTemplate')}
            className="btn btn-outline-primary"
          >
            {t('common.no')}
          </button>
          <button className="btn btn-primary" onClick={setAsTemplate}>
            {t('common.yes')}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteDraft}
        onHide={() => clearModalValues('deleteDraft')}
        size="lg"
        className="TenantEmailsTemplates__modal"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>
            {t(`tenantEmailsTemplate.modal.removeDraft.title`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="info-icon">
            <Icon className="warning" name="report_problem" />
          </div>
          <div className="info-text">
            {t(`tenantEmailsTemplate.modal.removeDraft.text`)}
          </div>
          <div className="info-subtext">
            {t(`tenantEmailsTemplate.modal.removeDraft.subtext`)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => clearModalValues('deleteDraft')}
            className="btn btn-outline-primary"
          >
            {t('common.cancel')}
          </button>
          <button className="btn btn-primary" onClick={deleteDraft}>
            {t('common.remove')}
          </button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}

export default TenantEmailsTemplates
