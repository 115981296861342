import { FC } from 'react'
import { Route, Routes } from 'react-router'

import PermissionRoute from '../containers/Restricted/RestrictedRoute'
import TemplateDetails from '../pages/TenantEmailsTemplates/TemplateDetails'
import TenantEmailsTemplates from '../pages/TenantEmailsTemplates'

import { Permission } from '../core/permissions/types'

const TenantEmailsTemplatesRouter: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_EmailsTemplates]}
            element={<TenantEmailsTemplates />}
          />
        }
      />
      <Route
        path="/:id"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_EmailsTemplates]}
            element={<TemplateDetails />}
          />
        }
      />
    </Routes>
  )
}

export default TenantEmailsTemplatesRouter
