import { FC } from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'
import TermsConsScreen from '../pages/TermsCons'
import TermsConsCompare from '../pages/TermsCons/Form/Compare'

const TermsConsRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<TermsConsScreen />} />
      <Route path="/:id1/compare/:id2" element={<TermsConsCompare />} />
    </Routes>
  )
}

export default TermsConsRouter
