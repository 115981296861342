// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-design-container{padding:16px 10%}.dropdown-design-container__dropdown{width:50%}`, "",{"version":3,"sources":["webpack://./src/app/pages/TenantDesign/Dropdown/Container/styles.scss"],"names":[],"mappings":"AAQA,2BACE,gBAAA,CACA,qCACE,SAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.dropdown-design-container {\n  padding: 16px 10%;\n  &__dropdown {\n    width: 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-design-container": `dropdown-design-container`,
	"dropdown-design-container__dropdown": `dropdown-design-container__dropdown`
};
export default ___CSS_LOADER_EXPORT___;
