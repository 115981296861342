import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Menu from '../Menu'
import Header from '../Header'
import Snackbar from '../Snackbar'
import SidePanel from '../SidePanel'
import ConfirmationModal from '../ConfirmationModal'

import useUi from '../../core/ui/useUi'
import ErrorPageHandler from '../../components/errors/ErrorPageHandler'

import './index.scss'

export const scrollableId = 'scrollable'

const Layout: FC = ({ children }) => {
  const location = useLocation()

  const {
    error: { isVisible: isErrorVisible, type: errorType },
    hideError,
  } = useUi()

  useEffect(() => {
    hideError()
  }, [location])

  return (
    <div className="layout">
      <div className="layout__header">
        <Header />
        <div className="layout__snackbar">
          <Snackbar />
        </div>
      </div>
      <div id="left-panel" />

      <SidePanel />
      <ConfirmationModal />

      <div className="layout__scrollable" id={scrollableId}>
        <div className="layout__content container">
          <div className="layout__nav">
            <div className="layout__nav__sticky">
              <Menu />
            </div>
          </div>
          <div className="layout__main">
            {isErrorVisible ? <ErrorPageHandler type={errorType} /> : children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
