import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  CreateProfile,
  Profile,
  ProfileDetail,
  ProfileKeyValueList,
  ProfileRequestParams,
  UseProfilesResult,
} from './types'
import { setDetail, setList, clearList, clearDetail } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useProfiles = (): UseProfilesResult => {
  const dispatch = useAppDispatch()
  const { detail, list, isLoaded } = useAppSelector((state) => state.profiles)
  const { get, post, del, put } = useFetch()
  const { notifyApiError } = useError()

  const endpoint = 'profiles'
  const errorModel = 'profiles'

  const getProfiles = async (params?: ProfileRequestParams) => {
    const response: Profile[] | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const getProfilesKeyValueList = async (params?: ProfileRequestParams) => {
    const response: ProfileKeyValueList[] | undefined = await get({
      endpoint: `${endpoint}/key-value-list`,
      params,
    })
    return response
  }

  const fetchProfiles = async (params?: ProfileRequestParams) => {
    const response = await getProfiles(params)
    response && dispatch(setList(response))
  }

  const clearProfiles = () => {
    dispatch(clearList())
  }

  const createProfile = async (data: CreateProfile) => {
    try {
      await post({
        endpoint,
        body: data,
      })
    } catch (e) {
      notifyApiError(e, errorModel, 'create')
    }
  }

  const updateProfile = async (data: CreateProfile) => {
    try {
      await put({
        endpoint,
        id: data.id,
        body: data,
      })
    } catch (e) {
      notifyApiError(e, errorModel, 'edit')
    }
  }

  const getDetail = async (id: string) => {
    const response: ProfileDetail | undefined = await get({
      endpoint: `${endpoint}/${id}`,
    })
    return response
  }

  const fetchDetail = async (id: string) => {
    const response: ProfileDetail | undefined = await getDetail(id)
    response && dispatch(setDetail(response))
  }

  const clearProfileDetail = () => {
    dispatch(clearDetail())
  }

  const deleteProfile = async (id: string) => {
    try {
      await del({ endpoint, id })
    } catch (e) {
      notifyApiError(e, errorModel, 'delete')
    }
  }

  const changeIsDefault = async (id: string, isDefault: boolean) => {
    try {
      await post({
        endpoint: `${endpoint}/${id}/change-default`,
        body: { isDefault },
      })
    } catch (e) {
      notifyApiError(e, errorModel, 'default')
    }
  }

  return {
    detail,
    list,
    isLoaded,
    getProfiles,
    getProfilesKeyValueList,
    fetchProfiles,
    clearProfiles,
    getDetail,
    fetchDetail,
    clearProfileDetail,
    createProfile,
    updateProfile,
    deleteProfile,
    changeIsDefault,
  }
}

export default useProfiles
