import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { UseMeResult, Me, IsoCode } from './types'
import { setDetail } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useMe = (): UseMeResult => {
  const dispatch = useAppDispatch()
  const { detail } = useAppSelector((state) => state.me)
  const { get, post } = useFetch()

  const endpoint = 'me'

  const fetchDetail = async () => {
    const response: Me | undefined = await get({ endpoint })
    response && dispatch(setDetail(response))
  }

  const changeLocalization = async (isoCode: IsoCode) => {
    const response = await post({
      endpoint: `${endpoint}/localization`,
      body: { isoCode },
    })
    response && dispatch(setDetail(response as Me))
  }

  return {
    me: detail,
    fetchMe: fetchDetail,
    changeLocalization,
  }
}

export default useMe
