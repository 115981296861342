// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop{position:fixed;left:0;height:100%;width:100%;background-color:rgba(41,33,82,.7)}`, "",{"version":3,"sources":["webpack://./src/app/components/Backdrop/styles.scss"],"names":[],"mappings":"AAQA,UACE,cAAA,CACA,MAAA,CACA,WAAA,CACA,UAAA,CACA,kCAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.backdrop {\n  position: fixed;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  background-color: rgba($blue-00, 0.7);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `backdrop`
};
export default ___CSS_LOADER_EXPORT___;
