import classNames from 'classnames'
import { Controller, Control, Path } from 'react-hook-form'
import { Icon } from '@liveconnect/icons'

import './styles.scss'

interface AvatarUploadControlProps<T> {
  control: Control<T, object>
  name: Path<T>
  label: string
  triggerText: string
  deleteText: string
  required?: boolean
  accept: string
}

export const AvatarUploadControl = <T,>({
  control,
  name,
  triggerText,
  deleteText,
  label,
  required,
  accept,
}: AvatarUploadControlProps<T>) => {
  const handleChange = (
    newImg: File | null | undefined,
    onChange: (v: string) => void
  ) => {
    const url = newImg ? URL.createObjectURL(newImg) : ''
    onChange(url)
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className="lc-form-control-group avatar-input-file">
          <label className={classNames('form-label', { required })}>
            {label}
          </label>
          <div className="avatar-input-file__image-wrapper d-flex justify-content-center align-items-center">
            {field.value ? (
              <img src={field.value as string} alt="" className="w-100 h-100" />
            ) : (
              <Icon name="photo_camera" />
            )}
          </div>
          <div className="avatar-input-file__info">
            {!field.value ? (
              <label className="btn btn-link p-0 m-0">
                <span className="fw-bold">{triggerText}</span>
                <input
                  name="avatar-input-file-upload"
                  type="file"
                  hidden
                  accept={accept}
                  onChange={(event) => {
                    handleChange(event.target.files?.item(0), field.onChange)
                  }}
                />
              </label>
            ) : (
              <button
                className="btn btn-link p-0 m-0"
                onClick={() => field.onChange('')}
              >
                <span className="fw-bold">{deleteText}</span>
              </button>
            )}
          </div>
        </div>
      )}
    />
  )
}
