import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EventsListPayload, EventsState } from './types'

const initialState: EventsState = {
  list: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearList: () => initialState,
    setList: (state, action: PayloadAction<EventsListPayload>) => {
      state.list = action.payload.list
    },
  },
})

export const { showLoader, hideLoader, clearList, setList } = slice.actions

export default slice.reducer
