import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormProvider, FormTextInput, useTheme } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'
import useDesignConstants from '../../hooks'
import useTenantDesign from '../../../../core/tenantDesign/useTenantDesign'
import useNotifications from '../../../../utils/notifications/useNotifications'
import useUi from '../../../../core/ui/useUi'

import { ThemeConfigurationFormProps } from './types'
import { Color, ConfigurationProps } from '../../types'

import './styles.scss'

const ThemeConfiguration: FC<ConfigurationProps> = ({
  colors,
  triggerCancel,
  formCancelled,
  triggerSubmit,
  formSubmitted,
  formDirty,
}) => {
  const { t } = useTranslation()
  const { changeThemeVars } = useTheme()
  const { defaultTheme } = useDesignConstants()
  const { editDesign } = useTenantDesign()
  const notify = useNotifications()
  const { showConfirmation } = useUi()

  const [showResetModal, setShowResetModal] = useState<boolean>(false)

  const methods = useForm<ThemeConfigurationFormProps>({
    mode: 'onChange',
    defaultValues: {
      'theme-background-primary': colors['theme-background-primary'],
      'theme-background-secondary': colors['theme-background-secondary'],
      'theme-initial': colors['theme-initial'],
      'theme-hover': colors['theme-hover'],
      'theme-badget': colors['theme-badget'],
    },
  })

  const {
    control,
    reset,
    getValues,
    formState: { isDirty },
  } = methods

  const handleChangeColor = (color: Record<string, string>) => {
    changeThemeVars(color)
  }

  const handleResetColor = () => {
    reset()
    handleChangeColor({ ...getValues() })
    formCancelled()
    notify.success(t('tenantDesign.configuration.notification'))
  }

  const submitDesign = async () => {
    try {
      const data: Color[] = await Object.entries({ ...getValues() }).map(
        (color) => ({ key: color[0], value: color[1] })
      )
      await editDesign(data, 'theme')
      reset({ ...getValues() })
      formSubmitted()
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (triggerCancel()) handleResetColor()
  }, [triggerCancel])

  useEffect(() => {
    if (triggerSubmit()) submitDesign()
  }, [triggerSubmit])

  useEffect(() => {
    formDirty(isDirty)
  }, [isDirty])

  useEffect(() => {
    showResetModal &&
      showConfirmation({
        title: t('tenantDesign.configuration.reset'),
        subtitle: t('tenantDesign.configuration.modal.body'),
        confirmText: t('tenantDesign.configuration.modal.reset'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        onConfirm: () => {
          handleResetColor()
          setShowResetModal(false)
        },
        onCancel: () => {
          setShowResetModal(false)
        },
      })
  }, [showResetModal])

  useEffect(() => {
    return () => changeThemeVars(defaultTheme)
  }, [])

  useBlockRouteChangeWithDialog(isDirty)

  return (
    <div className="theme-design-configuration">
      <FormProvider methods={methods}>
        <div className="theme-design-configuration__title">
          {t('tenantDesign.configuration.title')}
        </div>
        <hr className="theme-design-configuration__separator" />
        <div className="theme-design-configuration__body">
          <p className="theme-design-configuration__body__title">
            {t('tenantDesign.theme.background')}
          </p>
          <div className="theme-design-configuration__body__picker d-flex align-items-center">
            <div className="theme-design-configuration__body__picker__label">
              {t('tenantDesign.theme.background.primary')}
            </div>
            <FormTextInput
              control={control}
              name="theme-background-primary"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <div className="theme-design-configuration__body__picker d-flex align-items-center">
            <div className="theme-design-configuration__body__picker__label">
              {t('tenantDesign.theme.background.secondary')}
            </div>
            <FormTextInput
              control={control}
              name="theme-background-secondary"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <p className="theme-design-configuration__body__title">
            {t('tenantDesign.theme.iconsAndTexts')}
          </p>
          <div className="theme-design-configuration__body__picker d-flex align-items-center">
            <div className="theme-design-configuration__body__picker__label">
              {t('tenantDesign.theme.iconsAndTexts.initial')}
            </div>
            <FormTextInput
              control={control}
              name="theme-initial"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <div className="theme-design-configuration__body__picker d-flex align-items-center">
            <div className="theme-design-configuration__body__picker__label">
              {t('tenantDesign.theme.iconsAndTexts.hover')}
            </div>
            <FormTextInput
              control={control}
              name="theme-hover"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <p className="theme-design-configuration__body__title">
            {t('tenantDesign.theme.notifications')}
          </p>
          <div className="theme-design-configuration__body__picker d-flex align-items-center">
            <div className="theme-design-configuration__body__picker__label">
              {t('tenantDesign.theme.notifications.color')}
            </div>
            <FormTextInput
              control={control}
              name="theme-badget"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
        </div>
        <div
          className="theme-design-configuration__footer d-flex align-items-center"
          onClick={() => isDirty && setShowResetModal(true)}
        >
          <div className="theme-design-configuration__footer__label">
            {t('tenantDesign.configuration.reset')}
          </div>
          <Icon
            className="theme-design-configuration__footer__icon"
            name="upgrade"
          />
        </div>
      </FormProvider>
    </div>
  )
}

export default ThemeConfiguration
