import { Component, HTMLProps } from 'react'

import { UnknownError } from '../../../pages/error/UnknownError'

type ErrorBoundaryState = {
  hasError: boolean
}

type ErrorBoundaryProps = HTMLProps<HTMLDivElement>

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <UnknownError />
    }

    return <>{this.props.children}</>
  }
}
