import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    value: yup
      .string()
      .label(t('termsCons.form.value.label'))
      .required(({ label }) => t('validations.required', { label })),
  })
}
