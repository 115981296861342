import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, FormTextInput } from '@liveconnect/components'

import useNotifications from '../../../utils/notifications/useNotifications'
import { CreateEditionType, Edition } from '../../../core/editions/types'
import { buildValidationSchema } from './validations'
import useEditions from '../../../core/editions/useEditions'
import useEvents from '../../../core/events/useEvents'
import { Event } from '../../../core/events/types'
import { FetchError } from '../../../utils/fetch/types'

import './styles.scss'

interface CreateEditionProps {
  onClose: () => void
  edition?: Edition
  event: Event
}

const CreateEdition: FC<CreateEditionProps> = ({ edition, event, onClose }) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { createEdition, updateEdition } = useEditions()
  const { fetchList } = useEvents()

  const methods = useForm<CreateEditionType>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: edition
      ? edition
      : {
          code: '',
          name: '',
        },
  })
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
  } = methods

  const onSubmit = async (newEdition: CreateEditionType) => {
    setIsSubmitting(true)
    try {
      if (edition) {
        await updateEdition({ ...edition, ...newEdition })
      } else {
        await createEdition({ ...newEdition, eventId: event.id })
      }
      await fetchList()
      const successMsg = edition
        ? 'edition.edit.modal.successFeedback'
        : 'edition.create.modal.successFeedback'
      notify.success(t(successMsg))
      onClose()
    } catch (error: unknown) {
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        setError('code', {
          type: 'custom',
          message: t(`validations.ENTITY_CONFLICT`, {
            label: t(`edition.create.modal.code`).toLowerCase(),
          }),
        })
      } else {
        onClose()
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      className="CreateEdition"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {edition
            ? t('edition.edit.modal.title')
            : t('edition.create.modal.title')}
        </Modal.Title>
      </Modal.Header>
      <FormProvider methods={methods}>
        <Modal.Body>
          <div className="d-flex CreateEdition__legend">
            <div className="bd-highlight">{t('common.required')}</div>
          </div>
          <div className="mt-3">
            <h5>{t(`common.modal.main-data`)}</h5>
          </div>
          <div className="row">
            <div className="col-6">
              <FormTextInput
                control={control}
                name="code"
                label={t('edition.create.modal.code')}
                placeholder={t('edition.create.modal.code.placeholder')}
                type="text"
                required={true}
                disabled={!!edition}
              />
            </div>
            <div className="col-6">
              <FormTextInput
                control={control}
                name="name"
                label={t('edition.create.modal.name')}
                placeholder={t('edition.create.modal.name.placeholder')}
                type="text"
                required={true}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={onClose}>
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
          >
            {edition ? t('common.save') : t('common.create')}
          </button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

export default CreateEdition
