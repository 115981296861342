import {
  UseIdentityClientsResult,
  IdentityClient,
  IdentityClientProperties,
} from './types'

import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'
import useUploadImage from '../shared/useUploadImage'

const useIdentityClients = (): UseIdentityClientsResult => {
  const { get, put } = useFetch()
  const { notifyApiError } = useError()
  const { uploadImage } = useUploadImage()

  const endpoint = 'identity-clients'
  const errorModel = 'client'

  const getClientList = async () => {
    const response: IdentityClient[] | undefined = await get({
      endpoint,
    })
    return response
  }

  const getClientProperties = async (id: string, language: string) => {
    const response: IdentityClientProperties | undefined = await get({
      endpoint: `${endpoint}/${id}/properties/${language}`,
    })
    if (response && response.description === null) response.description = ''
    return response
  }

  const setClientProperties = async (
    id: string,
    data: IdentityClientProperties
  ) => {
    try {
      const response: IdentityClientProperties | undefined = await put({
        endpoint: `${endpoint}/${id}/properties`,
        body: data,
      })
      return response
    } catch (e) {
      notifyApiError(e, errorModel, 'edit')
    }
  }

  return {
    getClientList,
    getClientProperties,
    setClientProperties,
    uploadImage: (image) => uploadImage(endpoint, image),
  }
}

export default useIdentityClients
