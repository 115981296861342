// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tenant .gadm-title{margin-top:46px}.Tenant .gadm-hint{margin-bottom:32px}`, "",{"version":3,"sources":["webpack://./src/app/pages/Tenant/styles.scss"],"names":[],"mappings":"AASE,oBACE,eAAA,CAEF,mBACE,kBAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.Tenant{\n  .gadm-title {\n    margin-top: 46px;\n  }\n  .gadm-hint {\n    margin-bottom: 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tenant": `Tenant`,
	"gadm-title": `gadm-title`,
	"gadm-hint": `gadm-hint`
};
export default ___CSS_LOADER_EXPORT___;
