import { FC } from 'react'

import { Main } from '../../components/Main'
import useFetch from '../../utils/fetch/useFetch'

import './styles.scss'

const TokenScreen: FC = () => {
  const { get } = useFetch()
  const token: string = localStorage.ifema_tenant_admin_access_token || ''

  const handleTestIdentity = async () => {
    const response = await get({
      endpoint: 'test/uno',
      params: { email: 'admin.ifepl@yopmail.com' },
    })
    console.log(`Identity: ${response}`)
  }

  const handleTestNonIdentity = async () => {
    const response = await get({
      endpoint: 'test/uno',
    })
    console.log(`Sin Identity: ${response}`)
  }

  return (
    <Main ariaLabelledby="empty-title" className="Token">
      <div className="Token__text">{token}</div>

      <button onClick={() => navigator.clipboard.writeText(token)}>
        Copy Only Token
      </button>
      <button onClick={() => navigator.clipboard.writeText('Bearer ' + token)}>
        Copy Bearer + Token
      </button>
      <button onClick={handleTestIdentity}>Test Identity</button>
      <button onClick={handleTestNonIdentity}>Test Sin Identity</button>
    </Main>
  )
}

export default TokenScreen
