import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { FormSelect } from '@liveconnect/components'
import { useForm } from 'react-hook-form'

import useEmailsDashboard from '../../../../core/emailsDashboard/useEmailsDashboard'
import useCulture from '../../../../utils/culture'

import './styles.scss'

const GREEN_COLOR = '#5CC18E'
const ORANGE_COLOR = '#FBC400'
const RED_COLOR = '#E32424'

interface UseFormProps {
  type: string
}

const EmailsGraph: FC = () => {
  const { t } = useTranslation()
  const { formatDate } = useCulture()
  const { emailsTypes, emailsSummary, fetchEmailsSummary } =
    useEmailsDashboard()

  const methods = useForm<UseFormProps>({
    mode: 'onChange',
    defaultValues: {
      type: '',
    },
  })

  const { control, watch } = methods

  const typeFilter = watch('type')

  const [dateFiltered, setDateFiltered] = useState<
    'last-week' | 'last-two-weeks' | 'last-month'
  >('last-week')

  const DateFilterObj = useMemo(() => {
    return {
      'last-week': 7,
      'last-two-weeks': 15,
      'last-month': 30,
    }
  }, [])

  const filterSummary = useCallback(
    async (daysAgo: number, type?: string) =>
      await fetchEmailsSummary(daysAgo, type),
    [fetchEmailsSummary]
  )

  useEffect(() => {
    filterSummary(DateFilterObj[dateFiltered], typeFilter)
  }, [dateFiltered, typeFilter])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

  const options: ChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        ticks: {
          callback: (tickValue: number | string) => {
            const label = data.labels?.at(+tickValue)
            const labelSplitted = label?.split(' ')[0]
            const labelBeforeSplitted = data.labels
              ?.at(+tickValue - 1)
              ?.split(' ')[0]

            if (tickValue === 0 || labelSplitted != labelBeforeSplitted) {
              return label
            } else {
              return label?.split(' ')[3]
            }
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  }

  const data = useMemo(() => {
    const labels = emailsSummary?.data.map((x) =>
      formatDate(new Date(x.date as string) as Date, 'LONG_DATE_TIME')
    )
    return {
      labels,
      datasets: [
        {
          label: t('controlPanel.emails.graph.totals.sended'),
          data: emailsSummary?.data.map((x) => x.sended),
          borderColor: GREEN_COLOR,
          backgroundColor: GREEN_COLOR,
        },
        {
          label: t('controlPanel.emails.graph.totals.pending'),
          data: emailsSummary?.data.map((x) => x.pending),
          borderColor: ORANGE_COLOR,
          backgroundColor: ORANGE_COLOR,
        },
        {
          label: t('controlPanel.emails.graph.totals.error'),
          data: emailsSummary?.data.map((x) => x.error),
          borderColor: RED_COLOR,
          backgroundColor: RED_COLOR,
        },
      ],
    }
  }, [emailsSummary])

  return (
    <>
      <div>
        <div className="EmailsGraph">
          <h1 className="EmailsGrap__title h5">
            {t('controlPanel.emails.graph.title')}
          </h1>
          <div className="EmailsGraph__data">
            <section className="EmailsGraph__data__header d-flex align-items-center justify-content-between">
              <div className="EmailsGraph__data__header__filters d-flex align-items-center">
                <div className="EmailsGraph__data__header__filters__last-week">
                  <button
                    className={classNames('btn btn-outline-primary', {
                      active: dateFiltered === 'last-week',
                    })}
                    onClick={() => setDateFiltered('last-week')}
                  >
                    {t('controlPanel.emails.graph.filters.week.last')}
                  </button>
                </div>
                <div className="EmailsGraph__data__header__filters__last-two-weeks">
                  <button
                    className={classNames('btn btn-outline-primary', {
                      active: dateFiltered === 'last-two-weeks',
                    })}
                    onClick={() => setDateFiltered('last-two-weeks')}
                  >
                    {t('controlPanel.emails.graph.filters.week.two.last')}
                  </button>
                </div>
                <div className="EmailsGraph__data__header__filters__last-month">
                  <button
                    className={classNames('btn btn-outline-primary', {
                      active: dateFiltered === 'last-month',
                    })}
                    onClick={() => setDateFiltered('last-month')}
                  >
                    {t('controlPanel.emails.graph.filters.month.last')}
                  </button>
                </div>
              </div>
              <div className="EmailsGraph__data__header__totals d-flex align-items-center">
                <div className="EmailsGraph__data__header__totals__sended item">
                  <div className="dot dot--green"></div>
                  <div className="label">
                    {t('controlPanel.emails.graph.totals.sended')}
                  </div>
                  <div className="quantity">{emailsSummary?.totalSended}</div>
                </div>
                <div className="EmailsGraph__data__header__totals__pending item">
                  <div className="dot dot--orange"></div>
                  <div className="label">
                    {t('controlPanel.emails.graph.totals.pending')}
                  </div>
                  <div className="quantity">{emailsSummary?.totalPending}</div>
                </div>
                <div className="EmailsGraph__data__header__totals__error item">
                  <div className="dot dot--red"></div>
                  <div className="label">
                    {t('controlPanel.emails.graph.totals.error')}
                  </div>
                  <div className="quantity">{emailsSummary?.totalError}</div>
                </div>
              </div>
            </section>
            <section className="EmailsGraph__data__body">
              <div className="resume">
                <div className="EmailsGraph__graph">
                  <Line options={options} data={data} />
                </div>
              </div>
            </section>
            <section className="EmailsGraph__data__footer row">
              <div className="col-6">
                <FormSelect
                  control={control}
                  name="type"
                  label={t('controlPanel.emails.graph.filters.type.label')}
                  options={
                    emailsTypes?.items.map((type) => ({
                      label: type.name,
                      value: type.name,
                    })) ?? []
                  }
                  placeholder={t(
                    'controlPanel.emails.graph.filters.type.placeholder'
                  )}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailsGraph
