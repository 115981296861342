import { Pagination, SelectControl } from '@liveconnect/components'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Main } from '../../components/Main'
import useEventAdmins from '../../core/eventAdmins/useEventAdmins'
import useEvents from '../../core/events/useEvents'
import ProfilesModal from './ProfilesModal'
import { Profile } from '../../core/profiles/types'
import useNotifications from '../../utils/notifications/useNotifications'
import useUi from '../../core/ui/useUi'
import { EventAdmin } from '../../core/eventAdmins/types'
import { Event } from '../../core/events/types'
import CreateEventAdmin from './CreateEventAdmin'

import './styles.scss'
import ContentTable from '../../components/ContentTable'
import { ColumnDef, Row } from '@tanstack/react-table'

const EventAdminsScreen: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const {
    list: eventAdmins,
    isLoaded,
    search,
    page,
    availablePages,
    availableItems,
    pageSize,
    fetchList: fetchListAdmins,
    setEndpoint,
    clearList: clearAdminsList,
    deleteAdmin,
  } = useEventAdmins()
  const { getList: getEventsList } = useEvents()

  const [eventId, setEventId] = useState<string>('')
  const [eventsList, setEventsList] = useState<Event[]>([])
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showProfiles, setShowProfiles] = useState<Profile[] | null>(null)
  const [edittingUser, setEdittingUser] = useState<EventAdmin>()

  const eventsOptions = useMemo(
    () => eventsList.map((item) => ({ value: item.id, label: item.code })),
    [eventsList]
  )

  const initEvents = async () => {
    const events = await getEventsList()
    events && setEventsList(events)
  }

  const handleChangeApplication = (value: string) => {
    const eventId = eventsList.find((item) => item.id === value)?.id
    if (eventId) {
      setEventId(eventId)
      setEndpoint(eventId)
    }
  }

  const handleDelete = async (id: string) => {
    try {
      setEndpoint(eventId)
      await deleteAdmin(id)
      notify.success(t('eventAdminsModal.delete.successFeedback'))
      fetchListAdmins({ page, search })
    } catch (e) {}
  }

  const handleConfirmDelete = (id: string) => {
    showConfirmation({
      title: t('eventAdminsModal.delete.modal.title'),
      subtitle: t('eventAdminsModal.delete.modal.subtitle'),
      iconName: 'report_problem',
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        handleDelete(id)
      },
    })
  }

  const actions = (row: EventAdmin) => {
    return [
      {
        onClick: () => setEdittingUser(row),
        label: t('eventAdmins.list.table.action.edit'),
        icon: 'edit',
      },
      {
        onClick: () => handleConfirmDelete(row.id),
        label: t('eventAdmins.list.table.action.delete'),
        icon: 'delete_outline',
      },
    ]
  }

  const columns: ColumnDef<EventAdmin>[] = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: t('eventAdmins.list.table.email'),
      },
      {
        accessorKey: 'name',
        header: t('eventAdmins.list.table.name'),
        cell: ({ row }: { row: Row<EventAdmin> }) =>
          `${row.original.name} ${row.original.surname}`,
      },
      {
        accessorKey: 'profiles',
        header: t('eventAdmins.list.table.profiles'),
        cell: ({ row }: { row: Row<EventAdmin> }) =>
          renderProfilesCell(row.original),
      },
    ],
    []
  )

  const renderProfilesCell = (row: EventAdmin) => {
    if (!row.profiles?.length) return ''
    return row.profiles.length === 1 ? (
      row.profiles[0].name
    ) : (
      <div
        className="EventAdmins__profiles"
        onClick={() => setShowProfiles(row.profiles)}
      >
        <div className="EventAdmins__profiles__cell">{`${
          row.profiles.length
        } ${t('eventAdmins.list.table.profiles')}`}</div>
      </div>
    )
  }

  const handleCloseForm = () => {
    setShowCreateModal(false)
    setEdittingUser(undefined)
  }

  useEffect(() => {
    initEvents()
    return () => {
      clearAdminsList()
    }
  }, [])

  useEffect(() => {
    if (eventId) {
      setEndpoint(eventId)
      fetchListAdmins()
    }
  }, [eventId])

  return (
    <>
      <Main ariaLabelledby="profile-list-title" className="EventAdmins">
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="user-list-title" className="h3">
              {t('eventAdmins.title')}
            </h1>
            <p>{t('eventAdmins.hint')}</p>
          </div>
          <button
            className="btn btn-primary h-100"
            onClick={() => setShowCreateModal(true)}
            disabled={!eventId}
          >
            {t('eventAdmins.add')}
          </button>
        </div>
        <div className="d-flex justify-content-between">
          <div className="w-50">
            <SelectControl
              name="app"
              onChange={handleChangeApplication}
              label={t('eventAdmins.selectApp.label')}
              options={eventsOptions}
              required={true}
              value={eventId}
              noOptionsMessage=""
              isClearable={false}
              placeholder={t('eventAdmins.selectApp.placeholder')}
            />
          </div>
        </div>
        {eventId && (
          <>
            <section className="mt-3">
              <div className="EventAdmins__list">
                <ContentTable
                  title={t('admin.table.title')}
                  columns={columns}
                  data={eventAdmins}
                  emptyText={t('eventAdmins.emptyList')}
                  actions={actions}
                  onSearch={(s: string) => {
                    fetchListAdmins({ page: 1, search: s })
                  }}
                  isLoaded={isLoaded}
                  searchPlaceholder={t('eventAdmins.search')}
                />
                <Pagination
                  currentPage={(page || 1) - 1}
                  totalPages={availablePages}
                  totalItems={availableItems}
                  pageSize={pageSize}
                  singlePageText={(count) =>
                    t('common.pager.singlePage', { count })
                  }
                  multiplePageText={(start, end, total) =>
                    t('common.pager.multiplePage', { start, end, total })
                  }
                  onChange={(pageIndex) =>
                    fetchListAdmins({ page: pageIndex + 1 })
                  }
                />
              </div>
            </section>
          </>
        )}
      </Main>
      {(showCreateModal || edittingUser) && (
        <CreateEventAdmin
          onClose={handleCloseForm}
          eventId={eventId}
          admin={edittingUser}
        />
      )}
      {showProfiles && (
        <ProfilesModal
          onClose={() => setShowProfiles(null)}
          profiles={showProfiles}
        />
      )}
    </>
  )
}

export default EventAdminsScreen
