import { useRef } from 'react'
import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  MessageFilters,
  MessageStatus,
  PublishedMessages,
  PublishedMessagesDetail,
  PublishedMessagesRequestParams,
  PublishedStatistics,
  PublishedStatisticsRequestParams,
  UsePublishedMessages,
} from './types'
import {
  setList,
  clearStore,
  setDetail,
  showLoader,
  hideLoader,
  showDetailLoader,
  hideDetailLoader,
  setFilters,
  setServiceList,
  setMessageStatusList,
  setPublishedStatistics,
  clearMessageListStore,
} from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const usePublishedMessages = (): UsePublishedMessages => {
  const dispatch = useAppDispatch()
  const {
    detail,
    list,
    isLoaded,
    isDetailLoaded,
    serviceList,
    publishedStatistics,
    messageStatusList,
    serviceSelected,
    statusSelected,
  } = useAppSelector((state) => state.publishedMessages)
  const { get, post } = useFetch()
  const { notifyApiError } = useError()
  const listController = useRef<AbortController>()

  const endpoint = 'dashboard'
  const errorModel = 'message'

  const getPublishedMessages = async (
    params?: PublishedMessagesRequestParams,
    signal?: AbortSignal
  ) => {
    const response: PublishedMessages[] | undefined = await get({
      endpoint: `${endpoint}/published-messages`,
      params,
      signal,
    })
    return response
  }

  const fetchList = async (params?: PublishedMessagesRequestParams) => {
    try {
      listController.current?.abort()
      listController.current = new AbortController()
      dispatch(showLoader())
      const response = await getPublishedMessages(
        params,
        listController.current.signal
      )
      response && dispatch(setList(response))
      dispatch(hideLoader())
    } catch (e) {}
  }

  const fetchDetail = async (key: string) => {
    dispatch(showDetailLoader())
    const response: PublishedMessagesDetail | undefined = await get({
      endpoint: `${endpoint}/${sanitizeRouterParameter(key)}`,
    })

    response && dispatch(setDetail(response))
    dispatch(hideDetailLoader())
  }

  const fetchServiceList = async () => {
    const response: string[] | undefined = await get({
      endpoint: `${endpoint}/publishers`,
    })
    response && dispatch(setServiceList(response))
  }

  const fetchMessageStatusList = async () => {
    const response: MessageStatus[] | undefined = await get({
      endpoint: `${endpoint}/messages-status-types?boxtype=outbox`,
    })
    response && dispatch(setMessageStatusList(response.map((x) => x.status)))
  }

  const getStatistics = async (params?: PublishedStatisticsRequestParams) => {
    const response: PublishedStatistics | undefined = await get({
      endpoint: `${endpoint}/published-messages-statistics`,
      params,
    })
    return response
  }

  const fetchPublishedStatistics = async (
    params?: PublishedStatisticsRequestParams
  ) => {
    dispatch(showLoader())
    const response = await getStatistics(params)
    response && dispatch(setPublishedStatistics(response))
    dispatch(hideLoader())
  }

  const republishMessages = async (keys: string[]) => {
    try {
      await post({ endpoint: `${endpoint}/republish`, body: { keys: keys } })
    } catch (e) {
      notifyApiError(e, errorModel, 'republish')
    }
  }

  const sanitizeRouterParameter = (parameter: string) => {
    return parameter.replace(/\//g, '%2F')
  }

  return {
    detail,
    list,
    isLoaded,
    isDetailLoaded,
    serviceList,
    serviceSelected,
    statusSelected,
    messageStatusList,
    publishedStatistics,
    fetchList,
    clearStore: () => dispatch(clearStore()),
    clearMessageListStore: () => dispatch(clearMessageListStore()),
    setFilters: (filters: MessageFilters) => dispatch(setFilters(filters)),
    fetchDetail,
    fetchServiceList,
    fetchMessageStatusList,
    fetchPublishedStatistics,
    republishMessages,
  }
}

export default usePublishedMessages
