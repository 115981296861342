import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination, Tag } from '@liveconnect/components'
import { ColumnDef } from '@tanstack/react-table'

import { Main } from '../../components/Main'
import { IdentityUser, IdentityUserStatus } from '../../core/identity/types'
import useIdentity from '../../core/identity/useIdentity'
import useNotifications from '../../utils/notifications/useNotifications'
import useUi from '../../core/ui/useUi'
import usePermissions from '../../core/permissions/usePermissions'
import { Permission } from '../../core/permissions/types'
import ContentTable from '../../components/ContentTable'

import './styles.scss'

const IdentityScreen: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { checkPermissions } = usePermissions()
  const { showConfirmation } = useUi()
  const {
    list,
    availableItems,
    availablePages,
    pageSize,
    page,
    isLoaded,
    fetchList,
    clearList,
    blockUser,
    unblockUser,
    sendValidationdEmail,
    sendRestartPasswordEmail,
    deleteUser,
  } = useIdentity()

  const getTagType = (type: string) => {
    switch (type) {
      case 'NotVerified':
        return 'dark'
      case 'Blocked':
        return 'error'
      case 'Verified':
        return 'success'
      default:
        return 'disabled'
    }
  }

  const handleConfirmBlockUser = (id: string) => {
    showConfirmation({
      title: t('identity.list.table.action.block.title'),
      subtitle: t('identity.list.table.action.block.subtitle'),
      confirmText: t('identity.list.table.action.block.button'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        handleBlockUser(id)
      },
    })
  }

  const handleBlockUser = async (id: string) => {
    try {
      await blockUser(id)
      notify.success(t('identity.list.table.action.block.successFeedback'))
    } catch (e) {}
  }

  const handleUnblockUser = async (id: string) => {
    try {
      await unblockUser(id)
      notify.success(t('identity.list.table.action.unblock.successFeedback'))
    } catch (e) {}
  }

  const handleSendValidation = async (id: string) => {
    try {
      await sendValidationdEmail(id)
      notify.success(t('identity.list.table.action.email.successFeedback'))
    } catch (e) {
      notify.error(t('identity.list.table.action.email.unknownError'))
    }
  }

  const handleSendPassword = async (id: string) => {
    try {
      await sendRestartPasswordEmail(id)
      notify.success(t('identity.list.table.action.reset.successFeedback'))
    } catch (e) {
      notify.error(t('identity.list.table.action.reset.unknownError'))
    }
  }

  const handleConfirmDeleteUser = (id: string) => {
    showConfirmation({
      title: t('identity.list.table.action.delete.title'),
      subtitle: t('identity.list.table.action.delete.subtitle'),
      confirmText: t('identity.list.table.action.delete.button'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        handleDeleteUSer(id)
      },
    })
  }

  const handleDeleteUSer = async (id: string) => {
    try {
      await deleteUser(id)
      fetchList()
      notify.success(t('identity.list.table.action.delete.successFeedback'))
    } catch (e) {}
  }

  const actions = (row: IdentityUser) => {
    return [
      {
        onClick: () => handleConfirmBlockUser(row.id),
        label: t('identity.list.table.action.block'),
        icon: 'lock_outline',
        isHidden: row.status === IdentityUserStatus.BLOCKED,
      },
      {
        onClick: () => handleUnblockUser(row.id),
        label: t('identity.list.table.action.unblock'),
        icon: 'lock_outline',
        isHidden: row.status !== IdentityUserStatus.BLOCKED,
      },
      {
        onClick: () => handleSendValidation(row.id),
        label: t('identity.list.table.action.email'),
        icon: 'email',
        isHidden:
          row.status !== IdentityUserStatus.NOT_VERIFIED ||
          row.provider !== 'IDS',
      },
      {
        onClick: () => handleSendPassword(row.id),
        label: t('identity.list.table.action.reset'),
        icon: 'visibility',
        isHidden: row.provider !== 'IDS',
      },
      {
        onClick: () => handleConfirmDeleteUser(row.id),
        label: t('identity.list.table.action.delete'),
        icon: 'delete',
        isHidden: !checkPermissions([Permission.TenantAdmin_IdsDelete]),
      },
    ]
  }

  const columns: ColumnDef<IdentityUser>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: t('identity.list.table.id'),
      },
      {
        accessorKey: 'email',
        header: t('identity.list.table.email'),
      },
      {
        accessorKey: 'provider',
        header: t('identity.list.table.provider'),
      },
      {
        accessorKey: 'status',
        header: t('identity.list.table.status'),
        cell: ({ row }) => (
          <Tag
            type={getTagType(row.original.status)}
            icon={
              row.original.status === 'Verified'
                ? 'check_circle_outline'
                : undefined
            }
          >
            {t(`identity.status.${row.original.status}`)}
          </Tag>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    fetchList({ page: 1 })
    return () => {
      clearList()
    }
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="Identity">
      <div className="d-flex justify-content-between">
        <div>
          <h1 id="user-list-title" className="h3">
            {t('identity.title')}
          </h1>
          <p>{t('identity.list.hint')}</p>
        </div>
        <button
          className="Identity__refresh btn btn-primary h-100"
          onClick={() => fetchList({ page })}
        >
          {t('identity.button')}
        </button>
      </div>
      <section className="mt-3">
        <div className="Identity__list">
          <ContentTable
            columns={columns}
            data={list}
            actions={actions}
            title={t('indetity.list.title')}
            emptyText={t('identity.emptyList')}
            onSearch={(s: string) => {
              fetchList({ page: 1, search: s })
            }}
            searchPlaceholder={t('identity.list.search')}
            isLoaded={isLoaded}
          />
          <Pagination
            currentPage={(page || 1) - 1}
            totalPages={availablePages}
            totalItems={availableItems}
            pageSize={pageSize}
            singlePageText={(count) => t('common.pager.singlePage', { count })}
            multiplePageText={(start, end, total) =>
              t('common.pager.multiplePage', { start, end, total })
            }
            onChange={(pageIndex) => fetchList({ page: pageIndex + 1 })}
          />
        </div>
      </section>
    </Main>
  )
}

export default IdentityScreen
