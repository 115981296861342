import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseCommunityResult,
  CommunityRequestParams,
  CommunityListResponse,
  CreateCommunityUserType,
} from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useCommunity = (): UseCommunityResult => {
  const dispatch = useAppDispatch()
  const {
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
  } = useAppSelector((state) => state.community)
  const { get, post, del, patch } = useFetch()
  const { notifyApiError } = useError()

  const endpoint = 'community-admin-users'
  const errorModel = 'admin'

  const parseParams = (requestParams?: CommunityRequestParams) => {
    const params: CommunityRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
    }
    return params
  }

  const getList = async (params?: CommunityRequestParams) => {
    const response: CommunityListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const fetchList = async (params?: CommunityRequestParams) => {
    dispatch(showLoader())
    const reqParams = parseParams(params)
    const response: CommunityListResponse | undefined = await getList(reqParams)
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const createUser = async (user: CreateCommunityUserType) => {
    try {
      await post({ endpoint, body: user })
    } catch (e) {
      notifyApiError(e, errorModel, 'create')
    }
  }

  const updateUser = async (id: string, profiles: string[]) => {
    try {
      await patch({ endpoint, id, body: { profiles } })
    } catch (e) {
      notifyApiError(e, errorModel, 'edit')
    }
  }

  const deleteUser = async (id: string) => {
    try {
      await del({ endpoint, id })
    } catch (e) {
      notifyApiError(e, errorModel, 'delete')
    }
  }

  return {
    list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    fetchList,
    clearList: () => dispatch(clearList()),
    createUser,
    updateUser,
    deleteUser,
  }
}

export default useCommunity
