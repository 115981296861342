import { FC } from 'react'

import { SocialIcon } from '../../../../../components/SocialIcon'

import './index.scss'
import { useTranslation } from 'react-i18next'
import CustomIcon from './CustomIcon'
import { CustomIconName } from './CustomIcon/types'

const ThemeFooter: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="theme-footer section">
      <div className="theme-footer__links row">
        <div className="theme-footer__links__about col-4">
          <div className="theme-footer__links__about__title">
            {t('tenantDesign.theme.footer.about')}
          </div>
          <div className="theme-footer__links__about__links-group">
            <div className="theme-footer__links__about__links-group__item">
              {t('tenantDesign.theme.footer.exp')}
            </div>
            <div className="theme-footer__links__about__links-group__item">
              {t('tenantDesign.theme.footer.news')}
            </div>
            <div className="theme-footer__links__about__links-group__item">
              {t('tenantDesign.theme.footer.calendar')}
            </div>
            <div className="theme-footer__links__about__links-group__item">
              {t('tenantDesign.theme.footer.weare')}
            </div>
            <div className="theme-footer__links__about__links-group__item">
              {t('tenantDesign.theme.footer.fairs')}
            </div>
            <div className="theme-footer__links__about__links-group__item">
              {t('tenantDesign.theme.footer.tickets')}
            </div>
            <div className="theme-footer__links__about__links-group__item">
              {t('tenantDesign.theme.footer.org')}
            </div>
          </div>
        </div>
        <div className="theme-footer__links__contact col-4">
          <div className="theme-footer__links__contact__title">
            {' '}
            {t('tenantDesign.theme.footer.contacts')}
          </div>
          <div className="theme-footer__links__contact__links-group">
            <div className="theme-footer__links__contact__links-group__item">
              {t('tenantDesign.theme.footer.tel1')}
            </div>
            <div className="theme-footer__links__contact__links-group__item">
              {t('tenantDesign.theme.footer.tel2')}
            </div>
            <div className="theme-footer__links__contact__links-group__item">
              {t('tenantDesign.theme.footer.address')}
            </div>
            <div className="theme-footer__links__contact__links-group__item">
              {t('tenantDesign.theme.footer.contactus')}
            </div>
          </div>
        </div>
        <div className="theme-footer__links__social col-4">
          <div className="theme-footer__links__social__title">
            {t('tenantDesign.theme.footer.follow')}
          </div>
          <div className="theme-footer__links__social__links-group d-flex align-items-center">
            <FooterSocialMediaIcons/>
          </div>
        </div>
      </div>
      <div className="theme-footer__legal d-flex align-items-center">
        <div className="theme-footer__legal__icon"></div>
        <div className="theme-footer__legal__links-group d-flex align-items-center">
          <div className="theme-footer__legal__links-group__item">
            {t('tenantDesign.theme.footer.legal')}
          </div>
          <div className="theme-footer__legal__links-group__item">
            {t('tenantDesign.theme.footer.policies')}
          </div>
          <div className="theme-footer__legal__links-group__item">
            {t('tenantDesign.theme.footer.conditions')}
          </div>
          <div className="theme-footer__legal__links-group__item">
            {t('tenantDesign.theme.footer.cookies')}
          </div>
        </div>
      </div>
    </div>
  )
}

const FooterSocialMediaIcons: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const icons: Record<string, CustomIconName> = {
    linkedinUrl: 'linkedin',
    twitterUrl: 'twitter',
    youtubeUrl: 'youtube',
    facebookUrl: 'facebook',
    instagramUrl: 'instagram',
  }
  return (
    <>
      {Object.entries(icons).map(
        ([key, value]) =>
          value && (
            <div key={key} className="theme-footer__links__social__links-group__item">
              <a rel="noreferrer">
                <CustomIcon name={icons[key]} />
              </a>
            </div>
          )
      )}
    </>
  )
}

export default ThemeFooter
