import { TableComponent } from '@liveconnect/communities-ui'
import { ColumnDef, Row } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ContentTable from '../../../../components/ContentTable'
import {
  LiveconnectUserDetail,
  LiveconnectUserDetailApp,
} from '../../../../core/liveconnect/types'
import useLiveconnect from '../../../../core/liveconnect/useLiveconnect'

const LCApps = () => {
  const { t } = useTranslation()
  const { detail } = useLiveconnect()

  const { appInstances } = detail as LiveconnectUserDetail

  const columns: ColumnDef<LiveconnectUserDetailApp>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('liveconnect.detail.apps.header.name'),
      },
      {
        accessorKey: 'appsInstances',
        header: t('liveconnect.detail.apps.header.profiles'),
        cell: ({ row }: { row: Row<LiveconnectUserDetailApp> }) =>
          row.original.profiles.join(', '),
      },
    ],
    []
  )

  return (
    <div className="LCApps">
      <ContentTable
        title={t('liveconnect.detail.apps.title')}
        columns={columns}
        data={appInstances}
      />
    </div>
  )
}

export default LCApps
