import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  LiveconnectListPayload,
  LiveconnectState,
  LiveconnectUserDetail,
} from './types'

const initialState: LiveconnectState = {
  detail: null,
  list: [],
  page: 1,
  pageSize: 20,
  availablePages: 1,
  availableItems: 0,
  search: '',
  isLoaded: false,
}

export const slice = createSlice({
  name: 'liveconnect',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearList: () => initialState,
    setList: (state, action: PayloadAction<LiveconnectListPayload>) => {
      state.list = action.payload.list
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.availablePages = action.payload.availablePages
      state.availableItems = action.payload.availableItems
      state.search = action.payload.search
    },
    setDetail: (state, action: PayloadAction<LiveconnectUserDetail>) => {
      state.detail = action.payload
    },
  },
})

export const { showLoader, hideLoader, clearList, setList, setDetail } =
  slice.actions

export default slice.reducer
