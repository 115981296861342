import { useRef } from 'react'

import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  MessageStatus,
  ReceivedMessages,
  ReceivedMessagesDetail,
  ReceivedMessagesRequestParams,
  ReceivedStatistics,
  ReceivedStatisticsRequestParams,
  UseReceivedMessages,
  PublishedMessageFilters,
} from './types'
import {
  setList,
  clearStore,
  setDetail,
  showLoader,
  hideLoader,
  showDetailLoader,
  hideDetailLoader,
  setFilters,
  setServiceList,
  setMessageStatusList,
  setReceivedStatistics,
  clearMessageListStore,
} from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useReceivedMessages = (): UseReceivedMessages => {
  const dispatch = useAppDispatch()
  const {
    detail,
    list,
    isLoaded,
    isDetailLoaded,
    serviceList,
    receivedStatistics,
    messageStatusList,
    serviceSelected,
    statusSelected,
  } = useAppSelector((state) => state.receivedMessages)
  const { get, post } = useFetch()
  const { notifyApiError } = useError()
  const listController = useRef<AbortController>()

  const endpoint = 'dashboard'
  const errorModel = 'message'

  const getReceivedMessages = async (
    params?: ReceivedMessagesRequestParams,
    signal?: AbortSignal
  ) => {
    const response: ReceivedMessages[] | undefined = await get({
      endpoint: `${endpoint}/suscribed-messages`,
      params,
      signal,
    })
    return response
  }

  const fetchList = async (params?: ReceivedMessagesRequestParams) => {
    try {
      listController.current?.abort()
      listController.current = new AbortController()
      dispatch(showLoader())
      const response = await getReceivedMessages(
        params,
        listController.current.signal
      )
      response && dispatch(setList(response))
      dispatch(hideLoader())
    } catch (e) {}
  }

  const fetchDetail = async (key: string) => {
    dispatch(showDetailLoader())
    const response: ReceivedMessagesDetail | undefined = await get({
      endpoint: `${endpoint}/${sanitizeRouterParameter(key)}`,
    })
    response && dispatch(setDetail(response))
    dispatch(hideDetailLoader())
  }

  const fetchServiceList = async () => {
    const response: string[] | undefined = await get({
      endpoint: `${endpoint}/subscribers`,
    })
    response && dispatch(setServiceList(response))
  }

  const fetchMessageStatusList = async () => {
    const response: MessageStatus[] | undefined = await get({
      endpoint: `${endpoint}/messages-status-types?boxtype=inbox`,
    })
    response && dispatch(setMessageStatusList(response.map((x) => x.status)))
  }

  const getStatistics = async (params?: ReceivedStatisticsRequestParams) => {
    const response: ReceivedStatistics | undefined = await get({
      endpoint: `${endpoint}/suscribed-messages-statistics`,
      params,
    })
    return response
  }

  const fetchReceivedStatistics = async (
    params?: ReceivedStatisticsRequestParams
  ) => {
    dispatch(showLoader())
    const response = await getStatistics(params)
    response && dispatch(setReceivedStatistics(response))
    dispatch(hideLoader())
  }

  const reprocessMessages = async (keys: string[]) => {
    try {
      await post({ endpoint: `${endpoint}/reprocess`, body: { keys: keys } })
    } catch (e) {
      notifyApiError(e, errorModel, 'reprocess')
    }
  }

  const sanitizeRouterParameter = (parameter: string) => {
    return parameter.replace(/\//g, '%2F')
  }

  return {
    detail,
    list,
    isLoaded,
    isDetailLoaded,
    serviceList,
    serviceSelected,
    statusSelected,
    messageStatusList,
    receivedStatistics,
    fetchList,
    clearStore: () => dispatch(clearStore()),
    clearMessageListStore: () => dispatch(clearMessageListStore()),
    setFilters: (filters: PublishedMessageFilters) =>
      dispatch(setFilters(filters)),
    fetchDetail,
    fetchServiceList,
    fetchMessageStatusList,
    fetchReceivedStatistics,
    reprocessMessages,
  }
}

export default useReceivedMessages
