// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages-doughnut-graph{margin-top:26px;gap:81px}.IntegrationDashboard__box{border:1px solid #f2f2f5;width:100%;border-radius:4px}.IntegrationDashboard__box-50{width:50%}.IntegrationDashboard__row{display:flex;flex-direction:row}`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/Integration/styles.scss"],"names":[],"mappings":"AAQA,yBACE,eAAA,CACA,QAAA,CAGF,2BACE,wBAAA,CACA,UAAA,CACA,iBAAA,CAEA,8BACE,SAAA,CAGJ,2BACE,YAAA,CACA,kBAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.messages-doughnut-graph {\n  margin-top: 26px;\n  gap: 81px;\n}\n\n.IntegrationDashboard__box {\n  border: 1px solid $neutral-60;\n  width: 100%;\n  border-radius: 4px;\n\n  &-50 {\n    width: 50%;\n  }\n}\n.IntegrationDashboard__row {\n  display: flex;\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messages-doughnut-graph": `messages-doughnut-graph`,
	"IntegrationDashboard__box": `IntegrationDashboard__box`,
	"IntegrationDashboard__box-50": `IntegrationDashboard__box-50`,
	"IntegrationDashboard__row": `IntegrationDashboard__row`
};
export default ___CSS_LOADER_EXPORT___;
