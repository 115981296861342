import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { UseDomainsResult, CreateDomainType, Domain } from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useDomains = (): UseDomainsResult => {
  const dispatch = useAppDispatch()
  const { list, isLoaded } = useAppSelector((state) => state.domains)
  const { get, post, del, patch } = useFetch()
  const { notifyApiError } = useError()

  const endpoint = 'identity-domains-blacklist'
  const errorModel = 'domain'

  const fetchList = async () => {
    dispatch(showLoader())
    const response: Domain[] | undefined = await get({ endpoint })
    response && dispatch(setList(response))
    dispatch(hideLoader())
  }

  const createDomain = async (domain: CreateDomainType) => {
    try {
      await post({ endpoint, body: domain })
    } catch (e) {
      notifyApiError(e, errorModel, 'create')
    }
  }

  const deleteDomain = async (id: string) => {
    try {
      await del({ endpoint, id })
    } catch (e) {
      notifyApiError(e, errorModel, 'delete')
    }
  }

  return {
    list,
    isLoaded,
    fetchList,
    clearList: () => dispatch(clearList()),
    createDomain,
    deleteDomain,
  }
}

export default useDomains
