import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { HistoryGraph, HistoryGraphState } from './types'

const initialState: HistoryGraphState = {
  list: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'historyGraph',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<HistoryGraph[]>) => {
      state.list = action.payload
    },
    clearList: (state) => {
      state.list = []
    },
  },
})

export const { showLoader, hideLoader, setList, clearList } = slice.actions

export default slice.reducer
