import { CheckboxControl } from '@liveconnect/components'
import { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import useTermsCons from '../../../../core/termsCons/useTermsCons'
import useCulture from '../../../../utils/culture'

import './styles.scss'

interface HistoryModalProps {
  onClose: () => void
}

const HistoryModal: FC<HistoryModalProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { fetchTermsCons, list } = useTermsCons()
  const { formatDate } = useCulture()

  const [selectedVersions, setSelectedVersions] = useState<string[]>([])

  const handleCheck = (id: string) => {
    const index = selectedVersions.indexOf(id)
    if (index !== -1) {
      selectedVersions.splice(index, 1)
    } else {
      selectedVersions.push(id)
    }
    setSelectedVersions([...selectedVersions])
  }

  const handleSubmit = () => {
    navigate(`${selectedVersions[0]}/compare/${selectedVersions[1]}`)
  }

  useEffect(() => {
    fetchTermsCons()
  }, [])

  return (
    <Modal show={true} onHide={onClose} className="TCHistoryModal" size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>{t('termsCons.form.historyModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row TCHistoryModal__header">
          <div className="col-1 TCHistoryModal__header__col">{''}</div>
          <div className="col-4 TCHistoryModal__header__col">
            {t('termsCons.form.historyModal.version')}
          </div>
          <div className="col-4 TCHistoryModal__header__col">
            {t('termsCons.author')}
          </div>
          <div className="col-3 TCHistoryModal__header__col">
            {t('termsCons.date')}
          </div>
        </div>
        {list.map((item) => (
          <div className="row TCHistoryModal__row" key={item.code}>
            <div className="col-1 TCHistoryModal__col">
              <CheckboxControl
                value={selectedVersions.includes(item.id)}
                name={item.id}
                onChange={() => handleCheck(item.id)}
                label=""
                disabled={
                  selectedVersions.length === 2 &&
                  !selectedVersions.includes(item.id)
                }
              />
            </div>
            <div className="col-4 TCHistoryModal__col">{item.code}</div>
            <div className="col-4 TCHistoryModal__col">{item.author}</div>
            <div className="col-3 TCHistoryModal__col">
              {formatDate(
                new Date(item.creationDate) as Date,
                'LONG_DATE_TIME'
              )}
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={onClose}>
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={selectedVersions.length < 2}
        >
          {t('termsCons.reviewChanges')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default HistoryModal
