import useFetch from '../../utils/fetch/useFetch'

import { TemplateDetail, UseTenantEmailsTemplatesResult } from './types'
import { EmailsTemplate } from '../../pages/TenantEmailsTemplates/types'

export const useTenantEmailsTemplates = (): UseTenantEmailsTemplatesResult => {
  const { get, post, del } = useFetch()

  const endpoint = 'emails-templates'

  const getTemplates = async (): Promise<EmailsTemplate[] | undefined> =>
    await get({
      endpoint,
    })

  const getTemplateDetails = async (
    templateId: string
  ): Promise<TemplateDetail | undefined> =>
    await get({
      endpoint: `${endpoint}/${templateId}`,
    })

  const addDraft = async (
    emailTemplateFromId: string,
    mjml: string,
    subject: string
  ): Promise<unknown> =>
    await post({
      endpoint: `${endpoint}/create-draft`,
      body: {
        emailTemplateFromId,
        mjml,
        subject,
      },
    })

  const editTemplate = async (
    id: string,
    mjml: string,
    subject: string
  ): Promise<unknown> =>
    await post({
      endpoint: `${endpoint}/${id}/update`,
      body: {
        mjml,
        subject,
      },
    })

  const removeTemplate = async (id: string): Promise<unknown> =>
    await del({
      endpoint,
      id,
    })

  const setDraftAsTemplate = async (id: string): Promise<unknown> =>
    await post({
      endpoint: `${endpoint}/${id}/set-active`,
      body: null,
    })

  return {
    getTemplates,
    getTemplateDetails,
    addDraft,
    editTemplate,
    removeTemplate,
    setDraftAsTemplate,
  }
}
