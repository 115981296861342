import { FC } from 'react'
import classnames from 'classnames'

import './styles.scss'

interface EmptyStateProps {
  className?: string
  text?: string
  image?: {
    src: string
    description: string
  }
}

const EmptyState: FC<EmptyStateProps> = ({ className, text, image }) => {
  return (
    <div className={classnames('EmptyState', className)}>
      {image && (
        <img
          src={image.src}
          alt={image.description}
          title={image.description}
        />
      )}
      {text && <span className="EmptyState__text">{text}</span>}
    </div>
  )
}

export default EmptyState
