import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    email: yup
      .string()
      .label(t('tenantUser.create.modal.email'))
      .email(t('validations.email'))
      .required(({ label }) => t('validations.required', { label })),
    name: yup
      .string()
      .label(t('tenantUser.create.modal.name'))
      .required(({ label }) => t('validations.required', { label })),
    profiles: yup
      .array()
      .label(t('tenantUser.create.modal.profiles'))
      .min(1, ({ label }) => t('validations.required', { label }))
      .required(({ label }) => t('validations.required', { label })),
  })
}
