import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EmailsFilters } from '../types'

const initialState: EmailsFilters = {
  items: [],
}

export const emailsTypesSlice = createSlice({
  name: 'emailsTypes',
  initialState,
  reducers: {
    setEmailsTypes: (state, action: PayloadAction<EmailsFilters>) => {
      state.items = action.payload.items
    },
  },
})

export const { setEmailsTypes } = emailsTypesSlice.actions

export default emailsTypesSlice.reducer
