import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import EmptyState from '../../components/EmptyState'
import { Main } from '../../components/Main'
import { Event } from '../../core/events/types'
import useEvents from '../../core/events/useEvents'
import EventRow from './EventRow'
import CreateEvent from './CreateEvent'

import './styles.scss'

const EventsScreen: FC = () => {
  const { t } = useTranslation()
  const { list, fetchList, clearList } = useEvents()
  const [showCreatemodal, setShowCreatemodal] = useState(false)

  useEffect(() => {
    fetchList()
    return () => {
      clearList()
    }
  }, [])

  return (
    <>
      <Main ariaLabelledby="user-list-title" className="Events">
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="user-list-title" className="h3">
              {t('events.title')}
            </h1>
            <p>{t('events.list.hint')}</p>
          </div>
          <button
            className="btn btn-primary h-100"
            onClick={() => setShowCreatemodal(true)}
          >
            {t('events.list.add')}
          </button>
        </div>
        <section className="mt-3">
          {list.length === 0 ? (
            <EmptyState text={t('events.emptyList')} />
          ) : (
            <div className="Events__list">
              {list.map((event: Event) => (
                <EventRow event={event} key={event.id} />
              ))}
            </div>
          )}
        </section>
      </Main>
      {showCreatemodal && (
        <CreateEvent onClose={() => setShowCreatemodal(false)} />
      )}
    </>
  )
}

export default EventsScreen
