import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { AppsInstances } from '../../../core/liveconnect/types'

import './styles.scss'

interface AppInstancesModalProps {
  appInstances: AppsInstances[]
  onClose: () => void
}

const AppInstancesModal: FC<AppInstancesModalProps> = ({
  appInstances,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <Modal show={true} onHide={onClose} className="AppInstancesModal">
      <Modal.Header closeButton>
        <Modal.Title>{t('AppInstancesModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {appInstances.map((item) => (
          <div className="AppInstancesModal__item" key={item.name}>
            {item.name}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button className="btn btn-primary" onClick={onClose}>
            {t('common.accept')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AppInstancesModal
