import { Icon } from '@liveconnect/icons'
import { FC } from 'react'

import './styles.scss'

type ForwardButtonProps = {
  onClick: () => void
  label: string
}

const ForwardButton: FC<ForwardButtonProps> = ({ onClick, label }) => {
  return (
    <div className="ForwardButton" aria-hidden="true" onClick={onClick}>
      <p>{label}</p>
      <Icon name="chevron_right" />
    </div>
  )
}

export default ForwardButton
