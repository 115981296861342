import React, { FC } from 'react'
import DOMPurify from 'dompurify'

const defaultOptions = {
  ALLOWED_TAGS: [
    'a',
    'abbr',
    'acronym',
    'address',
    'area',
    'article',
    'aside',
    'b',
    'blockquote',
    'br',
    'button',
    'canvas',
    'caption',
    'center',
    'cite',
    'code',
    'col',
    'colgroup',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'dir',
    'div',
    'dl',
    'dt',
    'em',
    'fieldset',
    'figcaption',
    'figure',
    'font',
    'footer',
    'form',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'hr',
    'i',
    'img',
    'ins',
    'kbd',
    'label',
    'legend',
    'li',
    'main',
    'map',
    'mark',
    'menu',
    'menuitem',
    'meter',
    'nav',
    'noscript',
    'ol',
    'optgroup',
    'option',
    'p',
    'pre',
    'progress',
    'q',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'section',
    'select',
    'small',
    'span',
    'strike',
    'strong',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'tr',
    'u',
    'ul',
    'var',
    'wbr',
  ],
  ALLOWED_ATTR: [
    'href',
    'style',
    'src',
    'align',
    'border',
    'width',
    'height',
    'cellpadding',
    'cellspacing',
    'role',
    'valign',
    'bgcolor',
  ],
}

const sanitize = (dirty: string, options: Record<string, unknown> = {}) => ({
  __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
})

interface HtmlSanitizerProps extends React.HTMLAttributes<HTMLDivElement> {
  html: string
  options?: Record<string, unknown>
}

export const HtmlSanitizer: FC<HtmlSanitizerProps> = ({
  html,
  options,
  ...props
}) => <div {...props} dangerouslySetInnerHTML={sanitize(html, options)} />
