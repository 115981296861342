import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IdentityListPayload, IdentityState, IdentityUser } from './types'

const initialState: IdentityState = {
  list: [],
  page: 1,
  pageSize: 20,
  availablePages: 1,
  availableItems: 0,
  search: '',
  isLoaded: false,
}

export const slice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearList: () => initialState,
    setList: (state, action: PayloadAction<IdentityListPayload>) => {
      state.list = action.payload.list
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.availablePages = action.payload.availablePages
      state.availableItems = action.payload.availableItems
      state.search = action.payload.search
    },
    updateUserInList: (state, action: PayloadAction<IdentityUser>) => {
      const userIndex = state.list.findIndex(
        (user) => user.id === action.payload.id
      )
      if (userIndex !== -1) {
        state.list[userIndex] = action.payload
      }
    },
  },
})

export const { showLoader, hideLoader, clearList, setList, updateUserInList } =
  slice.actions

export default slice.reducer
