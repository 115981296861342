import classNames from 'classnames'
import { FC, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import Backdrop from '../Backdrop'

import './styles.scss'
import { createBrowserHistory } from 'history'

type SlidePanelProps = {
  alignment?: 'left' | 'right'
  opened: boolean
  openLeft?: boolean
  close: () => void
}

const delay = 300

const SlidePanel: FC<SlidePanelProps> = ({
  children,
  opened,
  close,
  alignment,
  openLeft,
}) => {
  const history = createBrowserHistory()
  const nodeRef = useRef<HTMLElement>(null)
  const unlistenRef = useRef<() => void>()

  useEffect(() => {
    if (opened) {
      unlistenRef.current = history.listen(() => close())
    } else {
      unlistenRef.current?.()
    }
  }, [history, opened, close])

  useEffect(() => {
    return () => unlistenRef.current?.()
  }, [])

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={opened}
      unmountOnExit
      timeout={{ exit: delay }}
      classNames={{ enterDone: 'active' }}
    >
      <div className="SlidePanel">
        <Backdrop close={close} />
        <div className="SlidePanel__container container">
          <article
            className={classNames('SlidePanel__content', {
              'SlidePanel__content--openLeft': openLeft,
              'SlidePanel__content--left': alignment === 'left',
            })}
            ref={nodeRef}
            style={{ transition: `all ${delay}ms` }}
          >
            {children}
          </article>
        </div>
      </div>
    </CSSTransition>
  )
}

export default SlidePanel
