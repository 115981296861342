export enum IdentityUserStatus {
  NOT_VERIFIED = 'NotVerified',
  VERIFIED = 'Verified',
  BLOCKED = 'Blocked',
}

export interface IdentityUser {
  id: string
  email: string
  provider: string
  status: IdentityUserStatus
}

export interface IdentityListPayload {
  list: Array<IdentityUser>
  page: number
  pageSize: number
  availablePages: number
  availableItems: number
  search: string
}

export interface IdentityRequestParams {
  page: number
  page_size?: number
  search?: string
}

export interface IdentityListResponse {
  items: Array<IdentityUser>
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
}

export interface IdentityState {
  list: Array<IdentityUser>
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
  search: string
  isLoaded: boolean
}

export interface UseIdentityResult {
  list: Array<IdentityUser>
  page: number
  availablePages: number
  availableItems: number
  pageSize: number
  search: string
  isLoaded: boolean
  fetchList: (params?: IdentityRequestParams) => void
  clearList: () => void
  blockUser: (id: string) => void
  unblockUser: (id: string) => void
  sendRestartPasswordEmail: (id: string) => void
  sendValidationdEmail: (id: string) => void
  deleteUser: (id: string) => void
}
