import { FC, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import JSONPretty from 'react-json-pretty'
import { Loader } from '@liveconnect/communities-ui'

import { ReceivedMessages } from '../../../../../core/receivedMessages/types'
import useReceivedMessages from '../../../../../core/receivedMessages/useReceivedMessages'
import useCulture from '../../../../../utils/culture'

import './styles.scss'

interface ReceivedMessageDeatilModalProps {
  receivedMessage: ReceivedMessages
  onClose: () => void
}

const ReceivedMessageDeatilModal: FC<ReceivedMessageDeatilModalProps> = ({
  receivedMessage,
  onClose,
}) => {
  const { t } = useTranslation()
  const { formatDate } = useCulture()
  const { detail, isDetailLoaded, fetchDetail } = useReceivedMessages()

  const getKeyValue = (key: string, value: string) => {
    return (
      <div className="KeyValue__field">
        <div className="KeyValue__field__key form-label">{key}</div>
        <div className="KeyValue__field__value">{value}</div>
      </div>
    )
  }

  useEffect(() => {
    fetchDetail(receivedMessage.key)
  }, [])

  return (
    <Modal
      show={true}
      onHide={onClose}
      className="ReceivedMessagesDetailModal"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('dashboard.receivedMessages.detail.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="DetailModal__data">
          <div className="DetailModal__row">
            {getKeyValue(
              t('dashboard.publishedMessages.list.table.id'),
              receivedMessage?.id
            )}
            {getKeyValue(
              t('dashboard.publishedMessages.list.table.key'),
              receivedMessage?.key
            )}
            {getKeyValue(
              t('dashboard.publishedMessages.list.table.eventType'),
              receivedMessage?.eventType
            )}
            {getKeyValue(
              t('dashboard.publishedMessages.list.table.retries'),
              receivedMessage?.retries.toString()
            )}{' '}
            {getKeyValue(
              t('dashboard.publishedMessages.list.table.createdAt'),
              formatDate(
                new Date(receivedMessage?.createdAt as string) as Date,
                'LONG_DATE_TIME'
              )
            )}{' '}
            {getKeyValue(
              t('dashboard.publishedMessages.list.table.expires'),
              formatDate(
                new Date(receivedMessage?.expires as string) as Date,
                'LONG_DATE_TIME'
              )
            )}{' '}
          </div>
        </div>
        {!isDetailLoaded ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {!!detail?.error &&
              getKeyValue(
                t('dashboard.publishedMessages.list.table.error'),
                detail?.error.toString()
              )}
            <div className="KeyValue__field">
              <div className="KeyValue__field__key form-label">
                {t('dashboard.publishedMessages.detail.data')}
              </div>
              <div className="KeyValue__field__json__value">
                {detail?.data && (
                  <JSONPretty id="json-pretty" data={detail.data}></JSONPretty>
                )}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ReceivedMessageDeatilModal
