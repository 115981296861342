import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Tenant, TenantState } from './types'

const initialState: TenantState = {
  detail: null,
}

export const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setDetail: (state, action: PayloadAction<Tenant>) => {
      state.detail = action.payload
    },
  },
})

export const { setDetail } = slice.actions

export default slice.reducer
