import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    name: yup
      .string()
      .label(t('tenant.form.name'))
      .required(({ label }) => t('validations.required', { label }))
      .max(50, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 50 })
      ),
    imageUrl: yup
      .string()
      .label(t('tenant.form.image'))
      .required(({ label }) => t('validations.required', { label })),
    googleAdManagerAccount: yup
      .string()
      .label(t('tenant.form.adManager'))
      .max(50, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 50 })
      ),
    googleTagManagerAccount: yup.array().of(
      yup.object({
        value: yup
          .string()
          .label(t('tenant.form.tagManager'))
          .max(50, ({ label }) =>
            t('validations.maxLength', { label, maxLength: 50 })
          ),
      })
    ),
  })
}
