import { FC, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useTermsConsVersions from '../../../../core/termsConsVersions/useTermsConsVersions'
import useCulture from '../../../../utils/culture'

import './styles.scss'

interface HistoryModalProps {
  onClose: () => void
}

const HistoryModal: FC<HistoryModalProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const { fetchTermsCons, list } = useTermsConsVersions()
  const { formatDate } = useCulture()

  useEffect(() => {
    fetchTermsCons()
  }, [])

  return (
    <Modal show={true} onHide={onClose} className="TCHistoryModal" size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>{t('termsCons.versions.historyModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row TCHistoryModal__header">
          <div className="col-3 TCHistoryModal__header__col">
            {t('termsCons.version')}
          </div>
          <div className="col-6 TCHistoryModal__header__col">
            {t('termsCons.author')}
          </div>
          <div className="col-3 TCHistoryModal__header__col">
            {t('termsCons.date')}
          </div>
        </div>
        {list.map((item) => (
          <div className="row TCHistoryModal__row" key={item.code}>
            <div className="col-3 TCHistoryModal__col">{item.code}</div>
            <div className="col-6 TCHistoryModal__col">{item.author}</div>
            <div className="col-3 TCHistoryModal__col">
              {formatDate(
                new Date(item.creationDate) as Date,
                'LONG_DATE_TIME'
              )}
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={onClose}>
          {t('common.return')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default HistoryModal
