import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoaded: false,
}

export const emailsLoaderSlice = createSlice({
  name: 'emailsLoader',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
  },
})

export const { showLoader, hideLoader } = emailsLoaderSlice.actions

export default emailsLoaderSlice.reducer
