import { FC, useCallback, useMemo, useState, useEffect } from 'react'
import {
  BlockManager,
  BasicType,
  JsonToMjml,
} from '@liveconnect/easy-email-core'
import {
  EmailEditor,
  EmailEditorProvider,
  IEmailTemplate,
  EmailEditorProviderProps,
} from '@liveconnect/easy-email-editor'
import { StandardLayout, MjmlToJson } from '@liveconnect/easy-email-extensions'
import { useWindowSize } from 'react-use'
import { Liquid } from 'liquidjs'
import { useTranslation } from 'react-i18next'

import { useTenantEmailsTemplates } from '../../../../core/tenantEmailsTemplates/useTenantEmailsTemplates'
import useNotifications from '../../../../utils/notifications/useNotifications'

import { categories } from './constants'

import '@liveconnect/easy-email-editor/lib/style.css'
import '@liveconnect/easy-email-extensions/lib/style.css'
import '@arco-themes/react-easy-email-theme/css/arco.css'
import './styles.scss'

interface TemplateEditorProps {
  id: string
  subject: string
  mjml: string
  properties: Record<string, Record<string, string>>
  templateType: 'addDraft' | 'editDraft' | 'editTemplate'
  onSubmit: (callback: () => void) => void
}

const TemplateEditor: FC<TemplateEditorProps> = ({
  id,
  subject,
  mjml,
  properties,
  templateType,
  onSubmit,
}) => {
  const { width } = useWindowSize()
  const smallScene = width < 1400
  const { addDraft, editTemplate } = useTenantEmailsTemplates()
  const notify = useNotifications()
  const { t } = useTranslation()

  const [template, setTemplate] = useState<any>(
    BlockManager.getBlockByType(BasicType.PAGE)!.create({})
  )
  const [mergeTagsData, setMergeTagsData] = useState<any>()

  const initialValues: IEmailTemplate | null = useMemo(() => {
    return {
      subject: subject,
      subTitle: '',
      content: template,
    }
  }, [template])

  const onBeforePreview: EmailEditorProviderProps['onBeforePreview'] =
    useCallback((html: string, mergeTags) => {
      const engine = new Liquid()
      const tpl = engine.parse(html)
      return engine.renderSync(tpl, mergeTags)
    }, [])

  const loadTemplate = async () => {
    setTemplate(MjmlToJson(mjml))
    setMergeTagsData(properties)
  }

  const handleSubmit = useCallback(
    async (values: any) => {
      const mjmlContent = JsonToMjml({
        data: values.content,
        mode: 'production',
        context: values.content,
        dataSource: mergeTagsData,
      })
      if (templateType === 'editDraft' || templateType === 'editTemplate') {
        try {
          await editTemplate(id, mjmlContent, values.subject)
          notify.success(
            templateType === 'editTemplate'
              ? t('tenantEmailsTemplate.notification.edit.template.success')
              : t('tenantEmailsTemplate.notification.edit.draft.success')
          )
        } catch {
          notify.error(
            templateType === 'editTemplate'
              ? t('tenantEmailsTemplate.notification.edit.template.error')
              : t('tenantEmailsTemplate.notification.edit.draft.error')
          )
        }
      }
      if (templateType === 'addDraft') {
        try {
          await addDraft(id, mjmlContent, values.subject)
          notify.success(t('tenantEmailsTemplate.notification.add.success'))
        } catch {
          notify.error(t('tenantEmailsTemplate.notification.add.error'))
        }
      }
    },
    [JsonToMjml, editTemplate]
  )

  useEffect(() => {
    loadTemplate()
  }, [])

  return (
    <div className="TemplateEditor">
      <EmailEditorProvider
        data={initialValues}
        height="auto"
        autoComplete
        enabledLogic
        dashed={false}
        mergeTags={mergeTagsData}
        onBeforePreview={onBeforePreview}
        mergeTagGenerate={(tag) => `{{${tag}}}`}
      >
        {({ values }) => {
          onSubmit(() => {
            handleSubmit(values)
          })
          return (
            <StandardLayout categories={categories} compact={!smallScene}>
              <EmailEditor />
            </StandardLayout>
          )
        }}
      </EmailEditorProvider>
    </div>
  )
}

export default TemplateEditor
