import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Loader } from '@liveconnect/communities-ui'
import { FormProvider, FormTextInput } from '@liveconnect/components'

import { buildValidationSchema } from './validations'
import { Main } from '../../components/Main'
import { Tenant } from '../../core/tenant/types'
import useTenant from '../../core/tenant/useTenant'
import useNotifications from '../../utils/notifications/useNotifications'
import UploadControl from '../../components/forms/UploadControl'
import FormActions from '../../components/FormActions'
import { useBlockRouteChangeWithDialog } from '../../utils/routing/useBlockRouteChange'
import useUi from '../../core/ui/useUi'

import './styles.scss'

const TenantScreen: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { detail, fetchTenant, editTenant, uploadImage } = useTenant()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const defaultValues = {
    name: '',
    imageUrl: '',
    googleAdManagerAccount: '',
    googleTagManagerAccounts: [],
  }

  const methods = useForm<Tenant>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: detail ?? defaultValues,
  })
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = methods

  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const handleCancel = () => {
    if (detail) {
      reset(detail)
    } else {
      reset(defaultValues)
    }
  }

  const handleConfirmCancel = () => {
    if (!isDirty) handleCancel()
    else
      showConfirmation({
        title: t('modal.cancel.generic.title'),
        subtitle: t('modal.cancel.generic.subtitle'),
        text: t('modal.cancel.generic.text'),
        confirmText: t('common.yes'),
        cancelText: t('common.no'),
        iconName: 'report_problem',
        onConfirm: handleCancel,
      })
  }

  const onSubmit = async (values: Tenant) => {
    setIsSubmitting(true)
    try {
      if (values?.imageUrl?.includes('blob:')) {
        const imageUrl = await uploadImage(values.imageUrl)
        values = { ...values, imageUrl }
      }
      if (values?.favIcoUrl?.includes('blob:')) {
        const favIcoUrl = await uploadImage(values.favIcoUrl)
        values = { ...values, favIcoUrl }
      }
      await editTenant(values)
      notify.success(t('tenant.form.successFeedback'))
    } catch (e) {
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    detail && reset(detail)
  }, [detail])

  useEffect(() => {
    fetchTenant()
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="Tenant">
      <div className="sticky-container">
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="user-list-title" className="h3">
              {t('tenant.title')}
            </h1>
            <p>{t('tenant.hint')}</p>
          </div>
        </div>
        <section className="mt-3">
          <FormProvider methods={methods}>
            <div className="row">
              <div className="col-12">
                <FormTextInput
                  control={control}
                  name="name"
                  label={t('tenant.form.name')}
                  placeholder={t('tenant.form.name')}
                  type="text"
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <UploadControl
                  control={control}
                  name="favIcoUrl"
                  title={t('tenant.form.favicon')}
                  label={t('common.add')}
                  disableCrop={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <UploadControl
                  control={control}
                  name="imageUrl"
                  title={t('tenant.form.image')}
                  label={t('common.add')}
                  required={true}
                  rules={{
                    image: {
                      maxWidth: 400,
                      maxHeight: 400,
                    },
                  }}
                />
              </div>
            </div>
            <div className="row">
              <h1 className="h5 gadm-title">{t('tenant.form.google.title')}</h1>
              <div className="col-12">
                <FormTextInput
                  control={control}
                  name="googleAdManagerAccount"
                  label={t('tenant.form.adManager')}
                  placeholder={t('tenant.form.adManager.placeholder')}
                  type="text"
                  required={false}
                />
              </div>
            </div>
            {(detail?.googleTagManagerAccounts || []).map((item, index) => (
              <div className="row" key={item.application}>
                <div className="col-12">
                  <FormTextInput
                    control={control}
                    name={`googleTagManagerAccounts.${index}.value`}
                    label={t(`tenant.form.tagManager.${item.application}`)}
                    placeholder={t('tenant.form.tagManager.placeholder')}
                    type="text"
                    required={false}
                  />
                </div>
              </div>
            ))}
          </FormProvider>
        </section>
      </div>
      <FormActions>
        <button
          className="btn btn-outline-primary"
          onClick={handleConfirmCancel}
        >
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          disabled={!isValid || isSubmitting || !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting ? <Loader /> : t('common.save')}
        </button>
      </FormActions>
    </Main>
  )
}

export default TenantScreen
