import { FC, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import SigninCallback from '../pages/auth/SigninCallback'
import Logout from '../pages/auth/Logout'
import SigninSilentRenew from '../pages/auth/SigninSilentRenew'
import NotFound from '../pages/error/NotFound'
import TenantRouter from './TenantRouter'
import { ErrorBoundary } from '../components/errors/ErrorBoundary'
import useAuth from '../core/auth/useAuth'
import useTenantConfig from '../core/tenantConfig/useTenantConfig'
import useMe from '../core/me/useMe'
import { useTranslation } from 'react-i18next'
import SignedOut from '../pages/auth/SignedOut'

const WithinRouter: FC = () => {
  const { i18n } = useTranslation()
  const { isLoggedIn } = useAuth()
  const { tenantConfig, fetchPublicTenantConfig } = useTenantConfig()
  const { me } = useMe()

  useEffect(() => {
    const lang = me?.isoCode ?? navigator.language
    i18n.changeLanguage(lang)
  }, [me])

  useEffect(() => {
    !isLoggedIn && fetchPublicTenantConfig()
  }, [isLoggedIn])

  return (
    <>
      <Helmet>
        {tenantConfig?.favIcoUrl && (
          <link
            id="favicon"
            rel="icon"
            href={tenantConfig?.favIcoUrl}
            type="image/x-icon"
          />
        )}
      </Helmet>
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/signed-out" element={<SignedOut />} />
        <Route path="/signin-callback" element={<SigninCallback />} />
        <Route path="/signin-silent-renew" element={<SigninSilentRenew />} />
        <Route path="t/:tenantId/*" element={<TenantRouter />} />
        <Route path="/" element={<div />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

const AppRouter: FC = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <WithinRouter />
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default AppRouter
