import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { FormRadio, FormProvider, Loader } from '@liveconnect/components'

import { Main } from '../../components/Main'
import FormActions from '../../components/FormActions'
import useSnackBars from '../../utils/notifications/useNotifications'
import useLocalizations from '../../core/localizations/useLocalizations'
import { ChangeLocalizationForm } from '../../core/localizations/types'
import { DEFAULT_LANG } from '../../i18n/config'
import { useBlockRouteChangeWithDialog } from '../../utils/routing/useBlockRouteChange'
import useMe from '../../core/me/useMe'

import './styles.scss'

interface Option {
  value: string
  label: string
}

const LocalizationScreen: FC = () => {
  const { t } = useTranslation()
  const notify = useSnackBars()
  const { localizations } = useLocalizations()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [options, setOptions] = useState<Option[]>([])
  const { me, changeLocalization } = useMe()

  const methods = useForm<ChangeLocalizationForm>({
    mode: 'onChange',
    defaultValues: { isoCode: me?.isoCode || DEFAULT_LANG },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const setLocalizationOptions = async () => {
    setOptions(
      (localizations ?? []).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }))
    )
  }

  const handleCancel = () => {
    reset({ isoCode: me?.isoCode || DEFAULT_LANG })
  }

  const onSubmit = async (data: ChangeLocalizationForm) => {
    setIsSubmitting(true)
    try {
      await changeLocalization(data.isoCode)
      setTimeout(() => {
        notify.success(t('localization.succeessFeedback'))
        reset()
      }, 100)
    } catch (e) {
      notify.error(t('localization.unknownError'))
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    setLocalizationOptions()
  }, [localizations])

  useEffect(() => {
    me?.isoCode && reset({ isoCode: me?.isoCode })
  }, [me?.isoCode])

  return (
    <Main ariaLabelledby="localization" className="Localization">
      <h1 className="h3">{t('common.language')}</h1>
      <p>{t('localization.hint')}</p>
      <FormProvider methods={methods}>
        <div>
          <FormRadio name="isoCode" control={control} options={options} />
        </div>
        <FormActions>
          <div className="Localization__actions">
            <button
              type="button"
              onClick={handleCancel}
              className="btn btn-outline-primary mr-2"
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn-primary"
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
            >
              {isSubmitting ? <Loader /> : t('common.save')}
            </button>
          </div>
        </FormActions>
      </FormProvider>
    </Main>
  )
}

export default LocalizationScreen
