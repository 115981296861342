import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseIdentityResult,
  IdentityRequestParams,
  IdentityListResponse,
  IdentityUser,
} from './types'
import {
  setList,
  clearList,
  showLoader,
  hideLoader,
  updateUserInList,
} from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useIdentity = (): UseIdentityResult => {
  const dispatch = useAppDispatch()
  const {
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
  } = useAppSelector((state) => state.identity)
  const { get, post, del } = useFetch()
  const { notifyApiError } = useError()

  const endpoint = 'identity-users'
  const errorModel = 'user'

  const parseParams = (requestParams?: IdentityRequestParams) => {
    const params: IdentityRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
    }
    return params
  }

  const getList = async (params?: IdentityRequestParams) => {
    const response: IdentityListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const fetchList = async (params?: IdentityRequestParams) => {
    dispatch(showLoader())
    const reqParams = parseParams(params)
    const response: IdentityListResponse | undefined = await getList(reqParams)
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const blockUser = async (id: string) => {
    try {
      const response: IdentityUser | undefined = await post({
        endpoint: `${endpoint}/${id}/block`,
        body: {},
      })
      response && dispatch(updateUserInList(response))
    } catch (e) {
      notifyApiError(e, errorModel, 'block')
    }
  }

  const unblockUser = async (id: string) => {
    try {
      const response: IdentityUser | undefined = await post({
        endpoint: `${endpoint}/${id}/unblock`,
        body: {},
      })
      response && dispatch(updateUserInList(response))
    } catch (e) {
      notifyApiError(e, errorModel, 'unblock')
    }
  }

  const sendRestartPasswordEmail = async (id: string) => {
    await post({
      endpoint: `${endpoint}/${id}/send-password-email`,
      body: {},
    })
  }

  const sendValidationdEmail = async (id: string) => {
    await post({
      endpoint: `${endpoint}/${id}/send-validation-email`,
      body: {},
    })
  }

  const deleteUser = async (id: string) => {
    try {
      await del({
        endpoint,
        id,
      })
    } catch (e) {
      notifyApiError(e, errorModel, 'delete')
    }
  }

  return {
    list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    fetchList,
    clearList: () => dispatch(clearList()),
    blockUser,
    unblockUser,
    sendRestartPasswordEmail,
    sendValidationdEmail,
    deleteUser,
  }
}

export default useIdentity
