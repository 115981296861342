import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { UseEventsResult, Event, CreateEventType } from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useEvents = (): UseEventsResult => {
  const dispatch = useAppDispatch()
  const { list, isLoaded } = useAppSelector((state) => state.events)
  const { get, post, put } = useFetch()
  const { notifyApiError } = useError()

  const endpoint = 'events'
  const errorModel = 'event'

  const getList = async () => {
    const response: Event[] | undefined = await get({
      endpoint,
    })
    return response
  }

  const fetchList = async () => {
    dispatch(showLoader())
    const response: Event[] | undefined = await getList()
    response && dispatch(setList({ list: response }))
    dispatch(hideLoader())
  }

  const createEvent = async (event: CreateEventType) => {
    try {
      await post({ endpoint, body: event })
    } catch (e) {
      notifyApiError(e, errorModel, 'create')
    }
  }

  const updateEvent = async (event: Event) => {
    try {
      await put({ endpoint, id: event.id, body: event })
    } catch (e) {
      notifyApiError(e, errorModel, 'edit')
    }
  }

  const changeEventStatus = async (id: string, isEnabled: boolean) => {
    try {
      await post({
        endpoint: `${endpoint}/${id}/change-status`,
        body: { isEnabled },
      })
    } catch (e) {
      const action = !isEnabled ? 'deactivate' : 'activate'
      notifyApiError(e, errorModel, action)
    }
  }

  return {
    list,
    isLoaded,
    fetchList,
    getList,
    clearList: () => dispatch(clearList()),
    createEvent,
    updateEvent,
    changeEventStatus,
  }
}

export default useEvents
