import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ColumnDef, Row } from '@tanstack/react-table'
import { Pagination } from '@liveconnect/components'

import { Main } from '../../components/Main'
import { AppsInstances, LiveconnectUser } from '../../core/liveconnect/types'
import useLiveconnect from '../../core/liveconnect/useLiveconnect'
import AppInstancesModal from './AppInstancesModal'
import ContentTable from '../../components/ContentTable'
import useUi from '../../core/ui/useUi'

import './styles.scss'
import useNotifications from '../../utils/notifications/useNotifications'

const LiveconnectScreen: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showConfirmation } = useUi()
  const notify = useNotifications()
  const {
    list,
    isLoaded,
    availableItems,
    page,
    availablePages,
    search,
    pageSize,
    fetchList,
    clearList,
    deleteUser,
  } = useLiveconnect()

  const [showAppInstances, setShowAppInstances] = useState<
    AppsInstances[] | null
  >(null)

  const handleDelete = async (id: string) => {
    try {
      await deleteUser(id)
      notify.success(t('liveconnect.delete.successFeedback'))
      fetchList({ page, search })
    } catch (e) {}
  }

  const handleConfirmDelete = (id: string, name: string) => {
    showConfirmation({
      title: t('liveconnect.delete.modal.title'),
      subtitle: t('liveconnect.delete.modal.subtitle', { user: name }),
      text: t('liveconnect.delete.modal.text'),
      iconName: 'report_problem',
      confirmText: t('common.remove'),
      cancelText: t('common.cancel'),
      confirmActionOptions: {
        confirmText: t('liveconnect.delete.modal.confirmText'),
        word: t('liveconnect.delete.modal.word'),
      },
      onConfirm: () => {
        handleDelete(id)
      },
    })
  }

  const actions = (row: LiveconnectUser) => {
    return [
      {
        onClick: () => navigate(`./${row.id}`),
        label: t('liveconnect.list.table.action.detail'),
        icon: 'edit',
      },
      {
        onClick: () => handleConfirmDelete(row.id, row.name),
        label: t('liveconnect.list.table.action.delete'),
        icon: 'delete_outline',
      },
    ]
  }

  const columns: ColumnDef<LiveconnectUser>[] = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: t('liveconnect.list.table.email'),
      },
      {
        accessorKey: 'name',
        header: t('liveconnect.list.table.name'),
        cell: ({ row }: { row: Row<LiveconnectUser> }) =>
          `${row.original.name} ${row.original.surname}`,
      },
      {
        accessorKey: 'appsInstances',
        header: t('liveconnect.list.table.appsinstances'),
        cell: ({ row }: { row: Row<LiveconnectUser> }) =>
          renderAppsInstancesCell(row.original),
      },
    ],
    []
  )

  useEffect(() => {
    fetchList({ page: 1 })
    return () => {
      clearList()
    }
  }, [])

  const renderAppsInstancesCell = (row: LiveconnectUser) => {
    if (!row.appsInstances?.length) return ''
    return row.appsInstances.length === 1 ? (
      row.appsInstances[0].name
    ) : (
      <div
        className="Liveconnect__appsInstances"
        onClick={() => setShowAppInstances(row.appsInstances)}
      >
        <div className="Liveconnect__appsInstances__cell">{`${
          row.appsInstances.length
        } ${t('liveconnect.list.table.appsinstances.cell')}`}</div>
      </div>
    )
  }

  return (
    <>
      <Main ariaLabelledby="user-list-title" className="Liveconnect">
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="user-list-title" className="h3">
              {t('liveconnect.title')}
            </h1>
            <p>{t('liveconnect.list.hint')}</p>
          </div>
        </div>
        <section className="mt-3">
          <div className="Liveconnect__list">
            <ContentTable
              title={t('user.table.title')}
              columns={columns}
              data={list}
              emptyText={t('liveconnect.emptyList')}
              actions={actions}
              onSearch={(s: string) => {
                fetchList({ page: 1, search: s })
              }}
              isLoaded={isLoaded}
              searchPlaceholder={t('liveconnect.list.search')}
            />
            <Pagination
              currentPage={(page || 1) - 1}
              totalPages={availablePages}
              totalItems={availableItems}
              pageSize={pageSize}
              singlePageText={(count) =>
                t('common.pager.singlePage', { count })
              }
              multiplePageText={(start, end, total) =>
                t('common.pager.multiplePage', { start, end, total })
              }
              onChange={(pageIndex) => fetchList({ page: pageIndex + 1 })}
            />
          </div>
        </section>
      </Main>
      {showAppInstances && (
        <AppInstancesModal
          onClose={() => setShowAppInstances(null)}
          appInstances={showAppInstances}
        />
      )}
    </>
  )
}

export default LiveconnectScreen
