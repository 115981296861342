import { Color, ComponentType } from '../../pages/TenantDesign/types'
import useFetch from '../../utils/fetch/useFetch'
import useNotifications from '../../utils/notifications/useNotifications'

import { UseTenantDesignResult } from './types'

const useTenantDesign = (): UseTenantDesignResult => {
  const { get, put } = useFetch()
  const notify = useNotifications()

  const endpoint = 'components-design'
  const errorModel = 'componentsDesign'

  const getDesigns = async (component: ComponentType) => {
    const response: Color[] | undefined = await get({
      endpoint: `${endpoint}/${component}`,
    })
    return response
  }

  const editDesign = async (
    componentDesign: Color[],
    component: ComponentType
  ) => {
    try {
      const payload = {
        values: componentDesign,
      }
      await put({ endpoint: `${endpoint}/${component}`, body: payload })
      notify.success(
        'Nuevos cambios en la configuración de diseño guardados correctamente'
      )
    } catch (e) {
      notify.error('Error al guardar cambios en la configuración de diseño')
    }
  }
  return {
    getDesigns,
    editDesign,
  }
}

export default useTenantDesign
