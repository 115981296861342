import {
  CheckboxItem,
  IconItem,
  MenuItem,
  RadioItem,
  TabItem,
  UseDesignConstantsResult,
} from './types'

const useDesignConstants = (): UseDesignConstantsResult => {
  const menuItems: MenuItem[] = [
    {
      index: 0,
      name: 'Tema principal',
      value: 'theme',
    },
    {
      index: 1,
      name: 'Navegación, iconos y selectores',
      value: 'navigation',
    },
    {
      index: 2,
      name: 'Botones',
      value: 'buttons',
    },
    {
      index: 3,
      name: 'Desplegable',
      value: 'dropdown',
    },
    {
      index: 4,
      name: 'Etiqueta',
      value: 'tags',
    },
  ]

  const paginationAvailablePages = 12

  const tabItems: TabItem[] = [
    { id: 'section1', component: <></>, label: 'Section 1' },
    { id: 'section2', component: <></>, label: 'Section 2', active: true },
    { id: 'section3', component: <></>, label: 'Section 3' },
    { id: 'section4', component: <></>, label: 'Section 4', disabled: true },
  ]

  const iconItems: IconItem[] = [
    { id: 0, name: 'home', style: '' },
    { id: 1, name: 'home', style: 'hover' },
    { id: 2, name: 'home', style: 'active' },
    { id: 3, name: 'home', style: 'disabled' },
  ]

  const radioItems: RadioItem[] = [
    { id: 0, value: 'option1', label: 'Categoría', disabled: false },
    { id: 1, value: 'option2', label: 'Categoría', disabled: false },
    { id: 2, value: 'option3', label: 'Categoría', disabled: true },
    { id: 3, value: 'option4', label: 'Categoría', disabled: true },
  ]

  const checkboxItems: CheckboxItem[] = [
    { id: 'checkbox1', value: true, label: 'Categoría', disabled: false },
    { id: 'checkbox2', value: false, label: 'Categoría', disabled: false },
    { id: 'checkbox3', value: true, label: 'Categoría', disabled: true },
    { id: 'checkbox4', value: false, label: 'Categoría', disabled: true },
  ]

  const defaultTheme: Record<string, string> = {
    'common-initial': '#000000',
    'common-hover': '#E32424',
    'common-active': '#E95050',
    'common-disabled': '#B5B6BB',
    'button-primary-initial': '#000000',
    'button-primary-hover': '#292152',
    'button-primary-active': '#544D75',
    'button-primary-text': '#FFFFFF',
    'button-secondary-initial': '#000000',
    'button-secondary-hover': '#292152',
    'button-secondary-active': '#544D75',
    'button-ternary-text': '#000000',
    'button-ternary-decoration': '#E32424',
    'button-link': '#8322FA',
    'select-chevron': '#E32424',
    'theme-background-primary': '#171736',
    'theme-background-secondary': '#292152',
    'theme-initial': '#FFFFFF',
    'theme-hover': '#E32424',
    'theme-badget': '#E32424',
    'tag-initial-background': '#F9F5FF',
    'tag-initial-text': '#3A037D',
    'tag-hover-background': '#F4EDFF',
    'tag-hover-text': '#5C05C7',
    'tag-active-background': '#5D2A9A',
    'tag-active-text': '#FFFFFF',
  }

  return {
    menuItems,
    paginationAvailablePages,
    tabItems,
    iconItems,
    radioItems,
    checkboxItems,
    defaultTheme,
  }
}

export default useDesignConstants
