import { FC } from 'react'
import { Navigate } from 'react-router-dom'

import Restricted from '.'
import { ComplimentType, Permission } from '../../core/permissions/types'
import { useCustomRouter } from '../../utils/extractParams'

interface RestrictedRouteProps {
  permissions?: Permission[]
  mustComply?: ComplimentType
  element: React.ReactNode | null
}

const RestrictedRoute: FC<RestrictedRouteProps> = ({
  permissions,
  mustComply,
  element,
}) => {
  const { basePath } = useCustomRouter()

  return (
    <Restricted
      to={permissions}
      mustComply={mustComply}
      fallback={<Navigate to={`${basePath}/unauthorized`} />}
    >
      {element}
    </Restricted>
  )
}

export default RestrictedRoute
