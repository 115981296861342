import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  PrivacyPolicies,
  PrivacyPoliciesDetail,
  PrivacyPoliciesState,
} from './types'

const initialState: PrivacyPoliciesState = {
  current: null,
  list: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'privacyPolicies',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<PrivacyPolicies[]>) => {
      state.list = action.payload
    },
    setCurrent: (state, action: PayloadAction<PrivacyPoliciesDetail>) => {
      state.current = action.payload
    },
  },
})

export const { setCurrent, showLoader, hideLoader, setList } = slice.actions

export default slice.reducer
