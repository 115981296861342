import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EmailsFilters } from '../types'

const initialState: EmailsFilters = {
  items: [],
}

export const emailsStatesSlice = createSlice({
  name: 'emailsStates',
  initialState,
  reducers: {
    setEmailsStates: (state, action: PayloadAction<EmailsFilters>) => {
      state.items = action.payload.items
    },
  },
})

export const { setEmailsStates } = emailsStatesSlice.actions

export default emailsStatesSlice.reducer
