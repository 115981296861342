import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  MessageFilters,
  PublishedMessages,
  PublishedMessagesDetail,
  PublishedMessagesState,
  PublishedStatistics,
} from './types'

const initialState: PublishedMessagesState = {
  list: [],
  detail: null,
  serviceList: [],
  serviceSelected: '',
  statusSelected: 'Success',
  messageStatusList: [],
  publishedStatistics: null,
  isLoaded: false,
  isDetailLoaded: false,
}

export const slice = createSlice({
  name: 'publishedMessages',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearStore: () => initialState,
    clearMessageListStore: (state) => {
      return { ...initialState, serviceList: state.serviceList }
    },
    setList: (state, action: PayloadAction<PublishedMessages[]>) => {
      state.list = action.payload
    },
    showDetailLoader: (state) => {
      state.isDetailLoaded = false
    },
    hideDetailLoader: (state) => {
      state.isDetailLoaded = true
    },
    setFilters: (state, action: PayloadAction<MessageFilters>) => {
      state.serviceSelected = action.payload.serviceSelected
      state.statusSelected = action.payload.statusSelected
    },
    setDetail: (state, action: PayloadAction<PublishedMessagesDetail>) => {
      state.detail = action.payload
    },
    setServiceList: (state, action: PayloadAction<string[]>) => {
      state.serviceList = action.payload
    },
    setMessageStatusList: (state, action: PayloadAction<string[]>) => {
      state.messageStatusList = action.payload
    },
    setPublishedStatistics: (
      state,
      action: PayloadAction<PublishedStatistics>
    ) => {
      state.publishedStatistics = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setList,
  clearStore,
  clearMessageListStore,
  setDetail,
  setFilters,
  setServiceList,
  setMessageStatusList,
  setPublishedStatistics,
  showDetailLoader,
  hideDetailLoader,
} = slice.actions

export default slice.reducer
