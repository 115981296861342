import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  HistoryGraph,
  HistoryGraphRequestParams,
  UseHistoryGraphResult,
} from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useHistoryGraph = (): UseHistoryGraphResult => {
  const dispatch = useAppDispatch()
  const { list, isLoaded } = useAppSelector((state) => state.historyGraph)
  const { get } = useFetch()

  const endpoint = 'dashboard'

  const getHistoryGraph = async (params?: HistoryGraphRequestParams) => {
    const response: HistoryGraph[] | undefined = await get({
      endpoint: `${endpoint}/statistics`,
      params,
    })
    return response
  }

  const fetchHistoryGraph = async (params?: HistoryGraphRequestParams) => {
    dispatch(showLoader())
    const response = await getHistoryGraph(params)
    response && dispatch(setList(response))
    dispatch(hideLoader())
  }

  const clearHistoryGraph = () => {
    dispatch(clearList())
  }

  return {
    list,
    isLoaded,
    fetchHistoryGraph,
    clearHistoryGraph,
  }
}

export default useHistoryGraph
