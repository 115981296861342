import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import IntegrationDashboard from '../pages/Dashboard/Integration'
import PublishedMessagesScreen from '../pages/Dashboard/Integration/PublishedMessages'
import ReceivedMessagesScreen from '../pages/Dashboard/Integration/ReceivedMessages'

const IntegrationDashboardRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<IntegrationDashboard />} />
      <Route path="/published" element={<PublishedMessagesScreen />} />
      <Route path="/received" element={<ReceivedMessagesScreen />} />
    </Routes>
  )
}

export default IntegrationDashboardRouter
