import { IdDocumentTypeResponseItem, UseIdDocumentTypesResult } from './types'
import useFetch from '../../utils/fetch/useFetch'

const useIdDocumentTypes = (): UseIdDocumentTypesResult => {
  const { get } = useFetch()

  const endpoint = 'identification-document-types'

  const getList = async () => {
    const response: IdDocumentTypeResponseItem[] | undefined = await get({
      endpoint,
    })
    return (response || []).map((item) => item.type)
  }

  return {
    getIdDocumentTypes: getList,
  }
}

export default useIdDocumentTypes
