import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { LiveConnectUserPosition } from '../../../../core/liveconnect/types'
import { useHtmlToString } from '../../../../utils/useHtmlToString'

const duplicatedJobs = (
  _: string | undefined,
  context: yup.TestContext
): boolean => {
  const currentValues = context.from[1].value.positions
  const names = currentValues.map(
    ({ jobTitle, company }: LiveConnectUserPosition) =>
      `c_${jobTitle.toLowerCase()}-e_${company.toLocaleLowerCase()}`
  )
  const duplicates = names.filter(
    (item: string, index: number) => names.indexOf(item.toLowerCase()) !== index
  )
  const _value = `c_${context.parent.jobTitle}-e_${context.parent.company}`
  return !duplicates.includes(_value?.toLowerCase())
}

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    name: yup
      .string()
      .label(t('liveconnect.detail.profile.name'))
      .required(({ label }) => t('validations.required', { label }))
      .max(200, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 200 })
      ),
    surname: yup
      .string()
      .label(t('liveconnect.detail.profile.surname'))
      .required(({ label }) => t('validations.required', { label }))
      .max(200, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 200 })
      ),
    email: yup
      .string()
      .label(t('liveconnect.detail.profile.email'))
      .required(({ label }) => t('validations.required', { label })),
    description: yup
      .string()
      .nullable()
      .label(t('liveconnect.detail.profile.description'))
      .test(
        'description',
        ({ label }) => t('validations.maxLength', { label, maxLength: 1000 }),
        val => useHtmlToString(val ?? '')!.length <= 1000
      ),
    dateOfBirth: yup
      .date()
      .label(t('liveconnect.detail.profile.dateOfBirth'))
      .nullable()
      .default(undefined)
      .transform((curr, orig) => orig === '' ? null : curr)
      .max(new Date(), ({ label }) => t('validations.dateinfuture', { label })),
    documentType: yup
      .string()
      .label(t('liveconnect.detail.profile.documentType'))
      .nullable()
      .max(3, ({ label, max }) => t('validations.maxLength', { label, max })),
    identificationDocument: yup
      .string()
      .label(t('liveconnect.detail.profile.identificationDocument'))
      .nullable()
      .max(32, ({ label, max }) => t('validations.maxLength', { label, max }))
      .when('documentType', {
        is: (value: string) => !!value,
        then: yup
          .string()
          .required(({ label }) => t('validations.required', { label })),
      })
      .when('documentType', {
        is: (value: string) => !value,
        then: yup
          .string()
          .length(0, ({ label }) => t('validations.empty', { label })),
      }),
    positions: yup.array().of(
      yup
        .object({
          jobTitle: yup
            .string()
            .label(t('liveconnect.detail.profile.job'))
            .max(200, ({ label }) =>
              t('validations.maxLength', { label, maxLength: 200 })
            )
            .test(
              'company-required',
              t('validations.required', {
                label: t('liveconnect.detail.profile.job').toLowerCase(),
              }),
              function (value, context) {
                return (!!value && !!!context.parent.company) || (!!!value && !!context.parent.company) || (!!value && !!context.parent.company)
              })
            .test(
              'jobs-company',
              t('validations.duplicateField', {
                label: t('liveconnect.detail.profile.job').toLowerCase(),
              }),
              (value, context) => duplicatedJobs(value, context)
            ),
          company: yup
            .string()
            .label(t('liveconnect.detail.profile.company'))
            .max(200, ({ label }) =>
              t('validations.maxLength', { label, maxLength: 200 })
            )
            .test(
              'jobTitle-required',
              t('validations.required', {
                label: t('liveconnect.detail.profile.company').toLowerCase(),
              }),
              function (value, context) {
                return (!!value && !!!context.parent.jobTitle) || (!!!value && !!context.parent.jobTitle) || (!!value && !!context.parent.jobTitle)
              }
            )
            .test(
              'jobs-company',
              t('validations.duplicateField', {
                label: t('liveconnect.detail.profile.company').toLowerCase(),
              }),
              (value, context) => duplicatedJobs(value, context)
            ),
        })
        //.required()
    ),
  })
}
