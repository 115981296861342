import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Permission } from '../core/permissions/types'
import IdentityScreen from '../pages/Identity'
import IdentityClientConfig from '../pages/IdentityClientProperties'
import PermissionRoute from '../containers/Restricted/RestrictedRoute'
import DomainBlackList from '../pages/DomainBlackList'

const IdentityRouter: FC = () => {
  return (
    <Routes>
      <Route
        path="/users"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_Ids]}
            element={<IdentityScreen />}
          />
        }
      />
      <Route
        path="/config"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_IdsClients]}
            element={<IdentityClientConfig />}
          />
        }
      />
      <Route
        path="/domain-blacklist"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_IdsClients]}
            element={<DomainBlackList />}
          />
        }
      />
    </Routes>
  )
}

export default IdentityRouter
