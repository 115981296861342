import { FC, useEffect, useMemo, useState } from 'react'
import './styles.scss'
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  Tooltip,
  ChartOptions,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { addHours } from 'date-fns/esm'
import { useTranslation } from 'react-i18next'
import { GREEN_COLOR, RED_COLOR } from '..'
import { useNavigate } from 'react-router'
import { SelectControl } from '@liveconnect/components'
import useReceivedMessages from '../../../../core/receivedMessages/useReceivedMessages'
import ForwardButton from '../../../../components/ForwardButton'

interface Option<T> {
  value: string
  label: T
}

const SuscribedMessagesGraph: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    serviceList,
    receivedStatistics,
    fetchReceivedStatistics,
    fetchServiceList,
  } = useReceivedMessages()

  const [serviceSelected, setServiceSelected] = useState<string>('')

  const serviceOptions: Option<string>[] = useMemo(() => {
    const allServices = {
      value: '',
      label: t('dashboard.all'),
    }
    const list = serviceList.map(
      (item) =>
        ({
          value: item,
          label: item,
        } as Option<string>)
    )
    return [allServices, ...list]
  }, [serviceList])

  // Service Combo - END

  ChartJS.register(ArcElement, Tooltip, Legend)

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const data = useMemo(() => {
    const labels = [t('dashboard.received'), t('dashboard.failed')]
    const data = {
      labels,
      datasets: [
        {
          data: [receivedStatistics?.success, receivedStatistics?.failed],
          borderColor: [GREEN_COLOR, RED_COLOR],
          backgroundColor: [GREEN_COLOR, RED_COLOR],
        },
      ],
    }

    return data
  }, [receivedStatistics])

  useEffect(() => {
    const actualDateTime = addHours(new Date().setMinutes(0, 0, 0), 1)

    fetchReceivedStatistics({
      startDate: addHours(actualDateTime, -24).toISOString(),
      endDate: actualDateTime.toISOString(),
      serviceName: serviceSelected,
    })
  }, [serviceSelected])

  useEffect(() => {
    !serviceList.length && fetchServiceList()
  }, [])

  return (
    <div className="SuscribedMessagesGraph">
      <div className="SuscribedMessagesGraph__top">
        <div className="SuscribedMessagesGraph__top__title">
          {t('dashboard.receivedMessages.short')}
        </div>
        <div className="SuscribedMessagesGraph__top__service">
          <SelectControl
            name="app"
            onChange={(value: string) => setServiceSelected(value)}
            label={t('dashboard.services')}
            options={serviceOptions}
            required={false}
            value={serviceSelected}
            noOptionsMessage=""
            isClearable={false}
            placeholder={t(
              'dashboard.suscribedMessages.selectService.placeholder'
            )}
          />
        </div>
      </div>
      <div className="SuscribedMessagesGraph__bottom">
        <div className="SuscribedMessagesGraph__bottom__graph">
          <Doughnut options={options} data={data} />
        </div>

        <div className="SuscribedMessagesGraph__bottom__legend">
          <div className="tag">
            <div className="tag__title">
              <div
                className="rectangle"
                style={{ background: GREEN_COLOR }}
              ></div>
              {t('dashboard.processed')}
            </div>
            <div>{!!receivedStatistics ? receivedStatistics.success : 0}</div>
          </div>
          <div className="tag">
            <div className="tag__title">
              <div
                className="rectangle"
                style={{ background: RED_COLOR }}
              ></div>
              {t('dashboard.failed')}
            </div>
            <div>{!!receivedStatistics ? receivedStatistics.failed : 0}</div>
          </div>
        </div>
      </div>
      <div className="SuscribedMessagesGraph__seemore">
        <ForwardButton
          label={t('dashboard.seemore')}
          onClick={() => navigate(`./received`)}
        />
      </div>
    </div>
  )
}

export default SuscribedMessagesGraph
