import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, FormTextInput } from '@liveconnect/components'

import useNotifications from '../../../utils/notifications/useNotifications'
import { CreateEventType, Event } from '../../../core/events/types'
import { buildValidationSchema } from './validations'
import useEvents from '../../../core/events/useEvents'
import { FetchError } from '../../../utils/fetch/types'

import './styles.scss'

interface CreateEventProps {
  onClose: () => void
  event?: Event
}

const CreateEvent: FC<CreateEventProps> = ({ event, onClose }) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { createEvent, updateEvent, fetchList } = useEvents()

  const methods = useForm<CreateEventType>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: event
      ? event
      : {
          code: '',
          name: '',
        },
  })
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
  } = methods

  const onSubmit = async (newEvent: CreateEventType) => {
    setIsSubmitting(true)
    try {
      if (event) {
        await updateEvent({ ...event, ...newEvent })
      } else {
        await createEvent(newEvent)
      }
      await fetchList()
      const successMsg = event
        ? 'event.edit.modal.successFeedback'
        : 'event.create.modal.successFeedback'
      notify.success(t(successMsg))
      onClose()
    } catch (error: unknown) {
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        setError('code', {
          type: 'custom',
          message: t(`validations.ENTITY_CONFLICT`, {
            label: t(`event.create.modal.code`).toLowerCase(),
          }),
        })
      } else {
        onClose()
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      className="CreateEvent"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {event ? t('event.edit.modal.title') : t('event.create.modal.title')}
        </Modal.Title>
      </Modal.Header>
      <FormProvider methods={methods}>
        <Modal.Body>
          <div className="d-flex CreateEvent__legend">
            <div className="bd-highlight">{t('common.required')}</div>
          </div>
          <div className="mt-3">
            <h5>{t(`common.modal.main-data`)}</h5>
          </div>
          <div className="row">
            <div className="col-6">
              <FormTextInput
                control={control}
                name="code"
                label={t('event.create.modal.code')}
                placeholder={t('event.create.modal.code.placeholder')}
                type="text"
                required={true}
                disabled={!!event}
              />
            </div>
            <div className="col-6">
              <FormTextInput
                control={control}
                name="name"
                label={t('event.create.modal.name')}
                placeholder={t('event.create.modal.name.placeholder')}
                type="text"
                required={true}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={onClose}>
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
          >
            {event ? t('common.save') : t('common.create')}
          </button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

export default CreateEvent
