import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@liveconnect/icons'
import { ToggleControl } from '@liveconnect/components'

import { Edition } from '../../../core/editions/types'
import { Event } from '../../../core/events/types'
import CreateEdition from '../CreateEdition'
import useEditions from '../../../core/editions/useEditions'
import useEvents from '../../../core/events/useEvents'
import useUi from '../../../core/ui/useUi'
import useNotifications from '../../../utils/notifications/useNotifications'

import './styles.scss'

interface EditionRowProps {
  edition: Edition
  event: Event
}

export const EditionRow: FC<EditionRowProps> = ({ edition, event }) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { changeEditionStatus } = useEditions()
  const { fetchList } = useEvents()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const handleConfirmChangeStatus = () => {
    const action = edition.isEnabled ? 'deactivate' : 'activate'
    showConfirmation({
      title: t(`edition.confirm.${action}.title`),
      subtitle: t(`edition.confirm.${action}.subtitle`, { name: edition.name }),
      confirmText: t(`common.${action}`),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        handleChangeStatus()
      },
    })
  }

  const handleChangeStatus = async () => {
    const action = edition.isEnabled ? 'deactivate' : 'activate'
    try {
      await changeEditionStatus(edition.id, !edition.isEnabled)
      notify.success(t(`edition.changeStatus.${action}.successFeedback`))
      fetchList()
    } catch (e) {}
  }

  return (
    <>
      <div className="EditionRow row">
        <div className="EditionRow__name col-4">{edition.code}</div>
        <div className="EditionRow__brands col-6">{edition.name}</div>
        <div className="EditionRow__isPublished col-1">
          <ToggleControl
            label=""
            name="isPublished"
            value={edition.isEnabled}
            onChange={handleConfirmChangeStatus}
            disabled={!event.isEnabled}
          />
        </div>
        <div className="EditionRow__actions col-1">
          <button
            className="EditionRow__actions__edit"
            onClick={() => setShowEditModal(true)}
            title={t('common.edit')}
          >
            <Icon name="edit" />
          </button>
        </div>
      </div>
      {showEditModal && (
        <CreateEdition
          onClose={() => setShowEditModal(false)}
          event={event}
          edition={edition}
        />
      )}
    </>
  )
}

export const EditionHeader: FC = () => {
  const { t } = useTranslation()
  return (
    <div className="EditionList__header row">
      <div className="EditionList__header__name col-4">
        {t('edition.list.headers.code')}
      </div>
      <div className="EditionList__header__brands col-6">
        {t('edition.list.headers.name')}
      </div>
      <div className="EditionList__header__isPublished col-1">
        {t('edition.list.headers.active')}
      </div>
      <div className="EditionList__header__actions col-1">&nbsp;</div>
    </div>
  )
}
