import { RadioControl, SelectControl } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import DiffViewer from '@liveconnect/react-diff-viewer'
import { Main } from '../../../../components/Main'
import useLocalizations from '../../../../core/localizations/useLocalizations'
import { CompareTermsConsResponse } from '../../../../core/termsCons/types'
import useTermsCons from '../../../../core/termsCons/useTermsCons'
import { DEFAULT_LANG } from '../../../../i18n/config'
import { useCustomRouter } from '../../../../utils/extractParams'

import './styles.scss'

const TermsConsCompare = () => {
  const { id1, id2 } = useParams()
  const { t } = useTranslation()
  const { compareTermsCons } = useTermsCons()
  const { localizations } = useLocalizations()
  const { basePath } = useCustomRouter()
  const [selectedLang, setSelectedLang] = useState<string>(DEFAULT_LANG)
  const [comparations, setComparations] = useState<CompareTermsConsResponse>()

  const initComparations = async () => {
    const response = await compareTermsCons({
      originTermId: id1 as string,
      destinyTermId: id2 as string,
    })
    setComparations(response)
  }

  const originComparation = useMemo(() => {
    if (comparations)
      return comparations?.originTermCondition?.creationDate >=
        comparations?.destinyTermCondition?.creationDate
        ? comparations?.destinyTermCondition
        : comparations?.originTermCondition
  }, [comparations])

  const originComparationTextValue = useMemo(() => {
    if (!originComparation) return undefined

    const langItem = originComparation.termConditionValues.find(
      (item) => item.isoCode === selectedLang
    )
    return langItem?.value
  }, [originComparation, selectedLang])

  const destinyComparation = useMemo(() => {
    if (comparations)
      return comparations?.originTermCondition.creationDate <=
        comparations?.destinyTermCondition.creationDate
        ? comparations?.destinyTermCondition
        : comparations?.originTermCondition
  }, [comparations])

  const destinyComparationTextValue = useMemo(() => {
    if (!destinyComparation) return undefined

    const langItem = destinyComparation?.termConditionValues.find(
      (item) => item.isoCode === selectedLang
    )
    return langItem?.value
  }, [destinyComparation, selectedLang])

  useEffect(() => {
    initComparations()
  }, [id1, id2])

  return (
    <Main ariaLabelledby="TermsConsCompare" className="TermsConsCompare">
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="h3">{t('termsCons.compare.title')}</h1>
        </div>
        <div className="TermsConsCompare__header__link">
          <Link to={`${basePath}/termscons`}>
            <Icon name="chevron_left" />
            {t('common.return')}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-5 d-flex TermsConsCompare__langs">
          {localizations.map((item) => (
            <RadioControl
              key={item.isoCode}
              id=""
              name=""
              label={t(`localizations.${item.isoCode}`)}
              value={item.isoCode}
              checked={item.isoCode === selectedLang}
              onChange={() => setSelectedLang(item.isoCode)}
              hiddenLabel={false}
            />
          ))}
        </div>
      </div>
      <div className="TermsConsCompare__comparator">
        {originComparation && destinyComparation && (
          <DiffViewer
            oldValue={originComparationTextValue}
            newValue={destinyComparationTextValue}
            leftTitle={`${originComparation.code} - ${originComparation.author}`}
            rightTitle={`${destinyComparation.code} - ${destinyComparation.author}`}
            splitView={true}
            showDiffOnly={false}
          />
        )}
      </div>
    </Main>
  )
}

export default TermsConsCompare
