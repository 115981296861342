import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabsInternal } from '@liveconnect/components'

import { Main } from '../../components/Main'
import PrivacyPoliciesVersions from './Versions'
import Form from './Form'

const PrivacyPoliciesScreen: FC = () => {
  const { t } = useTranslation()
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs([
      {
        id: 'form',
        label: t('privacyPolicies.tabs.form'),
        component: <Form />,
      },
      {
        id: 'versions',
        label: t('privacyPolicies.tabs.versions'),
        component: <PrivacyPoliciesVersions />,
      },
    ])
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="PrivacyPoliciesScreen">
      <h1 className="h3">{t('privacyPolicies.form.title')}</h1>
      <TabsInternal tabs={tabs} />
    </Main>
  )
}

export default PrivacyPoliciesScreen
