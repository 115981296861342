import { FC, useState } from 'react'
import { ToggleControl } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'
import { Edition } from '../../../core/editions/types'
import { Event } from '../../../core/events/types'
import CreateEvent from '../CreateEvent'
import CreateEdition from '../CreateEdition'
import useEvents from '../../../core/events/useEvents'
import { EditionRow, EditionHeader } from '../EditionRow'
import useUi from '../../../core/ui/useUi'
import useNotifications from '../../../utils/notifications/useNotifications'

import './styles.scss'

interface EventRowProps {
  event: Event
}

const EventRow: FC<EventRowProps> = ({ event }) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { changeEventStatus, fetchList } = useEvents()
  const [editEvent, setEditEvent] = useState<Event | null>(null)
  const [showCreateEdition, setShowCreateEdition] = useState<boolean>(false)
  const [showEditions, setShowEditions] = useState<boolean>(false)

  const handleConfirmChangeStatus = () => {
    const action = event.isEnabled ? 'deactivate' : 'activate'
    showConfirmation({
      title: t(`event.confirm.${action}.title`),
      subtitle: t(`event.confirm.${action}.subtitle`, { name: event.name }),
      confirmText: t(`common.${action}`),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        handleChangeStatus()
      },
    })
  }

  const handleChangeStatus = async () => {
    const action = event.isEnabled ? 'deactivate' : 'activate'
    try {
      await changeEventStatus(event.id, !event.isEnabled)
      notify.success(t(`event.changeStatus.${action}.successFeedback`))
      fetchList()
    } catch (e) {}
  }

  return (
    <>
      <div className="EventRow">
        <div className="EventRow__header">
          <div className="d-flex">
            <div className="EventRow__header__title">
              <span className="EventRow__header__title__name">
                {event.name}
              </span>
              <div className="EventRow__header__title__code">
                {t('event.code')}:{' '}
                <div className="EventRow__header__title__code__name">
                  {event.code}
                </div>
              </div>
            </div>
          </div>
          <div className="EventRow__header__actions">
            <div
              className="EventRow__header__actions__edit"
              onClick={() => setEditEvent(event)}
            >
              <Icon name="edit" />
            </div>
            <ToggleControl
              className="EventRow__header__actions__publish"
              label={t('event.actions.active')}
              name={'isPublished' + event.id}
              value={event.isEnabled}
              onChange={handleConfirmChangeStatus}
            />
            <div
              className="EventRow__header__actions__toggle-open"
              onClick={() => setShowEditions(!showEditions)}
            >
              {showEditions ? (
                <Icon name="keyboard_arrow_up" />
              ) : (
                <Icon name="keyboard_arrow_down" />
              )}
            </div>
          </div>
        </div>
        <div className="EventRow__editions__add">
          <button
            className="non-styled-btn"
            onClick={() => {
              setShowCreateEdition(true)
            }}
          >
            {t('event.editions.add')}
            <Icon name="add" />
          </button>
        </div>
        <div className="EventRow__editions">
          <EditionHeader />
          {showEditions && (
            <div className="EditionList">
              {(event.editions ?? []).map((edition: Edition) => (
                <EditionRow edition={edition} key={edition.id} event={event} />
              ))}
            </div>
          )}
        </div>
      </div>
      {editEvent && (
        <CreateEvent onClose={() => setEditEvent(null)} event={event} />
      )}
      {showCreateEdition && (
        <CreateEdition
          onClose={() => setShowCreateEdition(false)}
          event={event}
        />
      )}
    </>
  )
}

export default EventRow
