import classNames from 'classnames'
import SVG from 'react-inlinesvg'

interface IconProps {
  name: string
  className?: string
}

const WebIcon = ({ name, className }: IconProps) => {
  const myIcon = require(`./icons/${name}.svg`)

  return <SVG src={myIcon} className={classNames('icon', className)} />
}

export default WebIcon
