import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldValues, UseFormReturn } from 'react-hook-form'

import { Icon } from '@liveconnect/icons'
import { FormSelect } from '@liveconnect/components'
import { Loader, Tooltip } from '@liveconnect/communities-ui'

import FormActions from '../FormActions'
import { Localization } from '../../core/localizations/types'

import './styles.scss'

interface FormLocalActionsLangProps<Z extends FieldValues> {
  isSelectDisabled?: boolean
  isLoading: boolean
  methods: UseFormReturn<Z>
  selectName?: string
  localizations: Localization[]
  defaultLanguage: string
  onCancel?: () => void
  onSubmit: (values: Z) => Promise<void>
  onBeforeChange?: () => Promise<boolean>
}

export const FormLocalActionsLang = <T extends FieldValues>({
  isSelectDisabled,
  isLoading,
  methods,
  selectName = 'language',
  localizations,
  defaultLanguage,
  onCancel,
  onSubmit,
  onBeforeChange,
}: FormLocalActionsLangProps<T>) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods

  const localizationsOptions = useMemo(() => {
    return localizations.map((item) => ({
      label: `${t(`localizations.${item.twoLetterISOLanguageName}`)} ${
        item.twoLetterISOLanguageName == defaultLanguage
          ? `(${t(`common.changeLanguage.defaultLanguage`)})`
          : ''
      } `,
      value: item.twoLetterISOLanguageName,
    }))
  }, [localizations])

  const renderLanguageSelector = () => {
    return (
      <>
        <FormSelect
          control={control}
          name={selectName}
          label=""
          options={localizationsOptions}
          disabled={isSelectDisabled}
          noOptionsMessage={t('select.empty')}
          menuPlacement="top"
          isClearable={false}
          onBeforeChange={onBeforeChange}
        />
        <Tooltip content={t('common.changeLanguage.tooltip')} className="ms-2">
          <Icon name="info_outline" tabIndex={0} />
        </Tooltip>
      </>
    )
  }

  return (
    <div className="FormLocalActionsLang">
      <FormActions languageSelector={renderLanguageSelector()}>
        {onCancel && (
          <button
            className="btn btn-outline-primary"
            disabled={isLoading}
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
        )}
        <button
          className="btn btn-primary"
          disabled={!isValid || isLoading || !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          {isLoading ? <Loader /> : t('common.save')}
        </button>
      </FormActions>
    </div>
  )
}
