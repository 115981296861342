import { useState } from 'react'
import useFetch from '../../utils/fetch/useFetch'
import { UseActionsResult, Action } from './types'

const useActions = (): UseActionsResult => {
  const { get } = useFetch()
  const [actions, setActions] = useState<Action[]>([])

  const endpoint = 'actions'

  const fetchActions = async (application?: string) => {
    const response: Action[] | undefined = await get({
      endpoint,
      params: { application },
    })
    if (response) {
      setActions(response)
    }
  }

  return {
    fetchActions,
    actions,
  }
}

export default useActions
