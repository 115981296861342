import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { TabsInternal, TabInternal } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import LCProfile from './Profile'
import { Main } from '../../../components/Main'
import useLiveconnect from '../../../core/liveconnect/useLiveconnect'
import LCTermsCons from './TermsCons'
import LCTPrivacy from './PrivacyPolicy'
import LCApps from './Apps'

import './styles.scss'

const LCDetail: FC = () => {
  const { t } = useTranslation()
  const { id: userId } = useParams()
  const { fetchDetail } = useLiveconnect()
  const navigate = useNavigate()
  const [tabs, setTabs] = useState<TabInternal[]>([])

  useEffect(() => {
    userId && fetchDetail(userId)
  }, [userId])

  useEffect(() => {
    setTabs([
      {
        id: 'profile',
        label: t('liveconnect.detail.tabs.profile'),
        component: <LCProfile />,
      },
      {
        id: 'termscons',
        label: t('liveconnect.detail.tabs.termscons'),
        component: <LCTermsCons />,
      },
      {
        id: 'privacy',
        label: t('liveconnect.detail.tabs.privacy'),
        component: <LCTPrivacy />,
      },
      {
        id: 'apps',
        label: t('liveconnect.detail.tabs.apps'),
        component: <LCApps />,
      },
    ])
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="LCDetail">
      <div className="LCDetail__header d-flex justify-content-between">
        <h1 className="h3">{t('liveconnect.detail.title')}</h1>
        <button className="btn btn-link" onClick={() => navigate('..')}>
          <Icon name="chevron_left" />
          {t('liveconnect.detail.navigation')}
        </button>
      </div>
      <p>{t('liveconnect.detail.hint')}</p>
      <TabsInternal tabs={tabs} />
    </Main>
  )
}

export default LCDetail
