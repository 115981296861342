// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TCHistoryModal__header{font-weight:bold;border:1px solid #dcdce3}.TCHistoryModal__header.row{margin-bottom:0px}.TCHistoryModal__header__col{padding:12px;border-right:1px solid #dcdce3}.TCHistoryModal__header__col:last-child,.TCHistoryModal__header__col:first-child{border:none}.TCHistoryModal__row{border:1px solid #dcdce3;border-top:none}.TCHistoryModal__col{height:48px;display:flex;align-items:center}.TCHistoryModal__col .lc-checkbox-control{margin-top:0}.TCHistoryModal__col .lc-form-control-wrapper{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/app/pages/TermsCons/Versions/HistoryModal/styles.scss"],"names":[],"mappings":"AASE,wBACE,gBAAA,CACA,wBAAA,CACA,4BACE,iBAAA,CAEF,6BACE,YAAA,CACA,8BAAA,CACA,iFAEE,WAAA,CAIN,qBACE,wBAAA,CACA,eAAA,CAEF,qBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,0CACE,YAAA,CAEF,8CACE,eAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.TCHistoryModal {\n  &__header {\n    font-weight: bold;\n    border: 1px solid $neutral-50;\n    &.row {\n      margin-bottom: 0px;\n    }\n    &__col {\n      padding: 12px;\n      border-right: 1px solid $neutral-50;\n      &:last-child,\n      &:first-child {\n        border: none;\n      }\n    }\n  }\n  &__row {\n    border: 1px solid $neutral-50;\n    border-top: none;\n  }\n  &__col {\n    height: 48px;\n    display: flex;\n    align-items: center;\n    .lc-checkbox-control {\n      margin-top: 0;\n    }\n    .lc-form-control-wrapper {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TCHistoryModal__header": `TCHistoryModal__header`,
	"row": `row`,
	"TCHistoryModal__header__col": `TCHistoryModal__header__col`,
	"TCHistoryModal__row": `TCHistoryModal__row`,
	"TCHistoryModal__col": `TCHistoryModal__col`,
	"lc-checkbox-control": `lc-checkbox-control`,
	"lc-form-control-wrapper": `lc-form-control-wrapper`
};
export default ___CSS_LOADER_EXPORT___;
