import { Icon } from '@liveconnect/icons'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import ContentTable from '../../../../components/ContentTable'
import {
  LiveconnectUserDetail,
  LiveConnectUserSignedItem,
} from '../../../../core/liveconnect/types'
import useLiveconnect from '../../../../core/liveconnect/useLiveconnect'
import useCulture from '../../../../utils/culture'

import './styles.scss'

const LCTermsCons = () => {
  const { t } = useTranslation()
  const { detail } = useLiveconnect()
  const { formatDate } = useCulture()

  const { termsConditionsSigned, lastTermConditionVersionSigned } =
    detail as LiveconnectUserDetail

  const columns: ColumnDef<LiveConnectUserSignedItem>[] = useMemo(
    () => [
      {
        accessorKey: 'code',
        header: t('liveconnect.detail.termscons.header.code'),
      },
      {
        accessorKey: 'signDate',
        header: t('liveconnect.detail.termscons.header.date'),
        cell: ({ row }) =>
          formatDate(new Date(row.original.signDate) as Date, 'LONG_DATE_TIME'),
      },
    ],
    []
  )

  return (
    <div className="LCTermsCons">
      <h4>{t('liveconnect.detail.termscons.title')}</h4>
      <p>{t('liveconnect.detail.termscons.hint')}</p>
      {!lastTermConditionVersionSigned && (
        <div className="LCTermsCons__error">
          <div className="d-flex align-items-center">
            <div className="LCTermsCons__error__icon">
              <Icon name="report_problem" />
            </div>
            <Trans
              i18nKey="liveconnect.detail.termscons.error"
              components={{ 1: <b /> }}
            />
          </div>
        </div>
      )}
      <ContentTable
        title={t('liveconnect.detail.termscons.table.title')}
        emptyText={t('liveconnect.detail.termscons.table.empty')}
        columns={columns}
        data={termsConditionsSigned}
      />
    </div>
  )
}

export default LCTermsCons
