import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, LogoImage, SidePanel } from '@liveconnect/components'

import appLogo from '../../../../../../assets/images/app-logo.png'

import './index.scss'

const ThemeHeader: FC = () => {
  const { t } = useTranslation()

  const [burger, setBurger] = useState<boolean>(false)
  const [avatar, setAvatar] = useState<boolean>(false)

  const currentUser = {
    name: 'John',
    surname: 'Smith',
    avatarUrl: '',
  }

  useEffect(() => {
    setAvatar(true)
  }, [])

  return (
    <div className="theme-header">
      <Header className="header">
        <Header.Left>
          <Header.BurgerButton
            active={burger}
            title={t('tenantDesign.theme.header.burger')}
            onClick={() => setBurger((prevState: boolean) => !prevState)}
          />

          <Header.Logo>
            <LogoImage src={appLogo} alt="" />
          </Header.Logo>
        </Header.Left>

        <Header.Right>
          <Header.Menu>
            <Header.MenuItem>
              <a
                title={t('tenantDesign.theme.header.messages')}
                className="hover"
              >
                <Header.MenuIcon icon="mode_comment" dot={true} />
              </a>
            </Header.MenuItem>

            <Header.MenuItem>
              <a title={t('tenantDesign.theme.header.notifications')}>
                <Header.MenuIcon icon="notifications" dot={false} />
              </a>
            </Header.MenuItem>
          </Header.Menu>

          <Header.AvatarButton
            active={avatar}
            onClick={() => {}}
            avatar={{
              name: currentUser.name,
              surname: currentUser.surname,
              imageUrl: currentUser.avatarUrl,
              alt: t('tenantDesign.theme.header.avatar'),
            }}
          />
        </Header.Right>
      </Header>
      <SidePanel opened={avatar} onClose={() => {}}>
        <SidePanel.Menu>
          <SidePanel.MenuItem>
            <a href="">{t('tenantDesign.theme.header.account')}</a>
          </SidePanel.MenuItem>

          <SidePanel.MenuItem>
            <a href="" className="active">
              {t('tenantDesign.theme.header.agenda')}
            </a>
          </SidePanel.MenuItem>
          <SidePanel.MenuItem>
            <a href="">{t('tenantDesign.theme.header.interests')}</a>
          </SidePanel.MenuItem>
          <SidePanel.MenuItem>
            <a href="">{t('tenantDesign.theme.header.exhibitor')}</a>
          </SidePanel.MenuItem>
          <SidePanel.MenuItem>
            <a href="">{t('tenantDesign.theme.header.map')}</a>
          </SidePanel.MenuItem>
          <SidePanel.MenuItem>
            <a href="">{t('tenantDesign.theme.header.logout')}</a>
          </SidePanel.MenuItem>
        </SidePanel.Menu>
      </SidePanel>
    </div>
  )
}

export default ThemeHeader
