import { FC, useEffect, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { Icon } from '@liveconnect/icons'
import { FormProvider, FormSelect, Option } from '@liveconnect/components'

import SlidePanel from '../../../../../components/SlidePanel'
import useReceivedMessages from '../../../../../core/receivedMessages/useReceivedMessages'

import './styles.scss'

interface FilterPanelProps {
  isOpen: boolean
  onClose: () => void
}

interface FilterPanelForm {
  statusSelected: string
  serviceSelected: string
}

const FilterPanel: FC<FilterPanelProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const filterPanelWrapper = useRef<HTMLDivElement>(
    document.createElement('div')
  )
  const {
    serviceList,
    messageStatusList,
    statusSelected,
    serviceSelected,
    setFilters,
  } = useReceivedMessages()

  const methods = useForm<FilterPanelForm>({
    mode: 'onChange',
    defaultValues: {
      statusSelected,
      serviceSelected,
    },
  })
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods

  const messageStatusOptions = useMemo(
    () =>
      messageStatusList.map((item) => ({
        value: item,
        label: t(`dashboard.messageStatus.received.${item.toLowerCase()}`),
      })),
    [messageStatusList]
  )

  const serviceOptions: Option<string>[] = useMemo(() => {
    const allServices = {
      value: '',
      label: t('dashboard.all'),
    }
    const list = serviceList.map((item) => ({
      value: item,
      label: item,
    }))
    return [allServices, ...list]
  }, [serviceList])

  const handleReset = () => {
    reset({ statusSelected: 'Success', serviceSelected: '' })
  }

  const onSubmit = async (values: FilterPanelForm) => {
    setFilters(values)
    onClose()
  }

  useEffect(() => {
    const filterPanelElement = document.getElementById('left-panel')
    filterPanelElement?.appendChild(filterPanelWrapper.current)
  }, [])

  return ReactDOM.createPortal(
    <div className="FilterPanel">
      <SlidePanel alignment="left" opened={isOpen} close={onClose}>
        <div className="FilterPanel__wrapper">
          <div className="FilterPanel__header d-flex justify-content-between align-items-center">
            <div className="FilterPanel__title">{t('filter.panel.title')}</div>
            <button className="non-styled-btn" onClick={onClose}>
              <Icon name="close" />
            </button>
          </div>
          <FormProvider methods={methods}>
            <div className="FilterPanel__form">
              <FormSelect
                control={control}
                name="statusSelected"
                label={t(
                  'dashboard.receivedMessages.selectMessageStatus.label'
                )}
                options={messageStatusOptions}
                noOptionsMessage=""
                isClearable={false}
                placeholder={t(
                  'dashboard.receivedMessages.selectMessageStatus.placeholder'
                )}
              />
              <FormSelect
                control={control}
                name="serviceSelected"
                label={t('dashboard.receivedMessages.selectService.label')}
                options={serviceOptions}
                noOptionsMessage=""
                isClearable={false}
                placeholder={t(
                  'dashboard.receivedMessages.selectService.placeholder'
                )}
              />
            </div>
            <div className="FilterPanel__actions">
              <button
                className="btn btn-outline-primary"
                disabled={!isValid}
                onClick={() => handleReset()}
              >
                {t('filter.panel.reset')}
              </button>
              <button
                className="btn btn-primary"
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
              >
                {t('filter.panel.apply')}
              </button>
            </div>
          </FormProvider>
        </div>
      </SlidePanel>
    </div>,
    filterPanelWrapper.current
  )
}

export default FilterPanel
