import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabsInternal } from '@liveconnect/components'

import { Main } from '../../components/Main'
import TermsConsVersions from './Versions'
import Form from './Form'

const TermsConsScreen: FC = () => {
  const { t } = useTranslation()
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs([
      {
        id: 'form',
        label: t('termsCons.tabs.form'),
        component: <Form />,
      },
      {
        id: 'versions',
        label: t('termsCons.tabs.versions'),
        component: <TermsConsVersions />,
      },
    ])
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="TermsConsScreen">
      <h1 className="h3">{t('termsCons.form.title')}</h1>
      <TabsInternal tabs={tabs} />
    </Main>
  )
}

export default TermsConsScreen
