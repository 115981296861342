import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  PrivacyPoliciesVersion,
  UsePrivacyPoliciesVersionsResult,
} from './types'
import { setCurrent, setList } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const usePrivacyPoliciesVersions = (): UsePrivacyPoliciesVersionsResult => {
  const dispatch = useAppDispatch()
  const { current, list, isLoaded } = useAppSelector(
    (state) => state.privacyPoliciesVersions
  )
  const { get, post } = useFetch()
  const { notifyApiError } = useError()

  const endpoint = 'privacypolicyversions'
  const errorModel = 'privacypolicyversions'

  const fetchPrivacyPolicies = async () => {
    const response: PrivacyPoliciesVersion[] | undefined = await get({
      endpoint,
    })
    response && dispatch(setList(response))
  }

  const createPrivacyPoliciesVersion = async () => {
    try {
      const response: PrivacyPoliciesVersion | undefined = await post({
        endpoint: `${endpoint}/create`,
        body: {},
      })
      response && dispatch(setCurrent(response))
    } catch (e) {
      notifyApiError(e, errorModel, 'create')
    }
  }

  const fetchCurrent = async () => {
    const response: PrivacyPoliciesVersion | undefined = await get({
      endpoint: `${endpoint}/current`,
    })
    response && dispatch(setCurrent(response))
  }

  return {
    current,
    list,
    isLoaded,
    fetchPrivacyPolicies,
    fetchCurrent,
    createPrivacyPoliciesVersion,
  }
}

export default usePrivacyPoliciesVersions
