import { FC, useEffect, useMemo, useState } from 'react'
import { SelectControl } from '@liveconnect/components'
import { useTranslation } from 'react-i18next'
import { ColumnDef, Row } from '@tanstack/react-table'

import { Main } from '../../components/Main'
import { Application } from '../../core/applications/types'
import useApplications from '../../core/applications/useApplications'
import { Profile } from '../../core/profiles/types'
import useProfiles from '../../core/profiles/useProfiles'
import useUi from '../../core/ui/useUi'
import useNotifications from '../../utils/notifications/useNotifications'
import ProfileForm from './Form'
import ContentTable from '../../components/ContentTable'

const ProfilesScreen: FC = () => {
  const { t } = useTranslation()
  const { applications, fetchApplications } = useApplications()
  const [application, setApplication] = useState<Application>()
  const notify = useNotifications()
  const { list, deleteProfile, fetchProfiles, changeIsDefault, clearProfiles } =
    useProfiles()
  const { showConfirmation } = useUi()
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [edittingProfileId, setEdittingProfileId] = useState<string>('')
  const [parentProfileId, setParentProfileId] = useState<string>('')

  const handleDelete = async (id: string) => {
    try {
      await deleteProfile(id)
      notify.success(t('profiles.delete.successFeedback'))
      fetchProfiles({ application: application?.clientId })
    } catch (e) {}
  }

  const handleConfirmDelete = (id: string) => {
    showConfirmation({
      title: t('profiles.delete.modal.title'),
      subtitle: t('profiles.delete.modal.subtitle'),
      iconName: 'report_problem',
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        handleDelete(id)
      },
    })
  }

  const handleChangeDefault = async (id: string, isDefault: boolean) => {
    try {
      await changeIsDefault(id, isDefault)
      notify.success(t('profiles.default.successFeedback'))
      fetchProfiles({ application: application?.clientId })
    } catch (e) {}
  }

  const handleConfirmDefault = (id: string, isDefault: boolean) => {
    showConfirmation({
      title: t('profiles.default.modal.title'),
      subtitle: t('profiles.default.modal.subtitle'),
      iconName: 'report_problem',
      confirmText: t('common.yes'),
      cancelText: t('common.no'),
      onConfirm: () => {
        handleChangeDefault(id, isDefault)
      },
    })
  }

  const handleEditProfile = (id: string) => {
    setEdittingProfileId(id)
    setShowCreateModal(true)
  }

  const handleCreateChild = (id: string) => {
    setParentProfileId(id)
    setShowCreateModal(true)
  }

  const actions = (row: Profile) => {
    return [
      {
        onClick: () => handleEditProfile(row.id),
        label: t('common.edit'),
        icon: 'edit',
      },
      {
        onClick: () => handleConfirmDelete(row.id),
        label: t('profiles.list.table.action.delete'),
        icon: 'delete_outline',
        isHidden: row.isDefault,
      },
      {
        onClick: () => handleConfirmDefault(row.id, !row.isDefault),
        label: t('profiles.list.table.action.default'),
        icon: 'edit', // Hay que pedir icono a UI/UX
        isHidden: row.isDefault || !!row.parentProfileId,
      },
      {
        onClick: () => handleCreateChild(row.id),
        label: t('profiles.list.table.action.createChild'),
        icon: 'edit', // Hay que pedir icono a UI/UX
        isHidden: !!row.parentProfileId,
      },
    ]
  }

  const columns: ColumnDef<Profile>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('profiles.list.table.name'),
      },
      {
        accessorKey: 'description',
        minSize: 300,
        header: t('profiles.list.table.description'),
      },
      {
        accessorKey: 'isDefault',
        header: t('profiles.list.table.isDefault'),
        cell: ({ row }: { row: Row<Profile> }) => {
          return (
            <span>{t(`common.${row.original.isDefault ? 'yes' : 'no'}`)}</span>
          )
        },
      },
    ],
    []
  )

  const applicationOptions = useMemo(
    () =>
      applications.map((item) => ({
        value: item.clientId,
        label: t(`applications.label.${item.name}`),
      })),
    [applications]
  )

  const handleChangeApplication = (value: string) => {
    const app = applications.find((item) => item.clientId === value)
    setApplication(app)
  }

  const handleCloseModal = () => {
    setEdittingProfileId('')
    setParentProfileId('')
    setShowCreateModal(false)
  }

  useEffect(() => {
    application && fetchProfiles({ application: application.clientId })
  }, [application])

  useEffect(() => {
    fetchApplications()
    return clearProfiles
  }, [])

  return (
    <>
      <Main ariaLabelledby="profile-list-title" className="Profiles">
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="user-list-title" className="h3">
              {t('profiles.title')}
            </h1>
            <p>{t('profiles.hint')}</p>
          </div>
          <button
            className="btn btn-primary h-100"
            disabled={!application}
            onClick={() => setShowCreateModal(true)}
          >
            {t('profiles.add')}
          </button>
        </div>
        <div className="Profiles__select">
          <SelectControl
            name="app"
            onChange={handleChangeApplication}
            label={t('profiles.selectApp.label')}
            options={applicationOptions}
            required={true}
            value={application?.clientId as string}
            noOptionsMessage=""
            isClearable={false}
            placeholder={t('profiles.selectApp.placeholder')}
          />
        </div>
        <section className="mt-4">
          {list.length > 0 && (
            <div className="Profiles__list">
              <ContentTable
                title={t('profiles.table.title')}
                columns={columns}
                data={list}
                actions={actions}
                isExpandable={true}
              />
            </div>
          )}
        </section>
      </Main>
      {showCreateModal && (
        <ProfileForm
          onClose={handleCloseModal}
          edittingProfileId={edittingProfileId}
          parentProfileId={parentProfileId}
          application={application as Application}
        />
      )}
    </>
  )
}

export default ProfilesScreen
