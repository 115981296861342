// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header .lc-header__section--right:before{background-color:#171736 !important}.Header .lc-header__avatar-btn:hover{background-color:#292152 !important}`, "",{"version":3,"sources":["webpack://./src/app/containers/Header/styles.scss"],"names":[],"mappings":"AAUI,0CACE,mCAAA,CAEF,qCACE,mCAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.Header {\n  .lc-header {\n    &__section--right:before {\n      background-color: #171736 !important;\n    }\n    &__avatar-btn:hover {\n      background-color: #292152 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `Header`,
	"lc-header__section--right": `lc-header__section--right`,
	"lc-header__avatar-btn": `lc-header__avatar-btn`
};
export default ___CSS_LOADER_EXPORT___;
