import { FC } from 'react'
import { FormSelect } from '@liveconnect/components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DropdownFormProps } from './type'

import './styles.scss'

const DropdownContainer: FC = () => {
  const { t } = useTranslation()

  const methods = useForm<DropdownFormProps>({
    mode: 'onChange',
    defaultValues: {
      dropdown: '',
    },
  })

  const { control } = methods

  return (
    <div className="dropdown-design-container">
      <div className="dropdown-design-container__dropdown">
        <FormSelect
          control={control}
          name="dropdown"
          label={t('tenantDesign.dropdown.label')}
          placeholder={t('tenantDesign.dropdown.placeholder')}
          options={[]}
          menuPlacement="bottom"
          isClearable={true}
          onBeforeChange={() => true}
          disabled={true}
        />
      </div>
    </div>
  )
}

export default DropdownContainer
