import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import LiveconnectScreen from '../pages/Liveconnect'
import LCDetail from '../pages/Liveconnect/Detail'

const LiveconnectRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LiveconnectScreen />} />
      <Route path="/:id" element={<LCDetail />} />
    </Routes>
  )
}

export default LiveconnectRouter
