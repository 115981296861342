import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../reduxProvider'
import useFetch from '../../utils/fetch/useFetch'
import useNotifications from '../../utils/notifications/useNotifications'

import { setList } from './store/listState'
import { setEmailsTypes } from './store/typesState'
import { setEmailsStates } from './store/statesState'
import { setSummary } from './store/summaryState'
import { hideLoader, showLoader } from './store/loaderState'

import {
  EmailDetails,
  EmailsDashboardList,
  EmailsDashboardListFilters,
  EmailsSelector,
  EmailsSummary,
  FetchEmailsProps,
  useEmailsDashboardResult,
} from './types'
import { setListFilters } from './store/filtersState'

const useEmailsDashboard = (): useEmailsDashboardResult => {
  const { get, put } = useFetch()
  const notify = useNotifications()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    emailsList,
    emailsListFilters,
    emailsTypes,
    emailsStates,
    emailsSummary,
  } = useAppSelector((state) => state.emailsDashboard)

  const endpoint = 'emails'

  const setFilters = (filters: EmailsDashboardListFilters) => {
    dispatch(setListFilters(filters))
  }

  const fetchEmails = async ({
    types,
    states,
    search,
    page,
    pageSize,
  }: FetchEmailsProps): Promise<EmailsDashboardList | undefined> => {
    dispatch(showLoader())
    try {
      const response: EmailsDashboardList | undefined = await get({
        endpoint,
        params: {
          types,
          states,
          search,
          page,
          pageSize,
        },
      })
      response && dispatch(setList(response))
      dispatch(hideLoader())
      return response
    } catch (e) {
      dispatch(hideLoader())
      notify.error(t('controlPanel.emails.notifications.error.list'))
    }
  }

  const fetchEmailDetails = async (
    id: string
  ): Promise<EmailDetails | undefined> => {
    try {
      const response: EmailDetails | undefined = await get({
        endpoint: `${endpoint}/${id}`,
      })
      return response
    } catch (e) {
      notify.error(t('controlPanel.emails.notifications.error.details'))
    }
  }

  const fetchEmailsTypes = async (): Promise<EmailsSelector[] | undefined> => {
    try {
      const response: EmailsSelector[] | undefined = await get({
        endpoint: `${endpoint}/types`,
      })
      response && dispatch(setEmailsTypes({ items: response }))
      return response
    } catch {
      notify.error(t('controlPanel.emails.notifications.error.types'))
    }
  }

  const fetchEmailsStates = async (): Promise<EmailsSelector[] | undefined> => {
    try {
      const response: EmailsSelector[] | undefined = await get({
        endpoint: `${endpoint}/states`,
      })
      response && dispatch(setEmailsStates({ items: response }))
      return response
    } catch {
      notify.error(t('controlPanel.emails.notifications.error.states'))
    }
  }

  const fetchEmailsSummary = async (
    daysAgo?: number,
    type?: string
  ): Promise<EmailsSummary | undefined> => {
    try {
      const response: EmailsSummary | undefined = await get({
        endpoint: `${endpoint}/summary`,
        params: {
          type,
          daysAgo,
        },
      })
      response && dispatch(setSummary(response))
      return response
    } catch {
      notify.error(t('controlPanel.emails.notifications.error.summary'))
    }
  }

  const retryEmail = async (id: string): Promise<void> => {
    dispatch(showLoader())
    try {
      dispatch(hideLoader())
      notify.success(t('controlPanel.emails.notifications.success.retry'))
      return await put({ endpoint: `${endpoint}/${id}`, body: { emailId: id } })
    } catch {
      dispatch(hideLoader())
      notify.error(t('controlPanel.emails.notifications.error.retry'))
    }
  }

  return {
    emailsList,
    emailsListFilters,
    emailsTypes,
    emailsStates,
    emailsSummary,
    setFilters,
    fetchEmails,
    fetchEmailDetails,
    fetchEmailsTypes,
    fetchEmailsStates,
    fetchEmailsSummary,
    retryEmail,
  }
}

export default useEmailsDashboard
