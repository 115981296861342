import { Icon } from '@liveconnect/icons'
import classNames from 'classnames'
import { useRef, DragEvent } from 'react'
import { ControllerRenderProps, FieldError, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface DragAndDropComponentProps<Z> {
  field: ControllerRenderProps<Z, Path<Z>>
  removeOptionsVisible: boolean
  error?: FieldError
  onImageError?: () => void
  handleRemove: () => void
  canUploadVideo: () => boolean
}

const DragAndDropComponent = function <T>({
  field,
  removeOptionsVisible,
  error,
  onImageError,
  handleRemove,
  canUploadVideo,
}: DragAndDropComponentProps<T>): JSX.Element {
  const { t } = useTranslation()
  const fileContainerRef = useRef<HTMLInputElement>(null)

  const dragEnterHandler = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault()
    fileContainerRef.current?.classList.add(
      'input-file__droppable--dragging-over'
    )
  }

  const dragLeaveHandler = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault()
    fileContainerRef.current?.classList.remove(
      'input-file__droppable--dragging-over'
    )
  }

  return (
    <div
      className="input-file__droppable"
      ref={fileContainerRef}
      onDragEnter={dragEnterHandler}
      onDragLeave={dragLeaveHandler}
      onDragOver={(ev) => ev.preventDefault()}
    >
      {field.value ? (
        <div className="input-file__preview">
          {field.value && (
            <img
              onError={onImageError}
              src={field.value as string}
              alt={field.name}
            />
          )}
        </div>
      ) : (
        <div
          className={classNames('input-file__not-uploaded', {
            'input-file--invalid': error,
          })}
        >
          <div onDragEnter={dragEnterHandler}>
            {canUploadVideo() && (
              <Icon
                name="slideshow"
                className={classNames(
                  'input-file__icon input-file__icon--placeholder ms-2',
                  { 'input-file__icon--invalid': error }
                )}
              />
            )}
          </div>

          <p
            className={classNames('not-uploaded__text', {
              'not-uploaded__text--invalid': error,
            })}
            onDragEnter={dragEnterHandler}
          >
            {t('file.control.description')}
          </p>
        </div>
      )}

      <div
        className={classNames('input-file__overlay', {
          'border-danger': error,
        })}
      >
        {field.value && removeOptionsVisible && (
          <button
            type="button"
            className="input-file__remove"
            data-dismiss="modal"
            aria-label="close"
            onClick={handleRemove}
          >
            <Icon className="input-file__icon" name="delete_outline" />
          </button>
        )}
        {error && (
          <small className="input-file__error tlc-form-control-group__error">
            {error.message}
          </small>
        )}
      </div>
    </div>
  )
}

export default DragAndDropComponent
