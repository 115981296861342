import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TermsCons, TermsConsDetail, TermsConsState } from './types'

const initialState: TermsConsState = {
  current: null,
  list: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'termsCons',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<TermsCons[]>) => {
      state.list = action.payload
    },
    setCurrent: (state, action: PayloadAction<TermsConsDetail>) => {
      state.current = action.payload
    },
  },
})

export const { setCurrent, showLoader, hideLoader, setList } = slice.actions

export default slice.reducer
