import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorPage } from '@liveconnect/components'
import { Link } from 'react-router-dom'

const AccessDenied: FC = () => {
  const { t } = useTranslation()

  return (
    <ErrorPage className="access-denied">
      <>
        <ErrorPage.Icon name="report_problem" />

        <ErrorPage.Title text={t('pageError.accessDenied.title')} />
        <ErrorPage.Body text={t('pageError.accessDenied.body')} />
        <ErrorPage.Actions>
          <Link to="/logout">
            <button className="btn btn-primary">
              {t('header.menu.logout')}
            </button>
          </Link>
        </ErrorPage.Actions>
      </>
    </ErrorPage>
  )
}

export default AccessDenied
