import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PermissionsListPayload, PermissionsState } from './types'

const initialState: PermissionsState = {
  list: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    clearList: () => initialState,
    setList: (state, action: PayloadAction<PermissionsListPayload>) => {
      state.list = action.payload.list
      state.isLoaded = true
    },
  },
})

export const { clearList, setList } = slice.actions

export default slice.reducer
