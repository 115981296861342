import { useAppDispatch, useAppSelector } from '../reduxProvider'
import { UseUiResult } from './types'
import {
  showPanel,
  hidePanel,
  showConfirmation,
  hideConfirmation,
  showError,
  hideError,
} from './store'

const useUi = (): UseUiResult => {
  const dispatch = useAppDispatch()
  const { isPanelVisible, confirmation, error } = useAppSelector(
    (state) => state.ui
  )

  const toggleShowPanel = () => {
    isPanelVisible ? dispatch(hidePanel()) : dispatch(showPanel())
  }

  return {
    isPanelVisible,
    confirmation,
    error,
    toggleShowPanel,
    showConfirmation: (data) => dispatch(showConfirmation(data)),
    hideConfirmation: () => dispatch(hideConfirmation()),
    showError: (data) => dispatch(showError(data)),
    hideError: () => dispatch(hideError()),
  }
}

export default useUi
