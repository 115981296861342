import { FC, useEffect, useState } from 'react'
import { useTheme } from '@liveconnect/components'
import { useTranslation } from 'react-i18next'

import TenantDesignHeader from '../Header'
import { Main } from '../../../components/Main'
import TagsMenu from './Menu'
import TagsContainer from './Container'
import TagsConfiguration from './Configuration'
import FormActions from '../../../components/FormActions'

import useTenantDesign from '../../../core/tenantDesign/useTenantDesign'
import useUi from '../../../core/ui/useUi'

import { Color } from '../types'

import './styles.scss'

const TagsDesign: FC = () => {
  const labelledby = 'tags-design'
  const { t } = useTranslation()
  const { getDesigns } = useTenantDesign()
  const { changeThemeVars } = useTheme()
  const { showConfirmation } = useUi()

  const [colors, setColorsList] = useState<Record<string, string>>()
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [isFormCancelled, setIsFormCancelled] = useState<boolean>(false)
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)

  const getComponentDesign = async () => {
    const response = await getDesigns('tags')
    const responseRecord = arrayToRecord(response ?? [])
    setColorsList(responseRecord)
    changeThemeVars(responseRecord)
  }

  const arrayToRecord = (array: Color[]): Record<string, string> =>
    array.reduce(
      (accumulator, currentValue) => (
        (accumulator[currentValue['key']] = currentValue['value']), accumulator
      ),
      {}
    )

  useEffect(() => {
    showCancelModal &&
      showConfirmation({
        title: t('tenantDesign.modal.cancel.title'),
        subtitle: t('tenantDesign.modal.cancel.body'),
        confirmText: t('common.cancel'),
        cancelText: t('common.close'),
        onConfirm: () => {
          setIsFormCancelled(true)
          setShowCancelModal(false)
        },
        onCancel: () => {
          setShowCancelModal(false)
        },
      })
  }, [showCancelModal])

  useEffect(() => {
    getComponentDesign()
  }, [])

  return (
    <Main ariaLabelledby={labelledby} className="tags-design">
      <div className="tags-design sticky-container">
        <TenantDesignHeader labelledby={labelledby} />
        <div className="tags-design__body row">
          <div className="tags-design__body__menu col-2">
            <TagsMenu />
          </div>
          <div className="tags-design__body__container col-8">
            <TagsContainer />
          </div>
          <div className="tags-design__body__configuration col-2">
            {colors && (
              <TagsConfiguration
                colors={colors}
                triggerCancel={() => isFormCancelled}
                formCancelled={() => setIsFormCancelled(false)}
                triggerSubmit={() => isFormSubmitted}
                formSubmitted={() => setIsFormSubmitted(false)}
                formDirty={(isDirty) => setIsFormDirty(isDirty)}
              />
            )}
          </div>
        </div>
      </div>
      <FormActions>
        <button
          className="btn btn-outline-primary"
          onClick={() => isFormDirty && setShowCancelModal(true)}
        >
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          onClick={() => setIsFormSubmitted(true)}
          disabled={!isFormDirty}
        >
          {t('common.save')}
        </button>
      </FormActions>
    </Main>
  )
}

export default TagsDesign
