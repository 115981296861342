import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormProvider,
  FormTextInput,
  FormTextarea,
  FormSelect,
  TextControl,
} from '@liveconnect/components'

import useNotifications from '../../../utils/notifications/useNotifications'
import {
  CreateProfileAction,
  ProfileDetail,
  ProfileFormType,
} from '../../../core/profiles/types'
import { buildValidationSchema } from './validations'
import useProfiles from '../../../core/profiles/useProfiles'
import { Application } from '../../../core/applications/types'
import useActions from '../../../core/actions/useActions'
import { Action } from '../../../core/actions/types'
import useUi from '../../../core/ui/useUi'

import './styles.scss'

interface ProfileFormProps {
  onClose: () => void
  application: Application
  edittingProfileId: string
  parentProfileId: string
}

const ProfileForm: FC<ProfileFormProps> = ({
  onClose,
  application,
  edittingProfileId,
  parentProfileId,
}) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [parentProfile, setParentProfile] = useState<ProfileDetail>()
  const {
    createProfile,
    updateProfile,
    fetchProfiles,
    getDetail,
    fetchDetail,
    clearProfileDetail,
    detail: edittingProfile,
  } = useProfiles()
  const { fetchActions, actions } = useActions()

  const methods = useForm<ProfileFormType>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: edittingProfile
      ? { name: edittingProfile.name, description: edittingProfile.description }
      : {
          name: '',
          description: '',
        },
  })
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = methods

  const parseActions = (actions: {
    [key: string]: string | boolean
  }): CreateProfileAction[] => {
    const filteredActions = Object.keys(actions).filter(
      (id) => actions[id] !== 'inherited'
    )
    return filteredActions.map((id) => ({
      id,
      isActive: actions[id] === 'active' || false,
    }))
  }

  const selectOptions = (action: Action) => {
    const options = [
      { value: 'active', label: t('profile.create.modal.actions.active') },
      { value: 'inactive', label: t('profile.create.modal.actions.inactive') },
    ]
    if (parentProfile) {
      const parentAction = parentProfile.actions.find(
        (item) => item.id === action.id
      )
      const parentLabel = parentAction?.isActive
        ? t('profile.create.modal.actions.active')
        : t('profile.create.modal.actions.inactive')
      options.unshift({
        value: 'inherited',
        label: t('profile.create.modal.actions.inherit', { parentLabel }),
      })
    }
    return options
  }

  const fetchParentProfile = async () => {
    const response = await getDetail(parentProfileId)
    setParentProfile(response)
  }

  const handleCancel = () => {
    if (!isDirty) onClose()
    else {
      showConfirmation({
        title: t('common.warning'),
        subtitle: t('modal.cancel.generic.subtitle'),
        text: t('modal.cancel.generic.text'),
        confirmText: t('common.yes'),
        cancelText: t('common.no'),
        iconName: 'report_problem',
        onConfirm: onClose,
      })
    }
  }

  const onSubmit = async (data: ProfileFormType) => {
    setIsSubmitting(true)
    try {
      const parsedActions = data.actions ? parseActions(data.actions) : []
      if (parentProfileId) data.parentProfileId = parentProfileId
      if (edittingProfile) {
        await updateProfile({
          ...edittingProfile,
          ...data,
          actions: parsedActions,
        })
      } else {
        await createProfile({
          ...data,
          application: application.clientId,
          actions: parsedActions,
        })
      }
      await fetchProfiles({ application: application.clientId })
      notify.success(t('profile.create.modal.successFeedback'))
      onClose()
    } catch (e) {
      onClose()
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (edittingProfile?.parentProfile)
      setParentProfile(edittingProfile?.parentProfile)
  }, [edittingProfile])

  useEffect(() => {
    const actionsToObject: { [key: string]: string } = {}
    actions.map((item) => {
      const editAction = (edittingProfile?.actions || []).find(
        (action) => action.id === item.id
      )
      if ((parentProfileId || edittingProfile?.parentProfile) && !editAction) {
        actionsToObject[item.id] = 'inherited'
      } else {
        actionsToObject[item.id] = editAction?.isActive ? 'active' : 'inactive'
      }
    })

    reset({
      name: edittingProfile?.name || '',
      description: edittingProfile?.description || '',
      actions: actionsToObject,
    })
  }, [actions, edittingProfile])

  useEffect(() => {
    if (!edittingProfileId) return
    fetchDetail(edittingProfileId)
  }, [edittingProfileId])

  useEffect(() => {
    parentProfileId && fetchParentProfile()
  }, [parentProfileId])

  useEffect(() => {
    fetchActions(application.clientId)
  }, [application])

  useEffect(() => {
    return () => {
      clearProfileDetail()
    }
  }, [])

  return (
    <Modal
      show={true}
      onHide={handleCancel}
      size="lg"
      backdrop="static"
      className="ProfileForm"
      data-keyboard="false"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {parentProfile
            ? `${t(
                `profile.${
                  edittingProfile ? 'edit' : 'create'
                }.child.modal.title`
              )} ${parentProfile.name}`
            : t(`profile.${edittingProfile ? 'edit' : 'create'}.modal.title`)}
        </Modal.Title>
      </Modal.Header>
      <FormProvider methods={methods}>
        <Modal.Body>
          <div className="d-flex ProfileForm__legend">
            <div className="bd-highlight">{t('common.required')}</div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextControl
                name="app"
                label={t('profile.create.modal.app.label')}
                type="text"
                value={t(`applications.label.${application.name}`)}
                disabled={true}
                onChange={() => false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormTextInput
                control={control}
                name="name"
                label={t('profile.create.modal.name.label')}
                placeholder={t('profile.create.modal.name.placeholder')}
                type="text"
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormTextarea
                control={control}
                name="description"
                label={t('profile.create.modal.description.label')}
                placeholder={t('profile.create.modal.description.placeholder')}
                required={true}
                rows={3}
              />
            </div>
          </div>
          {actions.length > 0 && (
            <div className="row">
              <div className="col-12 ProfileForm__actions">
                <div className="ProfileForm__actions__headers">
                  <div>{t('profile.create.modal.actions.header.name')}</div>
                </div>
                {actions.map((item) => (
                  <div key={item.id} className="ProfileForm__actions__item row">
                    <div className="col-8">
                      {t(`applications.actions.${item.code}`)}
                    </div>
                    <div className="col-4">
                      <FormSelect
                        control={control}
                        name={`actions.${item.id}` as keyof ProfileFormType}
                        label=""
                        options={selectOptions(item)}
                        isClearable={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={handleCancel}>
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
          >
            {t('common.save')}
          </button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

export default ProfileForm
