import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnDef } from '@tanstack/react-table'
import { useNavigate } from 'react-router'
import { CheckboxControl } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import useReceivedMessages from '../../../../core/receivedMessages/useReceivedMessages'
import { ReceivedMessages } from '../../../../core/receivedMessages/types'
import { Main } from '../../../../components/Main'
import useCulture from '../../../../utils/culture'
import DetailModal from './DetailModal'
import useNotifications from '../../../../utils/notifications/useNotifications'
import ContentTable from '../../../../components/ContentTable'
import FilterPanel from './FilterPanel'

import './styles.scss'

const ReceivedMessagesScreen: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { formatDate } = useCulture()
  const navigate = useNavigate()

  const [selectedMessage, setSelectedMessage] = useState<string[]>([])
  const [showDetail, setShowDetail] = useState<ReceivedMessages | null>(null)
  const [showFilters, setShowFilters] = useState<boolean>(false)

  const {
    list,
    isLoaded,
    statusSelected,
    serviceSelected,
    fetchList,
    clearMessageListStore,
    fetchMessageStatusList,
    fetchServiceList,
    reprocessMessages,
  } = useReceivedMessages()

  // Table - Start

  const handleReceivedMessageSelected = (key: string) => {
    const index = selectedMessage.indexOf(key)
    if (index !== -1) {
      selectedMessage.splice(index, 1)
    } else {
      selectedMessage.push(key)
    }
    setSelectedMessage([...selectedMessage])
  }

  const renderCheckboxCell = useCallback(
    (row: ReceivedMessages) => {
      if (!row || !row.id) return ''
      return (
        <div className="ReceivedMessages__checkbox__cell">
          <CheckboxControl
            id={row.key}
            value={selectedMessage.includes(row.key)}
            name={row.key}
            onChange={() => handleReceivedMessageSelected(row.key)}
            label=""
            hiddenLabel={true}
          />
        </div>
      )
    },
    [handleReceivedMessageSelected, selectedMessage]
  )

  const renderKeyCell = (row: ReceivedMessages) => {
    if (!row || !row.id) return ''
    return (
      <div className="ReceivedMessages__id" onClick={() => setShowDetail(row)}>
        <div className="ReceivedMessages__id__cell">{row.id}</div>
      </div>
    )
  }

  const renderDateTimeCell = (dateTime: string) => {
    if (!dateTime) return ''
    return formatDate(new Date(dateTime as string) as Date, 'LONG_DATE_TIME')
  }

  const columns: ColumnDef<ReceivedMessages>[] = useMemo(
    () => [
      {
        accessorKey: 'isSelected',
        header: '',
        cell: ({ row }) => renderCheckboxCell(row.original),
      },
      {
        accessorKey: 'key',
        header: t('dashboard.receivedMessages.list.table.id'),
        cell: ({ row }) => renderKeyCell(row.original),
      },
      {
        accessorKey: 'eventType',
        header: t('dashboard.receivedMessages.list.table.eventType'),
      },
      {
        accessorKey: 'retries',
        header: t('dashboard.receivedMessages.list.table.retries'),
      },
      {
        accessorKey: 'createdAt',
        header: t('dashboard.receivedMessages.list.table.createdAt'),
        cell: ({ row }) => renderDateTimeCell(row.original.createdAt),
      },
      {
        accessorKey: 'expires',
        header: t('dashboard.receivedMessages.list.table.expires'),
        cell: ({ row }) => renderDateTimeCell(row.original.expires),
      },
    ],
    [renderCheckboxCell, renderDateTimeCell, t]
  )

  // Table - END
  const handleProcessMessage = async () => {
    try {
      await reprocessMessages(selectedMessage)
      notify.success(t('dashboard.receivedMessages.reprocess.successFeedback'))
    } catch (e) {
    } finally {
      setSelectedMessage([])
    }
  }

  useEffect(() => {
    if (statusSelected) {
      fetchList({
        messageStatusType: statusSelected,
        serviceName: serviceSelected,
      })
    }
  }, [statusSelected, serviceSelected])

  useEffect(() => {
    fetchMessageStatusList()
    fetchServiceList()
    return () => {
      clearMessageListStore()
    }
  }, [])

  return (
    <>
      <Main ariaLabelledby="user-list-title" className="ReceivedMessages">
        <div className="d-flex justify-content-between align-items-end">
          <div>
            <h1 id="user-list-title" className="h3">
              {t('dashboard.receivedMessages')}
            </h1>
          </div>
          <div className="d-flex flex-column">
            <div>
              <button
                className="btn btn-link btn-ternary"
                onClick={() => navigate('..')}
              >
                <Icon name="chevron_left" />
                {t('dashboard.details.goback')}
              </button>
            </div>
            <button
              className="btn btn-primary btn-reprocess mb-3"
              onClick={() => handleProcessMessage()}
              disabled={!selectedMessage.some((x) => !!x)}
            >
              {t('dashboard.receivedMessages.reprocess')}
            </button>
          </div>
        </div>

        <div className="ReceivedMessages__list__searchs"></div>
        <section className="mt-3">
          <div className="ReceivedMessages__list">
            <ContentTable
              title={t('dashboard.receivedMessages.list.table.title')}
              columns={columns}
              data={list}
              emptyText={t('dashboard.receivedMessages.emptyList')}
              isLoaded={isLoaded}
              onFilterClick={() => setShowFilters(true)}
              filterLabel={t('common.filters')}
              isFilterActive={true}
            />
          </div>
        </section>
      </Main>
      <FilterPanel isOpen={showFilters} onClose={() => setShowFilters(false)} />
      {showDetail && (
        <DetailModal
          onClose={() => setShowDetail(null)}
          receivedMessage={showDetail}
        />
      )}
    </>
  )
}

export default ReceivedMessagesScreen
