import { createSlice } from '@reduxjs/toolkit'

import { Confirmation, ErrorState, UiState } from './types'

const initialConfirmation: Confirmation = {
  isVisible: false,
  title: '',
  subtitle: '',
  text: '',
  confirmText: '',
  cancelText: '',
  hideCancel: false,
  onConfirm: null,
  onCancel: null,
}

const initialError: ErrorState = {
  isVisible: false,
  type: '',
}

const initialState: UiState = {
  isPanelVisible: false,
  error: initialError,
  confirmation: initialConfirmation,
}

export const UiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showPanel: (state) => {
      state.isPanelVisible = true
    },
    hidePanel: (state) => {
      state.isPanelVisible = false
    },
    showConfirmation: (state, action) => {
      state.confirmation = { ...action.payload, isVisible: true }
    },
    hideConfirmation: (state) => {
      state.confirmation = initialConfirmation
    },
    showError: (state, action) => {
      state.error.type = action.payload
      state.error.isVisible = true
    },
    hideError: (state) => {
      state.error.type = ''
      state.error.isVisible = false
    },
  },
})

export const {
  showPanel,
  hidePanel,
  showConfirmation,
  hideConfirmation,
  showError,
  hideError,
} = UiSlice.actions

export default UiSlice.reducer
