import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { TenantConfig, UseTenantConfigResult } from './types'
import { setConfig } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useSettings from '../settings/useSettings'

const useTenantConfig = (): UseTenantConfigResult => {
  const dispatch = useAppDispatch()
  const { tenantConfig } = useAppSelector((state) => state.tenantConfig)
  const { settings } = useSettings()
  const { get } = useFetch()

  const endpoint = 'tenant-config'

  const fetchTenantConfig = async () => {
    const response: TenantConfig | undefined = await get({
      endpoint,
    })
    response && dispatch(setConfig(response))
  }

  const fetchPublicTenantConfig = async () => {
    const tenantId = window.location.pathname.split('/').at(2)
    if (tenantId && settings?.bff?.url) {
      const response: TenantConfig | undefined = await get({
        endpoint: `${endpoint}/public/${tenantId}`,
        isPublic: true,
      })
      response && dispatch(setConfig(response))
    }
  }

  return {
    tenantConfig,
    fetchTenantConfig,
    fetchPublicTenantConfig,
  }
}

export default useTenantConfig
