// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailsDashBoard__header__subtitle{margin-bottom:32px}.EmailsDashBoard__body__graph{margin-bottom:56px}`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/Emails/styles.scss"],"names":[],"mappings":"AAUI,mCACE,kBAAA,CAIF,8BACE,kBAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.EmailsDashBoard {\n  &__header{\n    &__subtitle {\n      margin-bottom: 32px;\n    }\n  }\n  &__body {\n    &__graph {\n      margin-bottom: 56px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EmailsDashBoard__header__subtitle": `EmailsDashBoard__header__subtitle`,
	"EmailsDashBoard__body__graph": `EmailsDashBoard__body__graph`
};
export default ___CSS_LOADER_EXPORT___;
