import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TermsConsVersion, TermsConsVersionsState } from './types'

const initialState: TermsConsVersionsState = {
  current: null,
  list: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'termsConsVersions',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<TermsConsVersion[]>) => {
      state.list = action.payload
    },
    setCurrent: (state, action: PayloadAction<TermsConsVersion>) => {
      state.current = action.payload
    },
  },
})

export const { setCurrent, showLoader, hideLoader, setList } = slice.actions

export default slice.reducer
