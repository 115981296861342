import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormProvider,
  FormTextInput,
  FormMultiSelectTree,
} from '@liveconnect/components'
import debounce from 'lodash.debounce'

import useNotifications from '../../../utils/notifications/useNotifications'
import useFindUserByEmail from '../../../core/shared/useFindUserByEmail'
import useProfiles from '../../../core/profiles/useProfiles'
import useCommunity from '../../../core/community/useCommunity'

import { buildValidationSchema } from './validations'

import { FetchError } from '../../../utils/fetch/types'
import { FindUserResponse } from '../../../core/shared/types'
import {
  CommunityUser,
  CreateCommunityUserType,
} from '../../../core/community/types'
import { Profile, ProfileKeyValueList } from '../../../core/profiles/types'
import useUi from '../../../core/ui/useUi'

import './styles.scss'

interface CreateCommunityUserProps {
  user?: CommunityUser
  onClose: () => void
}

interface Option {
  value: string
  label: string
}

// Revisar de donde sacar esta información
const APPLICATION_ID = 'CommunitiesAdmin'

const CreateCommunityUser: FC<CreateCommunityUserProps> = ({
  user,
  onClose,
}) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { createUser, updateUser, fetchList } = useCommunity()
  const { findUserByEmail } = useFindUserByEmail()
  const { getProfilesKeyValueList } = useProfiles()
  const { showConfirmation } = useUi()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [profileOptions, setProfileOptions] = useState<Option[]>([])
  const [isReadonly, setIsReadonly] = useState<boolean>(false)

  const methods = useForm<CreateCommunityUserType>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: user
      ? {
          email: user.email,
          name: user.name,
          surname: user.surname,
          profiles: user.profiles.map((item: Profile) => item.id),
        }
      : {
          email: '',
          name: '',
          surname: '',
          profiles: [],
        },
  })
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    getFieldState,
    watch,
    trigger,
    formState: { isValid, isDirty },
  } = methods

  const initProfiles = async () => {
    const profiles = await getProfilesKeyValueList({
      application: APPLICATION_ID,
    })
    !user && setDefaultProfile(profiles)
    initProfileOptions(profiles)
  }

  const setDefaultProfile = (profiles: ProfileKeyValueList[] | undefined) => {
    const defaultProfile = (profiles || []).find(
      (item: ProfileKeyValueList) => item.isDefault
    )
    defaultProfile && setValue('profiles', [defaultProfile.id])
  }

  const initProfileOptions = (profiles: ProfileKeyValueList[] | undefined) => {
    const parsedProfiles = (profiles || []).map(
      (item: ProfileKeyValueList) => ({
        label: item.name,
        value: item.id,
      })
    )
    setProfileOptions(parsedProfiles)
  }

  const handleCancel = () => {
    if (!isDirty) onClose()
    else {
      showConfirmation({
        title: t('common.warning'),
        subtitle: t('modal.cancel.generic.subtitle'),
        text: t('modal.cancel.generic.text'),
        confirmText: t('common.yes'),
        cancelText: t('common.no'),
        iconName: 'report_problem',
        onConfirm: onClose,
      })
    }
  }

  const onSubmit = async (newCommunityUser: CreateCommunityUserType) => {
    setIsSubmitting(true)
    try {
      if (user) {
        await updateUser(user.id, newCommunityUser.profiles)
      } else {
        await createUser(newCommunityUser)
      }
      await fetchList()
      notify.success(t('communityUser.create.modal.successFeedback'))
      onClose()
    } catch (error: unknown) {
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        setError('email', {
          type: 'custom',
          message: t(`validations.ENTITY_CONFLICT`, {
            label: t(`communityUser.create.modal.email`).toLowerCase(),
          }),
        })
      } else {
        onClose()
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const fillFormData = (response?: FindUserResponse) => {
    setValue('name', response?.name || '')
    setValue('surname', response?.surname || '')
    setIsReadonly(response ? true : false)
    trigger()
  }

  const onSearch = useCallback(async () => {
    if (!!!getFieldState('email').error) {
      const email = watch('email')
      try {
        const response: FindUserResponse | undefined = await findUserByEmail(
          email
        )
        fillFormData(response)
      } catch (error: unknown) {
        const fetchError = error as FetchError
        if (fetchError.status === 404) {
          fillFormData()
        }
      }
    }
  }, [findUserByEmail, trigger, fillFormData])

  const debouncedOnSearch = useMemo(
    () => debounce(onSearch, 500),
    [onSearch, debounce]
  )

  useEffect(() => {
    initProfiles()
  }, [])

  return (
    <Modal
      show={true}
      onHide={handleCancel}
      backdrop="static"
      data-keyboard="false"
      size="lg"
      className="CreateCommunityUser"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t(`communityUser.${user ? 'edit' : 'create'}.modal.title`)}
        </Modal.Title>
      </Modal.Header>
      <FormProvider methods={methods}>
        <Modal.Body>
          <div className="d-flex CreateCommunityUser__legend">
            <div className="bd-highlight">{t('common.required')}</div>
          </div>
          <div className="mt-3">
            <h5>{t(`communityUser.create.modal.data.title`)}</h5>
          </div>
          <div className="row">
            <div className="col-12">
              <FormTextInput
                control={control}
                name="email"
                label={t('communityUser.create.modal.email')}
                placeholder={t('communityUser.create.modal.email.placeholder')}
                type="text"
                required={true}
                disabled={!!user}
                onChange={debouncedOnSearch}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <FormTextInput
                control={control}
                name="name"
                label={t('communityUser.create.modal.name')}
                placeholder={t('communityUser.create.modal.name.placeholder')}
                type="text"
                required={true}
                disabled={!!user || isReadonly}
              />
            </div>
            <div className="col-6">
              <FormTextInput
                control={control}
                name="surname"
                label={t('communityUser.create.modal.surname')}
                placeholder={t(
                  'communityUser.create.modal.surname.placeholder'
                )}
                type="text"
                disabled={!!user || isReadonly}
              />
            </div>
          </div>
          <div className="mt-3">
            <h5>{t(`communityUser.create.modal.profiles.title`)}</h5>
          </div>
          <div className="row">
            <div className="col-6">
              <FormMultiSelectTree
                control={control}
                name="profiles"
                placeholder={t(
                  'communityUser.create.modal.profiles.placeholder'
                )}
                options={profileOptions}
                label={t('communityUser.create.modal.profiles')}
                required={true}
                placeholderInfo={(number) =>
                  t('communityUser.create.modal.profiles.info', {
                    count: number,
                  })
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={handleCancel}>
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
          >
            {t(`common.${user ? 'save' : 'add'}`)}
          </button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

export default CreateCommunityUser
