import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    code: yup
      .string()
      .label(t('edition.create.modal.code'))
      .max(20, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 20 })
      )
      .matches(/^[A-Za-z0-9]+$/, t('edition.create.modal.code.regexError'))
      .required(({ label }) => t('validations.required', { label })),
    name: yup
      .string()
      .label(t('edition.create.modal.name'))
      .required(({ label }) => t('validations.required', { label })),
  })
}
