import { FC } from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'

import { Permission } from '../core/permissions/types'
import CommunityScreen from '../pages/Community'
import NotFound from '../pages/error/NotFound'
import NotAvailable from '../pages/error'
import UnauthorizedScreen from '../pages/error/Unauthorized'
import EventsScreen from '../pages/Events'
import TenantScreen from '../pages/Tenant'
import TokenScreen from '../pages/Token'
import PermissionRoute from '../containers/Restricted/RestrictedRoute'
import PrivateRoute from './PrivateRoute'
import TermsConsRouter from './TermsConsRouter'
import PrivacyPoliciesRouter from './PrivacyPoliciesRouter'
import ProfilesScreen from '../pages/Profiles'
import EventAdminsScreen from '../pages/EventAdmins'
import HomeScreen from '../pages/Home'
import LiveconnectRouter from './LiveconnectRouter'
import TenantUsersScreen from '../pages/TenantUsers'
import IdentityRouter from './IdentityRouter'
import TenantDesignRouter from './TenantDesignRouter'
import TenantEmailsTemplatesRouter from './TenantEmailsTemplatesRouter'
import IntegrationDashboardRouter from './IntegrationDashboardRouter'
import EmailsDashBoard from '../pages/Dashboard/Emails'
import LocalizationScreen from '../pages/Localization'
import useSettings from '../core/settings/useSettings'
import { Loader } from '@liveconnect/components'
import AccessDenied from '../pages/error/AccessDenied'

const TenantRouter: FC = () => {
  const { modulesStatus } = useSettings()

  if (!modulesStatus.isLoaded) return <Loader />

  if (!modulesStatus.modulesStatus.tenantStatus) return <NotAvailable />

  return (
    <Routes>
      <Route path="/access-denied" element={<AccessDenied />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Navigate to="home" />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route
          path="event-admins"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_EventAdmin]}
              element={<EventAdminsScreen />}
            />
          }
        />
        <Route
          path="identity/*"
          element={
            <PermissionRoute
              permissions={[
                Permission.TenantAdmin_Ids,
                Permission.TenantAdmin_IdsClients,
              ]}
              element={<IdentityRouter />}
            />
          }
        />
        <Route
          path="community"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_CommunityAdmin]}
              element={<CommunityScreen />}
            />
          }
        />
        <Route
          path="tenant-users"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_TenantAdminUsers]}
              element={<TenantUsersScreen />}
            />
          }
        />
        <Route
          path="events"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_EventEditionMaster]}
              element={<EventsScreen />}
            />
          }
        />
        <Route
          path="liveconnect/*"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_LiveconnectUser]}
              element={<LiveconnectRouter />}
            />
          }
        />
        <Route
          path="tenant"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_Tenant]}
              element={<TenantScreen />}
            />
          }
        />
        <Route
          path="tenant-design/*"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_ComponentDesign]}
              element={<TenantDesignRouter />}
            />
          }
        />
        <Route
          path="tenant-emails/*"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_EmailsTemplates]}
              element={<TenantEmailsTemplatesRouter />}
            />
          }
        />
        <Route
          path="termscons/*"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_TermsConditions]}
              element={<TermsConsRouter />}
            />
          }
        />
        <Route
          path="privacy-policies/*"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_PrivacyPolicy]}
              element={<PrivacyPoliciesRouter />}
            />
          }
        />
        <Route
          path="profiles"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_ProfileAdmin]}
              element={<ProfilesScreen />}
            />
          }
        />
        <Route
          path="integration/*"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_IntegrationDashboard]}
              element={<IntegrationDashboardRouter />}
            />
          }
        />
        <Route
          path="emails"
          element={
            <PermissionRoute
              permissions={[Permission.TenantAdmin_EmailDashboard]}
              element={<EmailsDashBoard />}
            />
          }
        />
        <Route path="localization" element={<LocalizationScreen />} />
        <Route path="unauthorized" element={<UnauthorizedScreen />} />
        <Route path="token" element={<TokenScreen />} />
        <Route path="/*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default TenantRouter
