import { FC, useEffect, useMemo } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import useHistoryGraph from '../../../../core/historyGraph/useHistoryGraph'
import { Loader } from '@liveconnect/communities-ui'
import { addHours } from 'date-fns/esm'
import useCulture from '../../../../utils/culture'
import { useTranslation } from 'react-i18next'
import { BLUE_LIGHT_COLOR, GREEN_COLOR, ORANGE_COLOR, RED_COLOR } from '..'

import './styles.scss'

const HistoryGraph: FC = () => {
  const { t } = useTranslation()
  const { fetchHistoryGraph, isLoaded, list } = useHistoryGraph()
  const { formatDate } = useCulture()

  useEffect(() => {
    const actualDateTime = addHours(new Date().setMinutes(0, 0, 0), 1)

    fetchHistoryGraph({
      intervalMinutes: 60,
      startDate: addHours(actualDateTime, -24).toISOString(),
      endDate: actualDateTime.toISOString(),
    })
  }, [])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )
  const options: ChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 10,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        ticks: {
          callback: (tickValue: number | string) => {
            const label = data.labels.at(+tickValue)
            const labelSplitted = label?.split(' ')[0]
            const labelBeforeSplitted = data.labels
              .at(+tickValue - 1)
              ?.split(' ')[0]

            if (tickValue === 0 || labelSplitted != labelBeforeSplitted) {
              return label
            } else {
              return label?.split(' ')[3]
            }
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  }

  const data = useMemo(() => {
    const labels = list.map((x) =>
      formatDate(new Date(x.date as string) as Date, 'LONG_DATE_TIME')
    )
    const data = {
      labels,
      datasets: [
        {
          label: t('dashboard.published'),
          data: list.map((x) => x.publishedSuccess),
          borderColor: BLUE_LIGHT_COLOR,
          backgroundColor: BLUE_LIGHT_COLOR,
        },
        {
          label: t('dashboard.historyGraph.publishedFailed'),
          data: list.map((x) => x.publishedFailed),
          borderColor: ORANGE_COLOR,
          backgroundColor: ORANGE_COLOR,
        },
        {
          label: t('dashboard.processed'),
          data: list.map((x) => x.receivedSuccess),
          borderColor: GREEN_COLOR,
          backgroundColor: GREEN_COLOR,
        },
        {
          label: t('dashboard.historyGraph.receivedFailed'),
          data: list.map((x) => x.receivedFailed),
          borderColor: RED_COLOR,
          backgroundColor: RED_COLOR,
        },
      ],
    }

    return data
  }, [list])

  const totals = useMemo(() => {
    return {
      totalPublishedSuccess: list.reduce(
        (previous, current) => previous + current.publishedSuccess,
        0
      ),
      totalPublishedFailed: list.reduce(
        (previous, current) => previous + current.publishedFailed,
        0
      ),
      totalReceivedSuccess: list.reduce(
        (previous, current) => previous + current.receivedSuccess,
        0
      ),
      totalReceivedFailed: list.reduce(
        (previous, current) => previous + current.receivedFailed,
        0
      ),
    }
  }, [list])

  const renderLegends = () => {
    return (
      <>
        <div className="HistoryGraph__legends justify-content-end">
          <div className="HistoryGraph__legends__types">
            <div className="HistoryGraph__legends__types__block">
              <div className="title">
                {t('dashboard.totalMessages')}{' '}
                <span>
                  {totals.totalPublishedFailed +
                    totals.totalPublishedSuccess +
                    totals.totalReceivedFailed +
                    totals.totalReceivedSuccess}
                </span>
              </div>
            </div>
            <div className="HistoryGraph__legends__types__block">
              <div className="title">
                {t('dashboard.emittedMessages.short')}
              </div>
              <div className="messagesTypes">
                <div className="tag">
                  <div
                    className="circle"
                    style={{ background: BLUE_LIGHT_COLOR }}
                  ></div>
                  {t('dashboard.published')}{' '}
                  <b>{totals.totalPublishedSuccess.toString()}</b>
                </div>
                <div className="tag">
                  <div
                    className="circle"
                    style={{ background: ORANGE_COLOR }}
                  ></div>
                  {t('dashboard.failed')}{' '}
                  <b>{totals.totalPublishedFailed.toString()}</b>
                </div>
              </div>
            </div>
            <div className="HistoryGraph__legends__types__block">
              <div className="title">
                {t('dashboard.receivedMessages.short')}
              </div>
              <div className="messagesTypes">
                <div className="tag">
                  <div
                    className="circle"
                    style={{ background: GREEN_COLOR }}
                  ></div>
                  {t('dashboard.processed')}{' '}
                  <b>{totals.totalReceivedSuccess.toString()}</b>
                </div>
                <div className="tag">
                  <div
                    className="circle"
                    style={{ background: RED_COLOR }}
                  ></div>
                  {t('dashboard.failed')}{' '}
                  <b>{totals.totalReceivedFailed.toString()}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {!isLoaded ? (
        <Loader />
      ) : (
        <div className="resume">
          {renderLegends()}
          <div className="HistoryGraph__graph">
            <Line options={options} data={data} />
          </div>
        </div>
      )}
    </>
  )
}

export default HistoryGraph
