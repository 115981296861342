import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckboxControl, Pagination, Tag } from '@liveconnect/components'
import { ColumnDef } from '@tanstack/react-table'
import { IconName } from '@liveconnect/icons'
import { debounce } from 'lodash'

import ContentTable, {
  TableRowAction,
} from '../../../../components/ContentTable'
import EmailsListFilter from './EmailListFilter'

import useCulture from '../../../../utils/culture'
import useEmailsDashboard from '../../../../core/emailsDashboard/useEmailsDashboard'
import useUi from '../../../../core/ui/useUi'
import { setListFilters } from '../../../../core/emailsDashboard/store/filtersState'

import {
  EmailDetails,
  EmailsDashboard,
  EmailsDashboardList,
} from '../../../../core/emailsDashboard/types'

import './styles.scss'
import { Modal } from 'react-bootstrap'
import ConfirmationModal from '../../../../containers/ConfirmationModal'
import { HtmlSanitizer } from '../../../../components/HtmlSanitizer'

interface EmailListProps {
  list: EmailsDashboardList
}

interface ItemStates {
  [key: string]: {
    type: 'warning' | 'success' | 'error'
    label: string
    icon: IconName
  }
}

const EmailsList: FC<EmailListProps> = ({ list }) => {
  const { t } = useTranslation()
  const { formatDate } = useCulture()
  const { fetchEmailDetails, retryEmail } = useEmailsDashboard()
  const { showConfirmation, hideConfirmation, isPanelVisible } = useUi()

  const [selectedMessage, setSelectedMessage] = useState<string[]>([])
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [emailDetails, setEmailDetails] = useState<EmailDetails | undefined>(
    undefined
  )
  const [showEmail, setShowEmail] = useState<boolean>(false)

  const handlePublishedMessageSelected = (id: string) => {
    const index = selectedMessage.indexOf(id)
    if (index !== -1) {
      selectedMessage.splice(index, 1)
    } else {
      selectedMessage.push(id)
    }
    setSelectedMessage([...selectedMessage])
  }

  const renderCheckboxCell = useCallback(
    (row: EmailsDashboard) => {
      if (!row || !row.id) return ''
      return (
        <div className="PublishedMessages__checkbox__cell">
          <CheckboxControl
            id={row.id}
            value={selectedMessage.includes(row.id)}
            name={row.id}
            onChange={() => handlePublishedMessageSelected(row.id)}
            label=""
            hiddenLabel={true}
          />
        </div>
      )
    },
    [handlePublishedMessageSelected, selectedMessage]
  )

  const renderDateTimeCell = (dateTime: string) => {
    if (!dateTime) return ''
    return formatDate(new Date(dateTime as string) as Date, 'LONG_DATE_TIME')
  }

  const itemStates: ItemStates = useMemo(() => {
    return {
      pending: {
        type: 'warning',
        label: t('controlPanel.emails.info.table.body.status.pending'),
        icon: 'info_outline',
      },
      send: {
        type: 'success',
        label: t('controlPanel.emails.info.table.body.status.success'),
        icon: 'check_circle_outline',
      },
      error: {
        type: 'error',
        label: t('controlPanel.emails.info.table.body.status.error'),
        icon: 'report_problem',
      },
    }
  }, [])

  const columns: ColumnDef<EmailsDashboard>[] = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: t('controlPanel.emails.info.table.header.email'),
      },
      {
        accessorKey: 'type',
        header: t('controlPanel.emails.info.table.header.type'),
        cell: ({ row }) =>
          t(`tenantEmailsTemplate.table.body.name.${row.original.type}`),
      },
      {
        accessorKey: 'language',
        header: t('controlPanel.emails.info.table.header.language'),
        cell: ({ row }) => (
          <Tag>{row.original.language.split('-')[0].toUpperCase()}</Tag>
        ),
      },
      {
        accessorKey: 'sendDate',
        header: t('controlPanel.emails.info.table.header.date'),
        cell: ({ row }) => renderDateTimeCell(row.original.sendDate),
      },
      {
        accessorKey: 'state',
        header: t('controlPanel.emails.info.table.header.state'),
        cell: ({ row }) => (
          <Tag
            type={itemStates[row.original.state.toLowerCase()]?.type}
            icon={itemStates[row.original.state.toLowerCase()]?.icon}
          >
            {itemStates[row.original.state.toLowerCase()]?.label}
          </Tag>
        ),
      },
    ],
    [renderCheckboxCell, renderDateTimeCell, t]
  )

  const handleViewEmails = async (id: string) => {
    const emailDetails = getEmailDetails(id)
    setEmailDetails(await emailDetails)
    setShowEmail(true)
  }

  const handleViewErrors = () => {
    showConfirmation({
      title: t('controlPanel.emails.info.modal.error.header.title'),
      subtitle: t('controlPanel.emails.info.modal.error.body.title'),
      confirmText: t('common.accept'),
      onConfirm: hideConfirmation,
      iconName: 'report_problem',
    })
  }

  const getActionsItems = (row: EmailsDashboard): TableRowAction[] => {
    if (row.state === 'error') {
      return [
        {
          onClick: () => handleViewEmails(row.id),
          label: 'Ver email',
          icon: '',
        },
        {
          onClick: async () => await retryEmail(row.id),
          label: 'Reenviar email',
          icon: '',
        },
        {
          onClick: handleViewErrors,
          label: 'Detalle fallo del envío',
          icon: '',
        },
      ]
    }
    return [
      {
        onClick: () => handleViewEmails(row.id),
        label: 'Ver email',
        icon: '',
      },
      {
        onClick: async () => await retryEmail(row.id),
        label: 'Reenviar email',
        icon: '',
      },
    ]
  }

  const getEmailDetails = useCallback(
    async (id: string) => await fetchEmailDetails(id),
    [fetchEmailDetails]
  )

  const { emailsListFilters, setFilters } = useEmailsDashboard()

  const onSearch = useCallback(
    async (searchValue: string) => {
      setFilters({ ...emailsListFilters, search: searchValue })
    },
    [setListFilters, setFilters, emailsListFilters]
  )

  const debouncedOnSearch = useMemo(
    () => debounce(onSearch, 500),
    [onSearch, debounce]
  )

  return (
    <div className="EmailsList">
      <h1 className="EmailsList__title h5">
        {t('controlPanel.emails.info.title')}
      </h1>
      <div className="EmailsList__data section">
        <ContentTable
          title={t('controlPanel.emails.info.table.title')}
          columns={columns}
          data={list.items}
          emptyText={t('controlPanel.emails.info.table.body.empty')}
          onFilterClick={() => setShowFilters(true)}
          filterLabel={t('common.filters')}
          isFilterActive={
            !!emailsListFilters?.states?.length ||
            !!emailsListFilters?.types?.length
          }
          actions={getActionsItems}
          onSearch={(s: string) => {
            debouncedOnSearch(s)
          }}
          searchPlaceholder={t('controlPanel.emails.info.search.placeholder')}
        />
        {list.availablePages > 0 && (
          <Pagination
            currentPage={(list?.page || 1) - 1}
            totalPages={list.availablePages}
            totalItems={list.availableItems}
            pageSize={list.pageSize}
            singlePageText={(count) => t('common.pager.singlePage', { count })}
            multiplePageText={(start, end, total) =>
              t('common.pager.multiplePage', { start, end, total })
            }
            onChange={(pageIndex: number) =>
              setFilters({
                ...emailsListFilters,
                page: pageIndex + 1,
              })
            }
          />
        )}
      </div>
      <EmailsListFilter
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
      />
      <Modal
        show={showEmail}
        onHide={() => setShowEmail(false)}
        size="lg"
        className="EmailsList__modal-view"
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>{emailDetails?.subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HtmlSanitizer html={emailDetails?.content as string} />
        </Modal.Body>
      </Modal>
      {isPanelVisible && <ConfirmationModal />}
    </div>
  )
}

export default EmailsList
