import { useEffect, FC } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'
import Layout from '../containers/Layout'

import useAuth, { postLogoutRedirectPathKey } from '../core/auth/useAuth'
import useLocalizations from '../core/localizations/useLocalizations'
import useMe from '../core/me/useMe'
import usePermissions from '../core/permissions/usePermissions'
import useTenantConfig from '../core/tenantConfig/useTenantConfig'
import { useCustomRouter } from '../utils/extractParams'

const PrivateRoute: FC = () => {
  const navigate = useNavigate()
  const { login, logout, loaded, isLoggedIn, user } = useAuth()
  const { isLoaded: hasPermissionsLoaded, fetchList: fetchPermissions } =
    usePermissions()
  const { fetchLocalizations } = useLocalizations()
  const { fetchMe } = useMe()
  const { fetchTenantConfig } = useTenantConfig()
  const { basePath } = useCustomRouter()
  const { tenantId } = useParams()

  const initPermissions = async () => {
    try {
      await fetchPermissions()
      await fetchLocalizations()
    } catch (e) {
      navigate(`${basePath}/access-denied`)
    }
  }

  useEffect(() => {
    if (!isLoggedIn) {
      login()
    }
    if (
      user &&
      !hasPermissionsLoaded &&
      !(user && tenantId && user.profile.tenant_id !== tenantId)
    ) {
      initPermissions()
      fetchMe()
      fetchTenantConfig()
    }
  }, [loaded, isLoggedIn, login, hasPermissionsLoaded, user])

  if (user && tenantId && user.profile.tenant_id !== tenantId) {
    sessionStorage.setItem(postLogoutRedirectPathKey, `/t/${tenantId}`)
    logout()
  }

  return isLoggedIn && hasPermissionsLoaded ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : null
}

export default PrivateRoute
