import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorPage } from '@liveconnect/components'

const Unauthorized: FC = () => {
  const { t } = useTranslation()

  return (
    <ErrorPage className="access-denied">
      <>
        <ErrorPage.Icon name="admin_panel_settings" />

        <ErrorPage.Title text={t('pageError.unauthorized.title')} />
        <ErrorPage.Body text={t('pageError.unauthorized.body')} />
      </>
    </ErrorPage>
  )
}

export default Unauthorized
