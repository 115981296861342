import useFetch from '../../utils/fetch/useFetch'
import { ImageResult } from './types'

const useUploadImage = () => {
  const { post } = useFetch()

  const uploadImage = async (endpoint: string, image: string) => {
    const form = new FormData()
    const blob = await fetch(image).then((r) => r.blob())
    form.set('imageFile', blob)
    const result: ImageResult | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: form,
      isMultipart: true,
    })
    return result?.uri ?? ''
  }

  return {
    uploadImage,
  }
}

export default useUploadImage
