import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EmailsDashboardList } from '../types'

const initialState: EmailsDashboardList = {
  page: 1,
  pageSize: 20,
  availablePages: 0,
  availableItems: 0,
  items: [],
}

export const emailsListSlice = createSlice({
  name: 'emailsList',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<EmailsDashboardList>) => {
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.availablePages = action.payload.availablePages
      state.availableItems = action.payload.availableItems
      state.items = action.payload.items
    },
  },
})

export const { setList } = emailsListSlice.actions

export default emailsListSlice.reducer
