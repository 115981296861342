import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Domain, DomainsState } from './types'

const initialState: DomainsState = {
  list: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearList: () => initialState,
    setList: (state, action: PayloadAction<Domain[]>) => {
      state.list = action.payload
    },
  },
})

export const { showLoader, hideLoader, clearList, setList } = slice.actions

export default slice.reducer
