import ReactDOM from 'react-dom'
import { Provider as StoreProvider } from 'react-redux'

import { App } from './app/App'
import { store } from './app/core/reduxProvider'

ReactDOM.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>,

  document.getElementById('root')
)
