import { Permission } from '../../core/permissions/types'
import { NavbarItem } from './types'

export const navbarItems: NavbarItem[] = [
  {
    key: 'identity',
    trnsKey: 'navMenu.identity',
    icon: 'menu_ids',
    exact: false,
    disabled: false,
    permissions: [
      Permission.TenantAdmin_Ids,
      Permission.TenantAdmin_IdsClients,
    ],
    submenu: [
      {
        key: 'identity',
        trnsKey: 'navMenu.identityUsers',
        path: '/identity/users',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_Ids],
      },
      {
        key: 'identityConfig',
        trnsKey: 'navMenu.identityConfig',
        path: '/identity/config',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_IdsClients],
      },
      {
        key: 'domainBlackList',
        trnsKey: 'navMenu.blackList',
        path: '/identity/domain-blacklist',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_IdsClients],
      },
    ],
  },
  {
    key: 'tenant',
    trnsKey: 'navMenu.tenant',
    icon: 'menu_tenant',
    exact: false,
    disabled: false,
    permissions: [
      Permission.TenantAdmin_Tenant,
      Permission.TenantAdmin_ProfileAdmin,
      Permission.TenantAdmin_TermsConditions,
      Permission.TenantAdmin_PrivacyPolicy,
      Permission.TenantAdmin_ComponentDesign,
      Permission.TenantAdmin_EmailsTemplates,
    ],
    submenu: [
      {
        key: 'tenant_data',
        trnsKey: 'navMenu.tenantData',
        path: '/tenant',
        exact: true,
        disabled: false,
        permissions: [Permission.TenantAdmin_Tenant],
      },
      {
        key: 'profiles',
        trnsKey: 'navMenu.profiles',
        path: '/profiles',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_ProfileAdmin],
      },
      {
        key: 'tenant_design',
        trnsKey: 'navMenu.tenantDesign',
        path: '/tenant-design',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_ComponentDesign],
      },
      {
        key: 'tenant_emails',
        trnsKey: 'navMenu.tenantEmails',
        path: '/tenant-emails',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_EmailsTemplates],
      },
      {
        key: 'termscons',
        trnsKey: 'navMenu.termsCons',
        path: '/termscons',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_TermsConditions],
      },
      {
        key: 'privacypolicies',
        trnsKey: 'navMenu.privacyPolicies',
        path: '/privacy-policies',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_PrivacyPolicy],
      },
    ],
  },
  {
    key: 'admins',
    trnsKey: 'navMenu.adminManager',
    icon: 'menu_admin',
    exact: false,
    disabled: false,
    permissions: [
      Permission.TenantAdmin_EventAdmin,
      Permission.TenantAdmin_CommunityAdmin,
      Permission.TenantAdmin_TenantAdminUsers,
    ],
    submenu: [
      {
        key: 'eventAdmins',
        trnsKey: 'navMenu.eventAdmins',
        path: '/event-admins',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_EventAdmin],
      },
      {
        key: 'community',
        trnsKey: 'navMenu.communities',
        path: '/community',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_CommunityAdmin],
      },
      {
        key: 'tenantUsers',
        trnsKey: 'navMenu.tenantUsers',
        path: '/tenant-users',
        exact: true,
        disabled: false,
        permissions: [Permission.TenantAdmin_TenantAdminUsers],
      },
    ],
  },

  {
    key: 'liveconnect',
    trnsKey: 'navMenu.liveconnect',
    icon: 'menu_liveconnect',
    exact: false,
    disabled: false,
    permissions: [
      Permission.TenantAdmin_LiveconnectUser,
      Permission.TenantAdmin_EventEditionMaster,
    ],
    submenu: [
      {
        key: 'events',
        trnsKey: 'navMenu.events',
        path: '/events',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_EventEditionMaster],
      },
      {
        key: 'liveconnect',
        trnsKey: 'navMenu.liveconnectUsers',
        path: '/liveconnect',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_LiveconnectUser],
      },
    ],
  },
  {
    key: 'control-panel',
    trnsKey: 'navMenu.controlPanel',
    icon: 'menu_dashboard',
    exact: false,
    disabled: false,
    permissions: [
      Permission.TenantAdmin_IntegrationDashboard,
      Permission.TenantAdmin_EmailDashboard,
    ],
    submenu: [
      {
        key: 'integration',
        trnsKey: 'navMenu.controlPanel.integration',
        path: '/integration',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_IntegrationDashboard],
      },
      {
        key: 'emails',
        trnsKey: 'navMenu.controlPanel.emails',
        path: '/emails',
        exact: false,
        disabled: false,
        permissions: [Permission.TenantAdmin_EmailDashboard],
      },
    ],
  },
]
