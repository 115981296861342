import { configureStore } from '@reduxjs/toolkit'

import auth from '../auth/store'
import ui from '../ui/store'
import settings from '../settings/store'
import identity from '../identity/store'
import community from '../community/store'
import events from '../events/store'
import eventAdmins from '../eventAdmins/store'
import liveconnect from '../liveconnect/store'
import permissions from '../permissions/store'
import tenant from '../tenant/store'
import localizations from '../localizations/store'
import termsCons from '../termsCons/store'
import termsConsVersions from '../termsConsVersions/store'
import privacyPolicies from '../privacyPolicies/store'
import privacyPoliciesVersions from '../privacyPoliciesVersions/store'
import profiles from '../profiles/store'
import publishedMessages from '../publishedMessages/store'
import receivedMessages from '../receivedMessages/store'
import historyGraph from '../historyGraph/store'
import tenantUsers from '../tenantUsers/store'
import domains from '../domains/store'
import emailsDashboard from '../emailsDashboard/store'
import me from '../me/store'
import tenantConfig from '../tenantConfig/store'

export const store = configureStore({
  reducer: {
    auth,
    ui,
    settings,
    identity,
    eventAdmins,
    community,
    events,
    liveconnect,
    permissions,
    tenant,
    localizations,
    termsCons,
    termsConsVersions,
    privacyPolicies,
    privacyPoliciesVersions,
    profiles,
    historyGraph,
    publishedMessages,
    receivedMessages,
    tenantUsers,
    domains,
    emailsDashboard,
    me,
    tenantConfig,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export * from './hooks'
