import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseLiveconnectResult,
  LiveconnectRequestParams,
  LiveconnectListResponse,
  LiveconnectUserDetail,
  LiveConnectUserProfile,
} from './types'
import { setList, clearList, showLoader, hideLoader, setDetail } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useUploadImage from '../shared/useUploadImage'

const useLiveconnect = (): UseLiveconnectResult => {
  const dispatch = useAppDispatch()
  const {
    detail,
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
  } = useAppSelector((state) => state.liveconnect)
  const { get, put, del } = useFetch()
  const { uploadImage } = useUploadImage()

  const endpoint = 'liveconnect-users'

  const parseDetailToProfile = (
    detail: LiveconnectUserDetail
  ): LiveConnectUserProfile => ({
    id: detail.id,
    email: detail.email,
    name: detail.name,
    surname: detail.surname,
    imageUrl: detail.imageUrl ?? '',
    dateOfBirth: detail.dateOfBirth,
    description: detail.description ?? '',
    documentType: detail.documentType,
    identificationDocument: detail.identificationDocument,
    hasCometchatAccount: detail.hasCometchatAccount,
    numberOfContacts: detail.numberOfContacts,
    positions: detail.positions,
  })

  const parseParams = (requestParams?: LiveconnectRequestParams) => {
    const params: LiveconnectRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
    }
    return params
  }

  const getList = async (params?: LiveconnectRequestParams) => {
    const response: LiveconnectListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const fetchList = async (params?: LiveconnectRequestParams) => {
    dispatch(showLoader())
    const reqParams = parseParams(params)
    const response: LiveconnectListResponse | undefined = await getList(
      reqParams
    )
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const fetchDetail = async (id: string) => {
    dispatch(showLoader())
    const response: LiveconnectUserDetail | undefined = await get({
      endpoint: `${endpoint}/${id}`,
    })
    response && dispatch(setDetail(response))
    dispatch(hideLoader())
  }

  const updateUser = async (data: LiveConnectUserProfile) => {
    dispatch(showLoader())

    if (data.dateOfBirth)
      data.dateOfBirth = new Date(data.dateOfBirth).toISOString()

    const response: LiveconnectUserDetail | undefined = await put({
      endpoint,
      id: data.id,
      body: data,
    })
    response && dispatch(setDetail({ ...detail, ...response }))
    dispatch(hideLoader())
  }

  const deleteUser = async (id: string) => {
    await del({ endpoint, id })
  }

  return {
    detail,
    list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    parseDetailToProfile,
    fetchList,
    clearList: () => dispatch(clearList()),
    fetchDetail,
    updateUser,
    uploadImage: (image) => uploadImage(endpoint, image),
    deleteUser,
  }
}

export default useLiveconnect
