import { FC } from 'react'
import { useNavigate } from 'react-router'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import useDesignConstants from '../../hooks'

import './styles.scss'

const DropdownMenu: FC = () => {
  const { menuItems } = useDesignConstants()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleNavigation = (navigateTo: string) => navigate(navigateTo)

  return (
    <div className="dropdown-design-menu">
      <div className="dropdown-design-menu__title">
        {t('tenantDesign.menu.title')}
      </div>
      <hr className="dropdown-design-menu__separator" />
      <div className="dropdown-design-menu__menu-options">
        {menuItems &&
          menuItems.map((menuItem) => (
            <div
              key={menuItem.index}
              className={classNames(
                'dropdown-design-menu__menu-options__option',
                menuItem.value === 'dropdown' &&
                  'dropdown-design-menu__menu-options__option active'
              )}
              onClick={() => handleNavigation(`../${menuItem.value}`)}
            >
              {menuItem.name}
            </div>
          ))}
      </div>
    </div>
  )
}

export default DropdownMenu
