import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormProvider,
  FormToggle,
  Option,
  FormTextInput,
  SelectControl,
  FormRichText,
} from '@liveconnect/components'

import { buildValidationSchema } from './validations'
import { Main } from '../../components/Main'
import { IdentityClientProperties } from '../../core/identityClients/types'
import useNotifications from '../../utils/notifications/useNotifications'
import UploadControl from '../../components/forms/UploadControl'
import useIdentityClients from '../../core/identityClients/useIdentityClients'
import useLocalizations from '../../core/localizations/useLocalizations'
import { Localization } from '../../core/localizations/types'
import { FormLocalActionsLang } from '../../components/FormLocalActionsLang'
import useUi from '../../core/ui/useUi'

import './styles.scss'

const IdentityClientConfig: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { getLocalizations } = useLocalizations()
  const { showConfirmation } = useUi()
  const {
    getClientList,
    getClientProperties,
    setClientProperties,
    uploadImage,
  } = useIdentityClients()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedClient, setSelectedClient] = useState<string>()
  const [clientOptions, setClientOptions] = useState<Option<string>[]>([])
  const [clientPropertiesList, setClientPropertiesList] = useState<
    IdentityClientProperties[]
  >([])
  const [localLocalizations, setLocalLocalizations] = useState<Localization[]>(
    []
  )

  const methods = useForm<IdentityClientProperties>({
    mode: 'onChange',
    defaultValues: {
      useFederatedLogin: false,
      description: '',
    },
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    control,
    reset,
    watch,
    setValue,
    formState: { dirtyFields },
  } = methods

  const currentLang = watch('language')

  const defaultLanguage = useMemo(() => {
    return (
      localLocalizations.find((item) => item.isDefault)
        ?.twoLetterISOLanguageName || ''
    )
  }, [localLocalizations])

  const defaultProperties = useMemo(() => {
    return clientPropertiesList.find(
      (item) => item.language === defaultLanguage
    )
  }, [clientPropertiesList])

  const initLocalizations = async () => {
    const localizations = await getLocalizations('identity')
    localizations && setLocalLocalizations(localizations)
  }

  const initClients = async () => {
    const clients = await getClientList()
    const options = (clients || []).map((item) => ({
      value: item.id.toString(),
      label: item.name,
    }))
    setClientOptions(options)
  }

  const initProperties = async () => {
    const properties = await getClientProperties(
      selectedClient as string,
      defaultLanguage
    )
    if (!properties) return
    setClientPropertiesList([properties])
    reset(properties)
  }

  const setNewLangProperties = async () => {
    const index = clientPropertiesList.findIndex(
      (item) => item.language === currentLang
    )
    const properties = await getClientProperties(
      selectedClient as string,
      currentLang
    )
    if (!properties) return
    reset(properties)
    if (index === -1) {
      clientPropertiesList.push(properties)
    } else {
      clientPropertiesList[index] = properties
    }
    setClientPropertiesList([...clientPropertiesList])
  }

  const handleBeforeChangeLang = (): Promise<boolean> =>
    new Promise((resolve) => {
      const _dirtyFields = { ...dirtyFields }
      delete _dirtyFields.language
      if (Object.keys(_dirtyFields).length) {
        showConfirmation({
          title: t('modal.cancel.generic.title'),
          subtitle: t('modal.cancel.generic.subtitle'),
          text: t('modal.cancel.generic.text'),
          confirmText: t('common.yes'),
          cancelText: t('common.no'),
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const resetFormAfterSubmit = (properties: IdentityClientProperties) => {
    const index = clientPropertiesList.findIndex(
      (item) => item.language === currentLang
    )
    reset(properties)
    clientPropertiesList[index] = properties
    setClientPropertiesList([...clientPropertiesList])
  }

  const handleCancel = () => {
    initProperties()
  }

  const onSubmit = async (values: IdentityClientProperties) => {
    setIsSubmitting(true)
    try {
      if (values?.logoUrl?.includes('blob:')) {
        const logoUrl = await uploadImage(values.logoUrl)
        values = { ...values, logoUrl }
      }
      const response = await setClientProperties(
        selectedClient as string,
        values
      )
      response && resetFormAfterSubmit(response)
      notify.success(t('IdentityClientConfig.form.successFeedback'))
    } catch (e) {
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    setValue('language', defaultLanguage)
  }, [defaultLanguage])

  useEffect(() => {
    selectedClient && initProperties()
  }, [selectedClient])

  useEffect(() => {
    selectedClient && setNewLangProperties()
  }, [currentLang])

  useEffect(() => {
    initLocalizations()
    initClients()
  }, [])

  return (
    <Main ariaLabelledby="identity-title" className="IdentityClientConfig">
      <div className="sticky-container">
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="identity-title" className="h3">
              {t('IdentityClientConfig.title')}
            </h1>
            <p>{t('IdentityClientConfig.hint')}</p>
          </div>
        </div>
        <section className="mt-3">
          <div className="row">
            <div className="col-12">
              <SelectControl
                label={t('IdentityClientConfig.form.list')}
                name="clients"
                options={clientOptions}
                value={selectedClient as string}
                onChange={setSelectedClient}
                noOptionsMessage=""
                placeholder={t('IdentityClientConfig.form.list.placeholder')}
                isClearable={false}
              />
            </div>
          </div>
        </section>
        {selectedClient && (
          <div>
            <FormProvider methods={methods}>
              <div className="row">
                <div className="col-12">
                  <UploadControl
                    control={control}
                    name="logoUrl"
                    title={t('IdentityClientConfig.form.image')}
                    label={t('common.add')}
                    key={currentLang}
                    rules={{
                      image: {
                        maxWidth: 320,
                        maxHeight: 80,
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <FormTextInput
                    control={control}
                    readOnlyValue={
                      defaultLanguage !== currentLang
                        ? defaultProperties?.description || ''
                        : ''
                    }
                    name="description"
                    label={t('IdentityClientConfig.form.text')}
                    placeholder={t(
                      'IdentityClientConfig.form.text.placeholder'
                    )}
                    type="text"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <FormRichText
                    control={control}
                    name="gdprText"
                    label={t('IdentityClientConfig.form.gdprText')}
                    placeholder={t(
                      'IdentityClientConfig.form.gdprText.placeholder'
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="h4">{t('IdentityClientConfig.federatedLogin.title')}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <FormToggle
                    label={t('IdentityClientConfig.form.federatedLogin')}
                    control={control}
                    name="useFederatedLogin"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <FormTextInput
                    control={control}
                    readOnlyValue={
                      defaultLanguage !== currentLang
                        ? defaultProperties?.azureActiveDirectoryLoginTitle || ''
                        : ''
                    }
                    name="azureActiveDirectoryLoginTitle"
                    label={t('IdentityClientConfig.form.aadTitle')}
                    placeholder={t(
                      'IdentityClientConfig.form.aadTitle.placeholder'
                    )}
                    type="text"
                  />
                </div>
              </div>
            </FormProvider>
          </div>
        )}
      </div>
      <FormLocalActionsLang
        isLoading={isSubmitting}
        methods={methods}
        onCancel={handleCancel}
        onSubmit={onSubmit}
        defaultLanguage={defaultLanguage}
        localizations={localLocalizations}
        onBeforeChange={handleBeforeChangeLang}
      />
    </Main>
  )
}

export default IdentityClientConfig
