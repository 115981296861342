import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import PermissionRoute from '../containers/Restricted/RestrictedRoute'
import { Permission } from '../core/permissions/types'

import ThemeDesign from '../pages/TenantDesign/Theme'
import NavigationDesign from '../pages/TenantDesign/Navigation'
import ButtonsDesign from '../pages/TenantDesign/Buttons'
import DropdownDesign from '../pages/TenantDesign/Dropdown'
import TagsDesign from '../pages/TenantDesign/Tags'

const TenantDesignRouter: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_ComponentDesign]}
            element={<ThemeDesign />}
          />
        }
      />
      <Route
        path="/theme"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_ComponentDesign]}
            element={<ThemeDesign />}
          />
        }
      />
      <Route
        path="/navigation"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_ComponentDesign]}
            element={<NavigationDesign />}
          />
        }
      />
      <Route
        path="/buttons"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_ComponentDesign]}
            element={<ButtonsDesign />}
          />
        }
      />
      <Route
        path="/dropdown"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_ComponentDesign]}
            element={<DropdownDesign />}
          />
        }
      />
      <Route
        path="/tags"
        element={
          <PermissionRoute
            permissions={[Permission.TenantAdmin_ComponentDesign]}
            element={<TagsDesign />}
          />
        }
      />
    </Routes>
  )
}
export default TenantDesignRouter
