import { FC } from 'react'
import { TagLink } from '@liveconnect/components'
import { useTranslation } from 'react-i18next'

import './styles.scss'

const TagsContainer: FC = () => {
  const { t } = useTranslation()
  return (
    <div className="tags-design-container">
      <p className="tags-design-container__title">
        {t('tenantDesign.tags.initial')}
      </p>
      <div className="tags-design-container__tag-section">
        <TagLink navigateTo="">{t('tenantDesign.tags.text')}</TagLink>
      </div>
      <p className="tags-design-container__title">
        {t('tenantDesign.tags.hover')}
      </p>
      <div className="tags-design-container__tag-section">
        <TagLink navigateTo="" className="hover">
          {t('tenantDesign.tags.text')}
        </TagLink>
      </div>
      <p className="tags-design-container__title">
        {t('tenantDesign.tags.active')}
      </p>
      <div className="tags-design-container__tag-section">
        <TagLink navigateTo="" className="active">
          {t('tenantDesign.tags.text')}
        </TagLink>
      </div>
    </div>
  )
}

export default TagsContainer
