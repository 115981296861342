import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { Accordion, Tag } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import { Main } from '../../../components/Main'
import TemplateEditor from './TemplateEditor'
import FormActions from '../../../components/FormActions'

import { useTenantEmailsTemplates } from '../../../core/tenantEmailsTemplates/useTenantEmailsTemplates'

import { TemplateDetail } from '../../../core/tenantEmailsTemplates/types'

import './styles.scss'

interface LocationState {
  id: string
  creatingDraft: boolean
}

const TemplateDetails: FC = () => {
  const { t } = useTranslation()
  const { getTemplateDetails } = useTenantEmailsTemplates()
  const location = useLocation()
  const navigate = useNavigate()
  const { id, creatingDraft } = location.state as LocationState
  const formActionsRef = useRef<() => void>()

  const [templateDetails, setTemplateDetails] = useState<TemplateDetail>()

  const initTemplate = useCallback(async () => {
    try {
      const response = await getTemplateDetails(id)
      setTemplateDetails(response)
    } catch (e) {
      throw e as Error
    }
  }, [getTemplateDetails, setTemplateDetails])

  const buildTemplateType = (): 'addDraft' | 'editDraft' | 'editTemplate' => {
    if (templateDetails?.isDraft) return 'editDraft'
    if (creatingDraft) return 'addDraft'
    return 'editTemplate'
  }

  const buildTitle = (): string => {
    if (templateDetails?.isDraft)
      return `${t('tenantEmailsTemplate.details.title.editDraft')} ${t(
        `tenantEmailsTemplate.table.body.name.${templateDetails?.name}`
      )}`
    if (creatingDraft)
      return `${t('tenantEmailsTemplate.details.title.createDraft')} ${t(
        `tenantEmailsTemplate.table.body.name.${templateDetails?.name}`
      )}`
    return `${t('tenantEmailsTemplate.details.title.editTemplate')} ${t(
      `tenantEmailsTemplate.table.body.name.${templateDetails?.name}`
    )}`
  }

  const onSubmit = (submitCallback: () => void) =>
    (formActionsRef.current = submitCallback)

  useEffect(() => {
    initTemplate()
  }, [])

  return (
    <Main ariaLabelledby="TemplateDetails" className="TemplateDetails">
      <div className="TemplateDetails__header">
        <h1
          id="TemplateDetails"
          className="h3 TemplateDetails__header__title d-flex align-items-center"
        >
          {buildTitle()}
          <button className="btn btn-link" onClick={() => navigate('..')}>
            <Icon name="chevron_left" />
            {t('tenantEmailsTemplate.details.navigation')}
          </button>
        </h1>

        <p className="TemplateDetails__header__subtitle">
          {t('tenantEmailsTemplate.details.subtitle')}
        </p>
      </div>
      <div className="TemplateDetails__info">
        {templateDetails?.propertiesList && (
          <Accordion
            className="TemplateDetails__info__accordion"
            items={[
              {
                key: 0,
                title: t('tenantEmailsTemplate.details.accordion.title'),
                iconName: 'info_outline',
                children: templateDetails.propertiesList.map(
                  ({ name, recommended }, idx) => (
                    <div
                      key={idx}
                      className="TemplateDetails__info__accordion__item row"
                    >
                      <div className="TemplateDetails__info__accordion__item__tag col-1 p-0">
                        {recommended && (
                          <Tag>
                            {t(
                              'tenantEmailsTemplate.details.accordion.recommended'
                            )}
                          </Tag>
                        )}
                      </div>
                      <div className="TemplateDetails__info__accordion__item__label col-11">
                        <div className="TemplateDetails__info__accordion__item__label">
                          <span className="TemplateDetails__info__accordion__item__label__name">
                            {name}:{' '}
                          </span>
                          <span className="TemplateDetails__info__accordion__item__label__description">
                            {t(
                              `tenantEmailsTemplate.details.accordion.vars.${name}`
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                ),
              },
            ]}
          />
        )}
      </div>
      <div className="TemplateDetails__editor">
        {templateDetails && (
          <TemplateEditor
            id={id}
            subject={templateDetails.subject}
            mjml={templateDetails.mjml}
            properties={JSON.parse(templateDetails.properties)}
            templateType={buildTemplateType()}
            onSubmit={onSubmit}
          />
        )}
      </div>
      <FormActions>
        <button
          className="btn btn-outline-primary"
          onClick={() => navigate('..')}
        >
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          onClick={() => formActionsRef.current && formActionsRef.current()}
        >
          {t('common.save')}
        </button>
      </FormActions>
    </Main>
  )
}

export default TemplateDetails
