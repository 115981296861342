import { FC, useMemo } from 'react'
import { ComplimentType, Permission } from '../../core/permissions/types'
import usePermissions from '../../core/permissions/usePermissions'

interface RestrictedProps {
  to?: Permission[]
  mustComply?: ComplimentType
  fallback?: JSX.Element | string
}

const Restricted: FC<RestrictedProps> = ({
  to = [],
  fallback,
  mustComply = ComplimentType.one,
  children,
}) => {
  const { checkPermissions, list } = usePermissions()

  const allowed = useMemo(
    () => checkPermissions(to, mustComply),
    [to, mustComply, list]
  )

  if (allowed) {
    return <>{children}</>
  }

  return <>{fallback}</>
}

export default Restricted
