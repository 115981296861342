import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { Tenant, UseTenantResult } from './types'
import { setDetail } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'
import useUploadImage from '../shared/useUploadImage'
import useTenantConfig from '../tenantConfig/useTenantConfig'

const useTenant = (): UseTenantResult => {
  const dispatch = useAppDispatch()
  const { detail } = useAppSelector((state) => state.tenant)
  const { get, put } = useFetch()
  const { notifyApiError } = useError()
  const { uploadImage } = useUploadImage()
  const { fetchTenantConfig } = useTenantConfig()

  const endpoint = 'tenants'
  const errorModel = 'tenantData'

  const fetchTenant = async () => {
    const response: Tenant | undefined = await get({ endpoint })
    response && dispatch(setDetail(response))
  }

  const editTenant = async (tenant: Tenant) => {
    try {
      const response: Tenant | undefined = await put({ endpoint, body: tenant })
      response && dispatch(setDetail(response))
      fetchTenantConfig()
    } catch (e) {
      notifyApiError(e, errorModel, 'save')
    }
  }

  return {
    detail,
    fetchTenant,
    editTenant,
    uploadImage: (image) => uploadImage(endpoint, image),
  }
}

export default useTenant
