import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  ComparePrivacyPoliciesParams,
  PrivacyPolicies,
  UsePrivacyPoliciesResult,
  ComparePrivacyPoliciesResponse,
  PrivacyPoliciesDetail,
  PrivacyPoliciesValue,
} from './types'
import { setCurrent, setList } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const usePrivacyPolicies = (): UsePrivacyPoliciesResult => {
  const dispatch = useAppDispatch()
  const { current, list, isLoaded } = useAppSelector(
    (state) => state.privacyPolicies
  )
  const { get, post } = useFetch()
  const { notifyApiError } = useError()

  const endpoint = 'privacypolicies'
  const errorModel = 'privacypolicies'

  const fetchPrivacyPolicies = async () => {
    const response: PrivacyPolicies[] | undefined = await get({ endpoint })
    response && dispatch(setList(response))
  }

  const createPrivacyPolicies = async (
    privacyPolicies: PrivacyPoliciesValue[]
  ) => {
    try {
      const response: PrivacyPoliciesDetail | undefined = await post({
        endpoint,
        body: { privacyPolicyValues: privacyPolicies },
      })
      response && dispatch(setCurrent(response))
    } catch (e) {
      notifyApiError(e, errorModel, 'save')
    }
  }

  const fetchCurrent = async () => {
    const response: PrivacyPoliciesDetail | undefined = await get({
      endpoint: `${endpoint}/current`,
    })
    response && dispatch(setCurrent(response))
  }

  const comparePrivacyPolicies = async (
    params: ComparePrivacyPoliciesParams
  ) => {
    const response: ComparePrivacyPoliciesResponse | undefined = await get({
      endpoint: `${endpoint}/compare`,
      params,
    })
    return response as ComparePrivacyPoliciesResponse
  }

  return {
    current,
    list,
    isLoaded,
    fetchPrivacyPolicies,
    setCurrent: (data) => dispatch(setCurrent(data)),
    createPrivacyPolicies,
    fetchCurrent,
    comparePrivacyPolicies,
  }
}

export default usePrivacyPolicies
