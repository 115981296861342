// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPoliciesVersions__content{min-height:calc(100vh - 435px)}.PrivacyPoliciesVersions__header-values{margin-bottom:16px}.PrivacyPoliciesVersions__header-values>div{margin-right:32px}.PrivacyPoliciesVersions__header-values span{font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/app/pages/PrivacyPolicies/Versions/styles.scss"],"names":[],"mappings":"AASE,kCACE,8BAAA,CAEF,wCAOE,kBAAA,CANA,4CACE,iBAAA,CAEF,6CACE,gBAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.PrivacyPoliciesVersions {\n  &__content {\n    min-height: calc(100vh - 435px);\n  }\n  &__header-values {\n    & > div {\n      margin-right: 32px;\n    }\n    span {\n      font-weight: bold;\n    }\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PrivacyPoliciesVersions__content": `PrivacyPoliciesVersions__content`,
	"PrivacyPoliciesVersions__header-values": `PrivacyPoliciesVersions__header-values`
};
export default ___CSS_LOADER_EXPORT___;
