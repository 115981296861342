import { FC } from 'react'
import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'

import './styles.scss'

const ButtonsContainer: FC = () => {
  const { t } = useTranslation()
  return (
    <div className="buttons-design-container">
      <p className="buttons-design-container__title">
        {t('tenantDesign.buttons.primary.title')}
      </p>
      <div className="buttons-design-container__primary section">
        <button className="btn btn-primary">
          {t('tenantDesign.buttons.button')}
        </button>
        <button className="btn btn-primary hover">
          {t('tenantDesign.buttons.button')}
        </button>
        <button className="btn btn-primary active">
          {t('tenantDesign.buttons.button')}
        </button>
      </div>
      <p className="buttons-design-container__title">
        {t('tenantDesign.buttons.secondary.title')}
      </p>
      <div className="buttons-design-container__secondary section">
        <button className="btn btn-outline-primary">
          {t('tenantDesign.buttons.button')}
        </button>
        <button className="btn btn-outline-primary hover">
          {t('tenantDesign.buttons.button')}
        </button>
        <button className="btn btn-outline-primary active">
          {t('tenantDesign.buttons.button')}
        </button>
      </div>
      <p className="buttons-design-container__title">
        {t('tenantDesign.buttons.terciary.title')}
      </p>
      <div className="buttons-design-container__terciary section">
        <button className="btn btn-link p-0">
          <Icon name="keyboard_arrow_left" />
          {t('tenantDesign.buttons.textButton')}
          <Icon name="keyboard_arrow_right" />
        </button>
        <button className="btn btn-link hover p-0">
          <Icon name="keyboard_arrow_left" />
          {t('tenantDesign.buttons.textButton')}
          <Icon name="keyboard_arrow_right" />
        </button>
      </div>
      <p className="buttons-design-container__title">
        {t('tenantDesign.buttons.link.title')}
      </p>
      <div className="buttons-design-container__link section d-flex align-items-center">
        <div className="buttons-design-container__link__item">
          <a className="lc-link">{t('tenantDesign.buttons.link.title')}</a>
        </div>
        <div className="buttons-design-container__link__item">
          <a className="lc-link hover">
            {t('tenantDesign.buttons.link.title')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default ButtonsContainer
