import { useRef, useEffect, useState, DragEvent } from 'react'
import { Controller, FieldError, Control, Path } from 'react-hook-form'
import classNames from 'classnames'
import { Icon } from '@liveconnect/icons'
import { Tooltip } from '@liveconnect/communities-ui'

import MetaData from './MetaData'
import CropImage from './CropImage'
import DragAndDropComponent from './DragAndDrop'
import { UploadControlRules } from './types'
import { VIDEO_KEY, IMAGE_KEY } from './constants'
import { getFileType } from './upload.utils'

import './index.scss'

interface UploadControlProps<Z> {
  control: Control<Z, object>
  name: keyof Z | string
  title: string
  label: string
  error?: FieldError
  readonly?: boolean
  required?: boolean
  videoMaxWeight?: number
  aspectRatio?: [number, number]
  selectFilesSuccess?: (file: File[]) => void
  selectFilesError?: (error: FieldError) => void
  onRemoveFile?: () => void
  rules?: UploadControlRules
  tooltip?: string
  value?: string
  disableRemove?: boolean
  disableCrop?: boolean
}

const UploadControl = function UploadControl<T>({
  control,
  name,
  title,
  label,
  rules,
  error,
  onRemoveFile,
  readonly = false,
  required,
  tooltip,
  disableRemove,
  disableCrop,
}: UploadControlProps<T>) {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const fileContainerRef = useRef<HTMLInputElement>(null)
  const [dimensions, setDimensions] = useState<string>()
  const [cropPreview, setCropPreview] = useState<string>('')

  const canUploadVideo = (): boolean => {
    if (typeof rules === 'undefined') return false
    if (rules[VIDEO_KEY]) return true
    return false
  }

  const getAcceptFromRules = (): string | undefined => {
    if (typeof rules === 'undefined') return
    const imageRules = rules[IMAGE_KEY]
    if (
      typeof imageRules !== 'undefined' &&
      typeof imageRules.allowedExtensions !== 'undefined' &&
      imageRules.allowedExtensions.length > 0
    ) {
      return `.${imageRules.allowedExtensions.join(', .')}`
    }
    const videoRules = rules[VIDEO_KEY]
    if (
      typeof videoRules !== 'undefined' &&
      typeof videoRules.allowedExtensions !== 'undefined' &&
      videoRules.allowedExtensions.length > 0
    ) {
      return `.${videoRules.allowedExtensions.join(', .')}`
    }
  }

  const resetInputFile = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
      // This patch ensures that html input file is cleared
      inputFileRef.current.dispatchEvent(new Event('onchange'))
    }
  }

  const onFilesChanges = (files: File[], field) => {
    if (files.length === 0) return
    if (disableCrop) field.onChange(URL.createObjectURL(files[0]))
    else if (getFileType(files[0].type) === IMAGE_KEY)
      setCropPreview(URL.createObjectURL(files[0]))
  }

  const handleChange = (field) => {
    if (!inputFileRef.current) return
    const files = Array.from(inputFileRef.current.files ?? [])
    onFilesChanges(files, field)
  }

  const handleRemove = (onChange: (v: string) => void) => {
    resetInputFile()
    onChange('')
    onRemoveFile && onRemoveFile()
  }

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const files = Array.from(event.dataTransfer.files)
    if (!inputFileRef.current) return

    onFilesChanges(files)
    fileContainerRef.current?.classList.remove(
      'input-file__droppable--dragging-over'
    )
  }

  useEffect(() => {
    const imageRules = rules ? rules[IMAGE_KEY] : undefined
    if ((imageRules?.maxWidth ?? 0) > 0 && (imageRules?.maxHeight ?? 0) > 0)
      setDimensions(`${imageRules?.maxWidth}px x ${imageRules?.maxHeight}px.`)
  }, [rules?.[IMAGE_KEY]?.maxWidth, rules?.[IMAGE_KEY]?.maxHeight])

  return (
    <Controller
      control={control}
      name={name as unknown as Path<T>}
      render={({ field }) => (
        <div className="lc-form-control-group input-file" onDrop={handleDrop}>
          <div className="form-label-wrapper">
            <label className={classNames('form-label', { required })}>
              {title}
            </label>
            {tooltip ? (
              <Tooltip content={tooltip} className="ms-2">
                <Icon name="info_outline" tabIndex={0} />
              </Tooltip>
            ) : undefined}
          </div>
          <DragAndDropComponent
            field={field}
            removeOptionsVisible={!disableRemove}
            error={error}
            handleRemove={() => handleRemove(field.onChange)}
            canUploadVideo={canUploadVideo}
          />
          <div className="input-file__info">
            <label className="btn p-0 m-0">
              {!readonly && (
                <div className="input-file__info__trigger">
                  <span className="fw-bold me-2">{label}</span>
                  <Icon className="input-file__info__icon" name="add" />
                </div>
              )}

              <input
                name="input-file-upload"
                ref={inputFileRef}
                type="file"
                hidden
                onChange={() => handleChange(field)}
                accept={getAcceptFromRules()}
              />
            </label>
            <MetaData dimensions={dimensions} rules={rules} />
          </div>
          <CropImage
            src={cropPreview}
            width={rules?.[IMAGE_KEY]?.maxWidth}
            height={rules?.[IMAGE_KEY]?.maxHeight}
            onCompleteCrop={(fileBlob: Blob) => {
              const url = URL.createObjectURL(fileBlob)
              field.onChange(url)
            }}
            onCancelCrop={resetInputFile}
          />
        </div>
      )}
    />
  )
}

export default UploadControl
