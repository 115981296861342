import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EmailsDashboardListFilters } from '../types'

const initialState: EmailsDashboardListFilters = {
  page: 1,
  pageSize: 20,
  availablePages: 1,
  search: '',
  types: [],
  states: [],
}

export const emailsListFiltersSlice = createSlice({
  name: 'emailsListFilters',
  initialState,
  reducers: {
    setListFilters: (
      state,
      action: PayloadAction<EmailsDashboardListFilters>
    ) => {
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.availablePages = action.payload.availablePages
      state.search = action.payload.search
      state.types = action.payload.types
      state.states = action.payload.states
    },
  },
})

export const { setListFilters } = emailsListFiltersSlice.actions

export default emailsListFiltersSlice.reducer
