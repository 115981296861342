import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Profile, ProfileDetail, ProfilesState } from './types'

const initialState: ProfilesState = {
  detail: null,
  list: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<Profile[]>) => {
      state.list = action.payload
    },
    clearList: (state) => {
      state.list = []
    },
    setDetail: (state, action: PayloadAction<ProfileDetail>) => {
      state.detail = action.payload
    },
    clearDetail: (state) => {
      state.detail = null
    },
  },
})

export const {
  setDetail,
  showLoader,
  hideLoader,
  setList,
  clearList,
  clearDetail,
} = slice.actions

export default slice.reducer
