import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Loader } from '@liveconnect/communities-ui'
import {
  FormProvider,
  FormRichText,
  RadioControl,
} from '@liveconnect/components'

import { buildValidationSchema } from './validations'
import useNotifications from '../../../utils/notifications/useNotifications'
import FormActions from '../../../components/FormActions'
import useTermsCons from '../../../core/termsCons/useTermsCons'
import useLocalizations from '../../../core/localizations/useLocalizations'
import { TermsConsDetail } from '../../../core/termsCons/types'
import useCulture from '../../../utils/culture'
import useUi from '../../../core/ui/useUi'
import HistoryModal from './HistoryModal'
import { DEFAULT_LANG } from '../../../i18n/config'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'

import './styles.scss'

interface FormType {
  value: string
}

const TermsConsForm: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { localizations } = useLocalizations()
  const { formatDate } = useCulture()
  const { showConfirmation } = useUi()
  const { current, fetchCurrent, createTermsCons } = useTermsCons()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedLang, setSelectedLang] = useState<string>(DEFAULT_LANG)
  const [isEditting, setIsEditting] = useState<boolean>(false)
  const [currentForm, setCurrentForm] = useState<TermsConsDetail>()
  const [showHistory, setShowHistory] = useState<boolean>(false)

  const currentValue = useMemo(
    () =>
      currentForm?.termConditionValues.find(
        (item) => item.isoCode === selectedLang
      )?.value ?? '',
    [selectedLang, isEditting, currentForm]
  )

  const methods = useForm<FormType>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      value: currentValue,
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isValid, isDirty },
  } = methods

  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const handleChangeLang = (isoCode: string) => {
    if (isEditting && currentForm) {
      const langIndex = currentForm.termConditionValues.findIndex(
        (item) => item.isoCode === selectedLang
      )
      if (langIndex !== -1) {
        const termConditionValues = [...currentForm.termConditionValues]
        termConditionValues[langIndex] = {
          ...termConditionValues[langIndex],
          value: getValues('value'),
        }
        setCurrentForm({ ...currentForm, termConditionValues })
      }
    }
    setSelectedLang(isoCode)
  }

  const handleConfirmCancel = () => {
    if (isDirty) {
      showConfirmation({
        title: t('modal.cancel.generic.title'),
        subtitle: t('modal.cancel.generic.subtitle'),
        text: t('modal.cancel.generic.text'),
        iconName: 'report_problem',
        confirmText: t('common.yes'),
        cancelText: t('common.no'),
        onConfirm: () => {
          handleCancel()
        },
      })
    } else {
      handleCancel()
    }
  }

  const handleCancel = () => {
    setIsEditting(false)
    current && setCurrentForm(current)
    reset({
      value: currentValue,
    })
  }

  const handleSaveConfirmation = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (isDirty) {
        showConfirmation({
          title: t('termsCons.form.saveConfirmation.title'),
          subtitle: t('termsCons.form.saveConfirmation.subtitle'),
          iconName: 'report_problem',
          confirmText: t('common.save'),
          cancelText: t('common.cancel'),
          onConfirm: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          },
        })
      } else {
        resolve(true)
      }
    })

  const onSubmit = async ({ value }: FormType) => {
    setIsSubmitting(true)
    try {
      if (currentForm) {
        const langIndex = currentForm.termConditionValues.findIndex(
          (item) => item.isoCode === selectedLang
        )
        if (langIndex !== -1) {
          const termConditionValues = [...currentForm.termConditionValues]
          termConditionValues[langIndex] = {
            ...termConditionValues[langIndex],
            value: value,
          }

          if (termConditionValues.some((item) => !item.value)) {
            showConfirmation({
              title: t('common.warning'),
              subtitle: t('termsCons.form.emptyConfirmation.subtitle'),
              text: t('termsCons.form.emptyConfirmation.text'),
              iconName: 'report_problem',
              hideCancel: true,
              confirmText: t('termsCons.form.emptyConfirmation.confirm'),
            })
          } else {
            const continueSaving = await handleSaveConfirmation()
            if (continueSaving) {
              await createTermsCons(termConditionValues)
              notify.success(t('termsCons.form.successFeedback'))
              setIsEditting(false)
            }
          }
        }
      }
    } catch (e) {
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    reset({
      value: currentValue,
    })
  }, [selectedLang, currentForm])

  useEffect(() => {
    if (current) {
      reset({
        value: currentValue,
      })
      setCurrentForm(current)
    } else {
      setCurrentForm({
        id: '',
        code: '',
        creationDate: '',
        author: '',
        termConditionValues: localizations.map((item) => ({
          isoCode: item.isoCode,
          value: '',
        })),
      })
    }
  }, [current, localizations])

  useEffect(() => {
    fetchCurrent()
  }, [])

  return (
    <>
      <div className="TermsConsForm">
        <div className="d-flex justify-content-between">
          <div>
            <div className="TermsConsForm__header-values d-flex">
              <div>
                <span>{t('termsCons.code')}:</span> {current?.code}
              </div>
              <div>
                <span>{t('termsCons.date')}:</span>{' '}
                {current?.creationDate &&
                  formatDate(
                    new Date(current?.creationDate as string) as Date,
                    'LONG_DATE_TIME'
                  )}
              </div>
              <div>
                <span>{t('termsCons.author')}:</span> {current?.author}
              </div>
            </div>
          </div>
          <div className="TermsConsForm__langs d-flex">
            {localizations.map((item) => (
              <RadioControl
                key={item.isoCode}
                id=""
                name=""
                label={t(`localizations.${item.isoCode}`)}
                value={item.isoCode}
                checked={item.isoCode === selectedLang}
                onChange={() => handleChangeLang(item.isoCode)}
                hiddenLabel={false}
              />
            ))}
          </div>
        </div>
        <section>
          <FormProvider methods={methods}>
            <div className="row">
              <div className="col-12">
                {isEditting ? (
                  <FormRichText
                    key={selectedLang}
                    control={control}
                    name="value"
                    required={true}
                    label={t('termsCons.edit.label')}
                    placeholder={t('termsCons.form.value.placeholder')}
                  />
                ) : (
                  <div
                    className="TermsConsForm__value-box"
                    dangerouslySetInnerHTML={{
                      __html:
                        currentValue ?? t('termsCons.form.value.placeholder'),
                    }}
                  />
                )}
              </div>
            </div>
          </FormProvider>
        </section>
        <FormActions>
          {isEditting ? (
            <>
              <button
                className="btn btn-outline-primary"
                onClick={handleConfirmCancel}
              >
                {t('common.cancel')}
              </button>
              <button
                className="btn btn-primary"
                disabled={!isValid || isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                {isSubmitting ? <Loader /> : t('common.save')}
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-outline-primary"
                onClick={() => setShowHistory(true)}
              >
                {t('termsCons.form.changeHistory')}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setIsEditting(true)}
              >
                {t('common.edit')}
              </button>
            </>
          )}
        </FormActions>
      </div>
      {showHistory && <HistoryModal onClose={() => setShowHistory(false)} />}
    </>
  )
}

export default TermsConsForm
