import { FC, useEffect, useState } from 'react'
import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { FormProvider, FormTextInput, useTheme } from '@liveconnect/components'

import { useBlockRouteChangeWithDialog } from '../../../../utils/routing/useBlockRouteChange'
import useDesignConstants from '../../hooks'
import useTenantDesign from '../../../../core/tenantDesign/useTenantDesign'
import useNotifications from '../../../../utils/notifications/useNotifications'
import useUi from '../../../../core/ui/useUi'

import { Color, ConfigurationProps } from '../../types'
import { ButtonsConfigurationFormProps } from './types'

import './styles.scss'

const ButtonsConfiguration: FC<ConfigurationProps> = ({
  colors,
  triggerCancel,
  formCancelled,
  triggerSubmit,
  formSubmitted,
  formDirty,
}) => {
  const { t } = useTranslation()
  const { changeThemeVars } = useTheme()
  const { defaultTheme } = useDesignConstants()
  const { editDesign } = useTenantDesign()
  const notify = useNotifications()
  const { showConfirmation } = useUi()

  const [showResetModal, setShowResetModal] = useState<boolean>(false)

  const methods = useForm<ButtonsConfigurationFormProps>({
    mode: 'onChange',
    defaultValues: {
      'button-primary-initial': colors['button-primary-initial'],
      'button-primary-hover': colors['button-primary-hover'],
      'button-primary-active': colors['button-primary-active'],
      'button-primary-text': colors['button-primary-text'],
      'button-secondary-initial': colors['button-secondary-initial'],
      'button-secondary-hover': colors['button-secondary-hover'],
      'button-secondary-active': colors['button-secondary-active'],
      'button-ternary-text': colors['button-ternary-text'],
      'button-ternary-decoration': colors['button-ternary-decoration'],
      'button-link': colors['button-link'],
    },
  })

  const {
    control,
    reset,
    getValues,
    formState: { isDirty },
  } = methods

  const handleChangeColor = (color: Record<string, string>) => {
    changeThemeVars(color)
  }

  const handleResetColor = () => {
    reset()
    handleChangeColor({ ...getValues() })
    formCancelled()
    notify.success(t('tenantDesign.configuration.notification'))
  }

  const submitDesign = async () => {
    try {
      const data: Color[] = await Object.entries({ ...getValues() }).map(
        (color) => ({ key: color[0], value: color[1] })
      )
      await editDesign(data, 'buttons')
      reset({ ...getValues() })
      formSubmitted()
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (triggerCancel()) handleResetColor()
  }, [triggerCancel])

  useEffect(() => {
    if (triggerSubmit()) submitDesign()
  }, [triggerSubmit])

  useEffect(() => {
    formDirty(isDirty)
  }, [isDirty])

  useEffect(() => {
    showResetModal &&
      showConfirmation({
        className: 'buttons-design-configuration__modal',
        title: t('tenantDesign.configuration.reset'),
        subtitle: t('tenantDesign.configuration.modal.body'),
        confirmText: t('tenantDesign.configuration.modal.reset'),
        cancelText: t('common.cancel'),
        iconName: 'report_problem',
        onConfirm: () => {
          handleResetColor()
          setShowResetModal(false)
        },
        onCancel: () => {
          setShowResetModal(false)
        },
      })
  }, [showResetModal])

  useEffect(() => {
    return () => changeThemeVars(defaultTheme)
  }, [])

  useBlockRouteChangeWithDialog(isDirty)

  return (
    <div className="buttons-design-configuration">
      <FormProvider methods={methods}>
        <div className="buttons-design-configuration__title">
          {t('tenantDesign.configuration.title')}
        </div>
        <hr className="buttons-design-configuration__separator" />
        <div className="buttons-design-configuration__body">
          <p className="buttons-design-configuration__body__title">
            {t('tenantDesign.buttons.primary.title')}
          </p>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.primary.initial')}
            </div>
            <FormTextInput
              control={control}
              name="button-primary-initial"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.primary.hover')}
            </div>
            <FormTextInput
              control={control}
              name="button-primary-hover"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.primary.active')}
            </div>
            <FormTextInput
              control={control}
              name="button-primary-active"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.primary.text')}
            </div>
            <FormTextInput
              control={control}
              name="button-primary-text"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <p className="buttons-design-configuration__body__title">
            {t('tenantDesign.buttons.secondary.title')}
          </p>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.secondary.initial')}
            </div>
            <FormTextInput
              control={control}
              name="button-secondary-initial"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.secondary.hover')}
            </div>
            <FormTextInput
              control={control}
              name="button-secondary-hover"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.secondary.active')}
            </div>
            <FormTextInput
              control={control}
              name="button-secondary-active"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <p className="buttons-design-configuration__body__title">
            {t('tenantDesign.buttons.terciary.title')}
          </p>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.terciary.text')}
            </div>
            <FormTextInput
              control={control}
              name="button-ternary-text"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.terciary.icon')}
            </div>
            <FormTextInput
              control={control}
              name="button-ternary-decoration"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
          <p className="buttons-design-configuration__body__title">
            {t('tenantDesign.buttons.link.title')}
          </p>
          <div className="buttons-design-configuration__body__picker d-flex align-items-center">
            <div className="buttons-design-configuration__body__picker__label">
              {t('tenantDesign.buttons.link.text')}
            </div>
            <FormTextInput
              control={control}
              name="button-link"
              type="color"
              onChange={(name, value) =>
                handleChangeColor({ [name]: value.toString() })
              }
            />
          </div>
        </div>
        <div
          className="buttons-design-configuration__footer d-flex align-items-center"
          onClick={() => isDirty && setShowResetModal(true)}
        >
          <div className="buttons-design-configuration__footer__label">
            {t('tenantDesign.configuration.reset')}
          </div>
          <Icon
            className="buttons-design-configuration__footer__icon"
            name="upgrade"
          />
        </div>
      </FormProvider>
    </div>
  )
}

export default ButtonsConfiguration
