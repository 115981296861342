import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, FormTextInput } from '@liveconnect/components'

import useNotifications from '../../../utils/notifications/useNotifications'
import { buildValidationSchema } from './validations'
import useDomains from '../../../core/domains/useDomains'
import { CreateDomainType } from '../../../core/domains/types'

import './styles.scss'

interface CreateDomainProps {
  onClose: () => void
}

const CreateDomain: FC<CreateDomainProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { createDomain, fetchList } = useDomains()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const methods = useForm<CreateDomainType>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: { domain: '' },
  })
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods

  const onSubmit = async (data: CreateDomainType) => {
    setIsSubmitting(true)
    try {
      await createDomain(data)
      await fetchList()
      notify.success(t('blackList.create.modal.successFeedback'))
      onClose()
    } catch (e) {
      onClose()
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal
      show={true}
      onHide={onClose}
      // size="lg"
      className="CreateDomain"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('blackList.create.modal.title')}</Modal.Title>
      </Modal.Header>
      <FormProvider methods={methods}>
        <Modal.Body>
          <div className="d-flex CreateDomain__legend mb-3">
            <div className="bd-highlight">{t('common.required')}</div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormTextInput
                control={control}
                name="domain"
                label={t('blackList.create.modal.domain')}
                placeholder={t('blackList.create.modal.domain.placeholder')}
                type="text"
                required={true}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={onClose}>
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
          >
            {t('common.add')}
          </button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

export default CreateDomain
