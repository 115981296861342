import { combineReducers } from '@reduxjs/toolkit'

import { emailsListFiltersSlice } from './filtersState'
import { emailsListSlice } from './listState'
import { emailsStatesSlice } from './statesState'
import { emailsSummarySlice } from './summaryState'
import { emailsTypesSlice } from './typesState'
import { emailsLoaderSlice } from './loaderState'

const emailsDashboard = combineReducers({
  emailsLoader: emailsLoaderSlice.reducer,
  emailsListFilters: emailsListFiltersSlice.reducer,
  emailsList: emailsListSlice.reducer,
  emailsTypes: emailsTypesSlice.reducer,
  emailsStates: emailsStatesSlice.reducer,
  emailsSummary: emailsSummarySlice.reducer,
})

export type RootState = ReturnType<typeof emailsDashboard>

export default emailsDashboard
