import { UseEditionsResult, Edition, CreateEditionType } from './types'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useEditions = (): UseEditionsResult => {
  const { post, put } = useFetch()
  const { notifyApiError } = useError()

  const endpoint = 'editions'
  const errorModel = 'edition'

  const createEdition = async (edition: CreateEditionType) => {
    try {
      await post({ endpoint, body: edition })
    } catch (e) {
      notifyApiError(e, errorModel, 'create')
    }
  }

  const updateEdition = async (edition: Edition) => {
    try {
      await put({ endpoint, id: edition.id, body: edition })
    } catch (e) {
      notifyApiError(e, errorModel, 'edit')
    }
  }

  const changeEditionStatus = async (id: string, isEnabled: boolean) => {
    try {
      await post({
        endpoint: `${endpoint}/${id}/change-status`,
        body: { isEnabled },
      })
    } catch (e) {
      const action = !isEnabled ? 'deactivate' : 'activate'
      notifyApiError(e, errorModel, action)
    }
  }

  return {
    createEdition,
    updateEdition,
    changeEditionStatus,
  }
}

export default useEditions
