import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnDef, Row } from '@tanstack/react-table'
import { Pagination } from '@liveconnect/components'

import { Main } from '../../components/Main'
import { CommunityUser } from '../../core/community/types'
import useCommunity from '../../core/community/useCommunity'
import CreateCommunityUser from './CreateCommunityUser'
import useNotifications from '../../utils/notifications/useNotifications'
import useUi from '../../core/ui/useUi'
import { Profile } from '../../core/profiles/types'
import ProfilesModal from './ProfilesModal'
import ContentTable from '../../components/ContentTable'

import './styles.scss'

const CommunityScreen: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const {
    list,
    search,
    isLoaded,
    availableItems,
    page,
    availablePages,
    pageSize,
    fetchList,
    clearList,
    deleteUser,
  } = useCommunity()
  const { showConfirmation } = useUi()
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showProfiles, setShowProfiles] = useState<Profile[] | null>(null)
  const [edittingUser, setEdittingUser] = useState<CommunityUser>()

  const handleDelete = async (id: string) => {
    try {
      await deleteUser(id)
      notify.success(t('communityUser.delete.successFeedback'))
      fetchList({ page, search })
    } catch (e) {}
  }

  const handleConfirmDelete = (id: string) => {
    showConfirmation({
      title: t('communityUser.delete.modal.title'),
      subtitle: t('communityUser.delete.modal.subtitle'),
      iconName: 'report_problem',
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        handleDelete(id)
      },
    })
  }

  const handleCloseForm = () => {
    setShowCreateModal(false)
    setEdittingUser(undefined)
  }

  const actions = (row: CommunityUser) => {
    return [
      {
        onClick: () => setEdittingUser(row),
        label: t('community.list.table.action.edit'),
        icon: 'edit',
      },
      {
        onClick: () => handleConfirmDelete(row.id),
        label: t('community.list.table.action.delete'),
        icon: 'delete_outline',
      },
    ]
  }

  const columns: ColumnDef<CommunityUser>[] = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: t('community.list.table.email'),
      },
      {
        accessorKey: 'name',
        header: t('community.list.table.name'),
        cell: ({ row }: { row: Row<CommunityUser> }) =>
          `${row.original.name} ${row.original.surname}`,
      },
      {
        accessorKey: 'profiles',
        header: t('community.list.table.profiles'),
        cell: ({ row }: { row: Row<CommunityUser> }) =>
          renderProfilesCell(row.original),
      },
    ],
    []
  )

  useEffect(() => {
    fetchList({ page: 1 })
    return () => {
      clearList()
    }
  }, [])

  const renderProfilesCell = (row: CommunityUser) => {
    if (!row.profiles?.length) return ''
    return row.profiles.length === 1 ? (
      row.profiles[0].name
    ) : (
      <div
        className="Community__profiles"
        onClick={() => setShowProfiles(row.profiles)}
      >
        <div className="Community__profiles__cell">{`${row.profiles.length} ${t(
          'community.list.table.profiles'
        )}`}</div>
      </div>
    )
  }

  return (
    <>
      <Main ariaLabelledby="user-list-title" className="Community">
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="user-list-title" className="h3">
              {t('community.title')}
            </h1>
            <p>{t('community.list.hint')}</p>
          </div>
          <button
            className="btn btn-primary h-100"
            onClick={() => setShowCreateModal(true)}
          >
            {t('community.list.add')}
          </button>
        </div>
        <section className="mt-3">
          <div className="Community__list">
            <ContentTable
              title={t('admin.table.title')}
              columns={columns}
              data={list}
              emptyText={t('community.emptyList')}
              actions={actions}
              onSearch={(s: string) => {
                fetchList({ page: 1, search: s })
              }}
              isLoaded={isLoaded}
              searchPlaceholder={t('community.list.search')}
            />
            <Pagination
              currentPage={page - 1}
              totalPages={availablePages}
              totalItems={availableItems}
              pageSize={pageSize}
              singlePageText={(count) =>
                t('common.pager.singlePage', { count })
              }
              multiplePageText={(start, end, total) =>
                t('common.pager.multiplePage', { start, end, total })
              }
              onChange={(pageIndex) => fetchList({ page: pageIndex + 1 })}
            />
          </div>
        </section>
      </Main>
      {(showCreateModal || edittingUser) && (
        <CreateCommunityUser onClose={handleCloseForm} user={edittingUser} />
      )}
      {showProfiles && (
        <ProfilesModal
          onClose={() => setShowProfiles(null)}
          profiles={showProfiles}
        />
      )}
    </>
  )
}

export default CommunityScreen
