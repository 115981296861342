import { ColumnDef } from '@tanstack/react-table'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContentTable from '../../components/ContentTable'
import { Main } from '../../components/Main'
import { Domain } from '../../core/domains/types'
import useDomains from '../../core/domains/useDomains'
import useUi from '../../core/ui/useUi'
import useNotifications from '../../utils/notifications/useNotifications'
import CreateDomain from './Create'

const DomainBlackList: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { list, fetchList, clearList, deleteDomain } = useDomains()
  const [showCreatemodal, setShowCreatemodal] = useState(false)

  const handleConfirmDelete = (id: string) => {
    showConfirmation({
      title: t('blackList.delete.modal.title'),
      subtitle: t('blackList.delete.modal.subtitle'),
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        handleDelete(id)
      },
    })
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteDomain(id)
      notify.success(t('blackList.delete.successFeedback'))
      fetchList()
    } catch (e) {}
  }

  const actions = (row: Domain) => {
    return [
      {
        onClick: () => handleConfirmDelete(row.id),
        label: t('blackList.list.table.action.delete'),
        icon: 'delete_outline',
      },
    ]
  }

  const columns: ColumnDef<Domain>[] = useMemo(
    () => [
      {
        accessorKey: 'domain',
        header: t('blackList.list.table.name'),
        cell: ({ row }) => `@${row.original.domain}`,
      },
    ],
    []
  )

  useEffect(() => {
    fetchList()
    return () => {
      clearList()
    }
  }, [])

  return (
    <>
      <Main ariaLabelledby="black-list-title" className="BlackList">
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="black-list-title" className="h3">
              {t('blackList.title')}
            </h1>
            <p>{t('blackList.hint')}</p>
          </div>
          <button
            className="btn btn-primary h-100"
            onClick={() => setShowCreatemodal(true)}
          >
            {t('blackList.list.add')}
          </button>
        </div>
        <section className="mt-3">
          <div className="Events__list">
            <ContentTable
              title={t('blackList.list.title')}
              columns={columns}
              data={list}
              actions={actions}
              emptyText={t('blackList.emptyList')}
            />
          </div>
        </section>
      </Main>
      {showCreatemodal && (
        <CreateDomain onClose={() => setShowCreatemodal(false)} />
      )}
    </>
  )
}

export default DomainBlackList
