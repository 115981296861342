import useFetch from '../../utils/fetch/useFetch'
import { FindUserResponse, UseFindUserByEmail } from './types'

const useFindUserByEmail = (): UseFindUserByEmail => {
  const { get } = useFetch()
  const endpoint = 'admin-users/find-by-email'

  const findUserByEmail = async (
    email: string
  ): Promise<FindUserResponse | undefined> => {
    return await get({
      endpoint,
      params: { email },
    })
  }

  return { findUserByEmail }
}

export default useFindUserByEmail
