import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useNotifications from '../../../utils/notifications/useNotifications'
import FormActions from '../../../components/FormActions'
import useCulture from '../../../utils/culture'
import useUi from '../../../core/ui/useUi'
import HistoryModal from './HistoryModal'
import usePrivacyPoliciesVersions from '../../../core/privacyPoliciesVersions/usePrivacyPoliciesVersions'

import './styles.scss'

const PrivacyPoliciesVersions: FC = () => {
  const { t } = useTranslation()
  const { formatDate } = useCulture()
  const { showConfirmation } = useUi()
  const notify = useNotifications()
  const { current, fetchCurrent, createPrivacyPoliciesVersion } =
    usePrivacyPoliciesVersions()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showHistory, setShowHistory] = useState<boolean>(false)

  const handleSaveConfirmation = () =>
    showConfirmation({
      title: t('privacyPolicies.versions.saveConfirmation.title'),
      subtitle: t('privacyPolicies.versions.saveConfirmation.subtitle'),
      confirmText: t('common.publish'),
      cancelText: t('common.cancel'),
      onConfirm: handleCreate,
    })

  const handleCreate = () => {
    setIsSubmitting(true)
    try {
      createPrivacyPoliciesVersion()
      notify.success(t('privacyPolicies.versions.successFeedback'))
    } catch (e) {
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    fetchCurrent()
  }, [])

  return (
    <>
      <div className="PrivacyPoliciesVersions">
        <div className="d-flex justify-content-between PrivacyPoliciesVersions__content">
          <div>
            <div className="PrivacyPoliciesVersions__header-values d-flex">
              <div>
                <span>{t('privacyPolicies.code')}:</span> {current?.code}
              </div>
              <div>
                <span>{t('privacyPolicies.date')}:</span>{' '}
                {current?.creationDate &&
                  formatDate(
                    new Date(current?.creationDate as string) as Date,
                    'LONG_DATE_TIME'
                  )}
              </div>
              <div>
                <span>{t('privacyPolicies.author')}:</span> {current?.author}
              </div>
            </div>
          </div>
        </div>
        <FormActions>
          <button
            className="btn btn-outline-primary"
            onClick={() => setShowHistory(true)}
          >
            {t('privacyPolicies.versions.changeHistory')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSaveConfirmation}
            disabled={isSubmitting}
          >
            {t('privacyPolicies.versions.newVersion')}
          </button>
        </FormActions>
      </div>
      {showHistory && <HistoryModal onClose={() => setShowHistory(false)} />}
    </>
  )
}

export default PrivacyPoliciesVersions
