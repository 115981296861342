import { FC, useState } from 'react'
import {
  CheckboxControl,
  FormProvider,
  FormRadio,
  Pagination,
  TabsInternal,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import useDesignConstants from '../../hooks'

import { CheckboxFormProps } from './types'

import './styles.scss'

const NavigationContainer: FC = () => {
  const [page, setPage] = useState<number>(1)
  const {
    paginationAvailablePages,
    tabItems,
    iconItems,
    radioItems,
    checkboxItems,
  } = useDesignConstants()
  const { t } = useTranslation()

  const methods = useForm<CheckboxFormProps>({
    mode: 'onChange',
    defaultValues: {
      option1: 'option1',
      option3: 'option3',
    },
  })

  const { control } = methods

  return (
    <div className="navigation-design-container">
      <p className="navigation-design-container__title">
        {t('tenantDesign.navigation.section.navigation')}
      </p>
      <div className="navigation-design-container__navigation section">
        <div className="navigation-design-container__navigation__tabs">
          <TabsInternal tabs={tabItems} />
        </div>
        <div className="navigation-design-container__navigation__pagination">
          <Pagination
            currentPage={(page || 1) - 1}
            totalPages={paginationAvailablePages}
            totalItems={120}
            pageSize={10}
            singlePageText={(count) => t('common.pager.singlePage', { count })}
            multiplePageText={(start, end, total) =>
              t('common.pager.multiplePage', { start, end, total })
            }
            onChange={(pageIndex) => setPage(pageIndex + 1)}
          />
        </div>
      </div>
      <p className="navigation-design-container__title">
        {t('tenantDesign.navigation.section.icons')}
      </p>
      <div className="navigation-design-container__icons section">
        {iconItems &&
          iconItems.map(({ id, name, style }) => (
            <Icon
              key={id}
              name={name}
              className={`navigation-design-container__icons__icon icon ${style}`}
            />
          ))}
      </div>
      <p className="navigation-design-container__title">
        {t('tenantDesign.navigation.section.selectors')}
      </p>
      <FormProvider methods={methods}>
        <div className="navigation-design-container__selectors section">
          <div className="navigation-design-container__selectors__radio-group">
            {radioItems &&
              radioItems.map(({ id, value, label, disabled }) => (
                <FormRadio
                  key={id}
                  control={control as any}
                  name={value}
                  options={[{ value, label }]}
                  disabled={disabled}
                />
              ))}
          </div>
          <div className="navigation-design-container__selectors__checkbox-group">
            {checkboxItems &&
              checkboxItems.map(({ id, value, label, disabled }) => (
                <CheckboxControl
                  key={id}
                  value={value}
                  name={id}
                  onChange={() => {}}
                  label={label}
                  disabled={disabled}
                />
              ))}
          </div>
        </div>
      </FormProvider>
    </div>
  )
}

export default NavigationContainer
