import { useRef } from 'react'
import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseEventAdminsResult,
  EventRequestParams,
  EventListResponse,
  CreateEventAdminType,
} from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useEventAdmins = (): UseEventAdminsResult => {
  const dispatch = useAppDispatch()
  const {
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
  } = useAppSelector((state) => state.eventAdmins)
  const { get, post, del, patch } = useFetch()
  const { notifyApiError } = useError()

  const errorModel = 'admin'
  const endpoint = useRef<string>('')
  const setEndpoint = (eventId: string) => {
    endpoint.current = `events/${eventId}/admin-users`
  }

  const parseParams = (requestParams?: EventRequestParams) => {
    const params: EventRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
    }
    return params
  }

  const getList = async (params?: EventRequestParams) => {
    const response: EventListResponse | undefined = await get({
      endpoint: endpoint.current,
      params,
    })
    return response
  }

  const fetchList = async (params?: EventRequestParams) => {
    dispatch(showLoader())
    const reqParams = parseParams(params)
    const response: EventListResponse | undefined = await getList(reqParams)
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const createAdmin = async (user: CreateEventAdminType) => {
    try {
      await post({ endpoint: endpoint.current, body: user })
    } catch (e) {
      notifyApiError(e, errorModel, 'create')
    }
  }

  const updateAdmin = async (id: string, profiles: string[]) => {
    try {
      await patch({ endpoint: endpoint.current, id, body: { profiles } })
    } catch (e) {
      notifyApiError(e, errorModel, 'edit')
    }
  }

  const deleteAdmin = async (id: string) => {
    try {
      await del({ endpoint: endpoint.current, id })
    } catch (e) {
      notifyApiError(e, errorModel, 'delete')
    }
  }

  return {
    list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    fetchList,
    clearList: () => dispatch(clearList()),
    createAdmin,
    updateAdmin,
    deleteAdmin,
    setEndpoint,
  }
}

export default useEventAdmins
