import { FC } from 'react'

import ThemeHeader from './Header'
import ThemeFooter from './Footer'

import './styles.scss'
import { useTranslation } from 'react-i18next'

const ThemeContainer: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="theme-design-container">
      <p className="theme-design-container__title">
        {t('tenantDesign.theme.header.title')}
      </p>
      <div className="theme-design-container__header section">
        <ThemeHeader />
      </div>
      <p className="theme-design-container__title">
        {t('tenantDesign.theme.footer.title')}
      </p>
      <div className="theme-design-container__footer section">
        <ThemeFooter />
      </div>
    </div>
  )
}

export default ThemeContainer
