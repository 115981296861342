import { FC } from 'react'

import './styles.scss'

type BackdropProps = {
  close: () => void
}

const Backdrop: FC<BackdropProps> = ({ close }) => {
  return <div className="backdrop" onClick={close} />
}

export default Backdrop
