import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  ReceivedMessages,
  ReceivedMessagesDetail,
  ReceivedMessagesState,
  ReceivedStatistics,
  PublishedMessageFilters,
} from './types'

const initialState: ReceivedMessagesState = {
  list: [],
  detail: null,
  serviceList: [],
  serviceSelected: '',
  statusSelected: 'Success',
  messageStatusList: [],
  receivedStatistics: null,
  isLoaded: false,
  isDetailLoaded: false,
}

export const slice = createSlice({
  name: 'receivedMessages',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setFilters: (state, action: PayloadAction<PublishedMessageFilters>) => {
      state.serviceSelected = action.payload.serviceSelected
      state.statusSelected = action.payload.statusSelected
    },
    clearStore: () => initialState,
    clearMessageListStore: (state) => {
      return { ...initialState, serviceList: state.serviceList }
    },
    setList: (state, action: PayloadAction<ReceivedMessages[]>) => {
      state.list = action.payload
    },
    showDetailLoader: (state) => {
      state.isDetailLoaded = false
    },
    hideDetailLoader: (state) => {
      state.isDetailLoaded = true
    },
    setDetail: (state, action: PayloadAction<ReceivedMessagesDetail>) => {
      state.detail = action.payload
    },
    setServiceList: (state, action: PayloadAction<string[]>) => {
      state.serviceList = action.payload
    },
    setMessageStatusList: (state, action: PayloadAction<string[]>) => {
      state.messageStatusList = action.payload
    },
    setReceivedStatistics: (
      state,
      action: PayloadAction<ReceivedStatistics>
    ) => {
      state.receivedStatistics = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setList,
  clearStore,
  clearMessageListStore,
  setDetail,
  setFilters,
  setServiceList,
  setMessageStatusList,
  setReceivedStatistics,
  showDetailLoader,
  hideDetailLoader,
} = slice.actions

export default slice.reducer
