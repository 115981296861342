import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    domain: yup
      .string()
      .label(t('blackList.create.modal.domain'))
      .matches(
        /^([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.)*([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.[a-zA-Z]{2,7})$/,
        t('blackList.create.regexError')
      )
      .required(({ label }) => t('validations.required', { label })),
  })
}
