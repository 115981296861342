import { useState } from 'react'
import useFetch from '../../utils/fetch/useFetch'
import { UseApplicationsResult, Application } from './types'

const useApplications = (): UseApplicationsResult => {
  const { get } = useFetch()
  const [applications, setApplications] = useState<Application[]>([])

  const endpoint = 'applications'

  const fetchApplications = async () => {
    const response: Application[] | undefined = await get({ endpoint })
    if (response) {
      setApplications(response)
    }
  }

  return {
    fetchApplications,
    applications,
  }
}

export default useApplications
