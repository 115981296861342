import { FC } from 'react'
import { UnknownError } from '../../../pages/error/UnknownError'

interface ErrorPageHandlerProps {
  type: string
}

const ErrorPageHandler: FC<ErrorPageHandlerProps> = () => {
  return <UnknownError />
}

export default ErrorPageHandler
